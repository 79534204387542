import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../components/Common/Loader";
import { Container, Row, Col } from "reactstrap";
import ProfileHeader from "../components/Common/ProfileHeader";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";

const PaymentOptions = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");

  const [transactionId, setTransactionId] = useState(0);
  if (!userID) {
    navigate("../login");
  }
  let merchantPayId = localStorage.getItem("merchantPayId");

  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const [values, setValues] = useState("");
  const mobile = useFormInput("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [txnStatus, setTxnStatus] = useState(0);
  const [order, setOrder] = useState(null);
  const sendAmount = useFormInput("");
  const MINUTE_MS = 10000;
  const [timeLimit, setTimeLimit] = useState(0);
  const [wallet, setWallet] = useState(0);

  const sendPayment = async () => {
    try {
      if (!sendAmount.value) {
        setError("Please enter amount");
      }
      if (!order.order_price) {
        setError("Order amount should not be empty");
      } else {
        let userEmail = localStorage.getItem("email");
        let dataToSend = {
          userId: userID,
          clientEmail: userEmail,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          payAmount: order.order_price,
          orderId: order.order_id,
          orderpay: true,
        };

        let Url = "transactions/api/upipayment";
        setLoading(true);
        let params = createSendData(dataToSend);
        const responseJson = await postFunction(Url, params);
        if (responseJson.status === 1) {
          let response = responseJson.data;
          console.log(response.status);
          if (response.status === true) {
            setTransactionId(response.data.txn_id);
            setTimeout(function () {
              checkTxnStatus(response.data.txn_id);
            }, MINUTE_MS);
            window.location.href = response.data.link;
          } else {
            setError(responseJson.data.msg);
          }
        } else {
          setError(responseJson.msg);
        }
      }
    } catch (error) {
      setError(error);
    }
  };

  const checkTxnStatus = async (txnId) => {
    try {
      let dataToSend = {
        userId: userID,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        orderpay: true,
        txnId: txnId,
      };

      let Url = "transactions/api/checkTxnStatus";
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);
      if (responseJson.response.status === true) {
        let response = responseJson.response.data;
        if (
          response.payment_status === "COMPLETED" ||
          response.payment_status === "FAILED" ||
          timeLimit >= "60000"
        ) {
          localStorage.setItem("payment_status", response.payment_status);
          localStorage.setItem("payment_txnid", response.payment_txnid);
          localStorage.setItem("payment_amount", response.payment_amount);
          localStorage.setItem(
            "payment_date",
            responseJson.response.payment_date
          );
          setLoading(false);
          navigate("../payment-status", { state: { orderpay: true } });
        } else {
          setTxnStatus(0);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    }
  };

  const getOrder = async (Url) => {
    try {
      let dataToSend = {
        userId: "17",
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
      };

      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);
      console.log("🚀 ~ getOrder ~ responseJson:", responseJson);
      if (responseJson.status === 1) {
        setOrder(responseJson.data);
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  function myFunction(divid) {
    var x = document.getElementById(divid);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  useEffect(() => {
    getOrder("transactions/api/order");
  }, []);

  let months = "";
  let years = "";
  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const yarray = [
    2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
  ];
  months = array.map((op) => (
    <option value={op} key={op}>
      {op <= 9 ? "0" + op : op}
    </option>
  ));
  years = yarray.map((y) => (
    <option value={y} key={y}>
      {y}
    </option>
  ));
  setTimeout(() => {
    if (transactionId && txnStatus === 0) {
      let time = timeLimit + MINUTE_MS;
      setTimeLimit(time);
      checkTxnStatus(transactionId);
    }
  }, MINUTE_MS);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <ProfileHeader pagename="checkout" title="Pay" />
        <Container>
          <div className="mt-4"></div>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="qr_background_image">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="paymt_amount_wrapper">
                          <p> Total Payable Amount </p>
                          <h3>
                            <img
                              src={require("../assets/images/rup_icon_yellow.png")}
                              alt="pay amount"
                            />{" "}
                            {order ? order.order_price : 0}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="mt-4"></div>

          <section className="x">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="payment_info_title">
                    <p> Select Payment Methods: </p>
                  </div>
                </div>
                <div className="mt-3"></div>
                <div className="col-md-12">
                  <div>
                    <div className="pay_upi_wrapper">
                      <img
                        src={require("../assets/images/upi.png")}
                        alt="upi"
                      />
                      <button
                        type="button"
                        className="upi_button pointer_class"
                        onClick={() => myFunction("upiModal")}
                      >
                        Pay using UPI ID
                      </button>
                      <h6>
                        <img
                          src={require("../assets/images/star.png")}
                          alt="stars"
                        />
                        Recommended Method
                      </h6>
                      <div
                        id="upiModal"
                        className="collapse payment_info_wrapper"
                        style={{ display: "none" }}
                      >
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div>
                              <div className="upi_qr_payment_wrapper">
                                <h5>
                                  <img
                                    src={require("../assets/images/rupee_icon.png")}
                                    alt="rupee icon"
                                  />
                                  <span> {order ? order.order_price : 0} </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <button
                                type="button"
                                className="paynowBtn"
                                onClick={sendPayment}
                              >
                                Pay Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="pay_upi_wrapper">
                      <img
                        src={require("../assets/images/visa.png")}
                        alt="visa"
                      />
                      <button
                        type="button"
                        className="upi_button"
                        onClick={() => myFunction("cdModal")}
                      >
                        {" "}
                        Pay using Debit or Credit Card{" "}
                      </button>
                      <h6> Needs to pay extra transaction charges </h6>
                      <div
                        id="cdModal"
                        className="collapse payment_info_wrapper"
                        style={{ display: "none" }}
                      >
                        <div className="card_payment_info">
                          <form>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-12">
                                  <label> Card Number </label>
                                  <input
                                    type="text"
                                    name="card_number"
                                    maxLength="16"
                                    placeholder="Enter your card number"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-12">
                                  <label> Card holder Name </label>
                                  <input
                                    type="text"
                                    name="card_name"
                                    maxLength="100"
                                    placeholder="Enter your name"
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-md-6">
                                  <label> Cvv Number </label>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="number"
                                    name="cvv_number"
                                    className="form-control"
                                    placeholder="Enter your cvv number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="row align-items-center">
                                <div className="col-md-6">
                                  <label> Expiry date </label>
                                </div>
                                <div className="col-md-6">
                                  <div className="row align-items-center">
                                    <div className="col-5">
                                      <select
                                        className="form-control"
                                        name="month"
                                      >
                                        <option value="0">Select Month</option>
                                        {months}
                                      </select>
                                    </div>
                                    <div className="col-2">
                                      <p className="text-center"> / </p>
                                    </div>
                                    <div className="col-5">
                                      <select
                                        className="form-control"
                                        name="year"
                                      >
                                        <option value="0">Select Year</option>
                                        {years}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5">
                              <div className="row">
                                <div className="col-md-8 offset-md-2">
                                  <div>
                                    <button type="button" className="paynowBtn">
                                      pay now
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="pay_upi_wrapper">
                      <img
                        src={require("../assets/images/net-banking-icon.png")}
                        alt="net banking"
                      />
                      <button
                        type="button"
                        className="upi_button"
                        onClick={() => myFunction("nbModal")}
                      >
                        {" "}
                        Net Banking{" "}
                      </button>
                      <h6> Needs to pay extra transaction charges </h6>
                      <div
                        id="nbModal"
                        className="collapse ¸"
                        style={{ display: "none" }}
                      >
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <p> please select your bank </p>
                          </div>
                          <div className="col-md-6">
                            <div className="netBanking_option">
                              <div className="dropdown">
                                <button
                                  type="button"
                                  className="form-control dropdown-toggle"
                                  data-toggle="dropdown"
                                >
                                  Bank details
                                </button>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href="#">
                                    ICICI BANK
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    axis bank
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    hdfc bank
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="banking_paynow">
                              <button type="button" className="paynowBtn ">
                                {" "}
                                paynow{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row pay_upi_wrapper">
                      <div className="col-md-4 col-4">
                        <img
                          src={require("../assets/images/smilez_wallet.png")}
                          alt="smileez wallet"
                        />
                        <h5> Smileez wallet </h5>
                      </div>
                      <div className="col-md-4 col-4">
                        <div className="smilz_wallet_balance">
                          <p> Your wallet balance </p>
                          <h4>
                            <img
                              src={require("../assets/images/rupee_icon.png")}
                              alt="rupee"
                            />{" "}
                            <span>
                              {" "}
                              {localStorage.getItem("walletAmount")}{" "}
                            </span>{" "}
                          </h4>
                        </div>
                      </div>
                      <div className="col-md-4 col-4">
                        <div className="checkBox_walllet">
                          <div className="banking_paynow">
                            <br />
                            <input
                              type="checkbox"
                              name="check"
                              value="1"
                              onChange={(e) => setWallet(e.target.value)}
                            />
                            {order &&
                            localStorage.getItem("walletAmount") >=
                              order.order_price ? (
                              <button type="button" className="paynowBtn">
                                {" "}
                                paynow{" "}
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
      </div>
      <div className="divider-80"></div>
    </section>
  );
};

export default PaymentOptions;
