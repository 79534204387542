import React from "react";

import CommonSection from "../components/UI/common-section/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import "../styles/cart-page.css";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { cartActions } from "../store/shopping-cart/cartSlice";
import { Link,useNavigate } from "react-router-dom";

import ProfileHeader from '../components/Common/ProfileHeader';

const Cart = () => {
	  let navigate = useNavigate();
const goPage=(pageName)=>{
      navigate('../'+pageName)
  }
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  
  localStorage.setItem('cartitems',cartItems.length);
  return (
    <Helmet title="Cart">
        
      
     <section className='main_section_wrapper'>
    <div className="welcome_section_wrapper">
    <ProfileHeader pagename="welcome" title="Cart" />
        <Container>
          <Row className="ml-2 mt-4 ">
           
              {cartItems.length === 0 ? (
                <h5 className="text-center">Your cart is empty</h5>
                ) : (<div className="container">
                
                    {cartItems.map((item) => (
                      <Trnew item={item} key={item.id} />
                    ))}
            </div>
              )}

              
            
          </Row>
          <br/>
<hr className=" hrcss" />

          <div className="row">
    <div className="col subtotal">
                
                  Subtotal:  
                
                
                
              </div>
              <div className="col text-right carttext">
    &#8377;
                  <span >{totalAmount}</span>
    </div>
    
    </div>
    <div className="divider-120"></div>
          <div className="row">
          <div className="col  text-center text-cart-page mt-4">
   Taxes and shipping will calculate at checkout
    </div>
    </div>
          <div className="row ">
          
                  <div className="col  text-center mt-4">
                    <button className="continue-btn" onClick={()=>goPage('welcome')}>Continue Shopping</button>
                  </div>
                  
                </div>
                <div className="row"><div className="col text-center mt-4">
                {cartItems.length>0?
                  <button className="create-button pointer_class" onClick={()=>goPage('checkout')}>
                 Checkout
                  </button>:'' }
                  </div></div>
        </Container>
        </div>
      </section>
      <div className="divider-80"></div>
    </Helmet>
    
  );
};

const Trnew = (props) => {
  const { id, image01, title, price, quantity } = props.item;
  const dispatch = useDispatch();

  const deleteItem = () => {
    dispatch(cartActions.deleteItem(id));
  };
  return (
    <div className="row mycart-item mt-2">
        <div className="col-3 mt-2">
        <img src={image01} alt={title} height="80" width="80" />
      </div>
      <div className="col-7 mt-3">
      <span className="cart-title ml-2">{title}</span><br/>
      <span className="price-cart ml-2">Price: &#8377; {price}</span><br/>
      <span className="price-cart ml-2">Qty: {quantity}</span></div>
      <div className="col-2 text-right pointer_class mt-4">
       <br /><br />
       <img src={require("../assets/images/trash.png")} alt={title} height="20" width="20" onClick={deleteItem} />
       </div>
    </div>
  );
};

const Tr = (props) => {
  const { id, image01, title, price, quantity } = props.item;
  const dispatch = useDispatch();

  const deleteItem = () => {
    dispatch(cartActions.deleteItem(id));
  };
  return (
    <tr>
      <td className="text-center cart__img-box">
        <img src={image01} alt="" />
      </td>
      <td className="text-center">{title}</td>
      <td className="text-center">&#8377; {price}</td>
      <td className="text-center">{quantity}pcs</td>
      <td className="text-center pointer_class cart__item-del">
        <span className="bi bi-trash"></span><i className="ri-delete-bin-line" onClick={deleteItem}>X</i>
      </td>
    </tr>
  );
};

export default Cart;
