import React from "react";
import Form from "react-bootstrap/Form";

function Search() {
  return (
    <div className="search-part">
      <Form className="d-flex align-items-center justify-content-center w-100">
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Search</Form.Label>
          <Form.Control type="text" placeholder="Search here..." />
        </Form.Group>
      </Form>
    </div>
  );
}

export default Search;
