import React,{useState,useEffect} from 'react'
import { API_KEY, APP_ID, APP_OS, CLIENT_ROLE_ID, IMEI_NO ,BRAND_ID} from '../Constants'
//import {  useNavigate} from 'react-router-dom'
import {createSendData,getFunction} from '../components/Common/Actions'
//import CountryDropdown from 'country-dropdown-with-flags-for-react';

import { Container, Row } from "reactstrap";
//import ContentHeader from '../components/Common/ContentHeader';
import Checkbox from "../components/Common/Checkbox";
import AppLoader from "../components/Common/Loader";
const RequestList = () => {
    // let navigate = useNavigate();
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue)
  
    const handleChange = (e) => {
      setValue(e.target.value)
    }
    return {
      value,
      onChange: handleChange,
    }
  }
   
 //const [values,setValues] = useState('')
  const mobile = useFormInput('')
  const country_code = useFormInput('')
  const [countryCode,setCountryCode]=useState(0);
  
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  let userID = localStorage.getItem('userId');
  const [receivers,setReceivers] = useState(null)
  const sendpoint = useFormInput('')
  const [request,setRequest]=useState(null)
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  
  const updateStatus = async(statusVal,requestId) => {
    try{
    //console.log(fullname.value+email.value+mobile.value+country_code.value)
    let dataToSend = {userId:userID,countryCode:countryCode
      ,mobile_no: localStorage.getItem('mobile_no'),KEY:API_KEY,role_id:CLIENT_ROLE_ID,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),request_id:requestId,status:statusVal};
    
    
    let Url = "gratification/api/updatereqstatus";
    //console.log(Url)
     let params = createSendData(dataToSend);
    const responseJson = await getFunction(Url,params);
     if(responseJson.status===1){
         //console.log(responseJson.data)
         setError(responseJson.msg)
         requestForApproval();
     }else{
         setError(responseJson.msg)
     }
      }catch (error) {
    setError(error)
   } finally {
     setLoading(false);
   }
    
  }

  
  
  
  const requestForApproval =async()=>{
      
      try{
      let dataToSend = {userId:userID,countryCode:countryCode
      ,mobile_no: localStorage.getItem('mobile_no'),KEY:API_KEY,role_id:CLIENT_ROLE_ID,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key')};
    
    let Url = "gratification/api/smzrequests";
    //console.log(Url)
     let params = createSendData(dataToSend);
    const responseJson = await getFunction(Url,params);
     if(responseJson.status===1){
         setRequest(responseJson.data)
         
     }else{
         setError(responseJson.msg)
     }
      }catch (error) {
    setError(error)
   } finally {
     setLoading(false);
   }
  
  }
  

  //console.log(isCheck);
  
  useEffect(()=>{
  requestForApproval()
     
  },[])
  let requestData = "";
  const handleSelectAll = e => {
      
    setIsCheckAll(!isCheckAll);
    setIsCheck(request.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = e => {
      //console.log('hi')
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };
  //console.log(request)
  
  let requestbuttons = null;
  
  let reqfrom =null;
  if(request){
      requestData =request.map((req)=>
      <tr key={req.id}><td>
      <Checkbox
          key={req.id}
          type="checkbox"
          name={req.display_name}
          id={req.id}
          handleClick={handleClick}
          isChecked={isCheck.includes(req.id)}
        />
         </td>
      <td>{req.display_name}</td>
      <td>{req.smileez}</td>
      <td>{req.created_on}</td>
              <td>{req.status}
              {req.status==='Pending' && (userID===req.created_by)?<a  href="#" name="cancel" className="btn btn-warning" onClick={()=>updateStatus('Cancel',req.id)} >Cancel </a>:''}
              {req.status==='Pending' && (userID!==req.created_by)?<div><a name="approved" className="btn btn-primary" onClick={()=>updateStatus('Approved',req.id)}>Approved </a> <a name="rejected" className="btn btn-warning" onClick={()=>updateStatus('Rejected',req.id)}>Rejected </a></div>:''} </td></tr>
              //reqfrom=req.reqfrom
                )
      
     //requestbuttons =request.map((req)=> (req.created_by!==req.request_user_id)? <div ><button name="approved" className="btn btn-primary">Approved </button> <button name="rejected" className="btn btn-warning">Rejected </button></div>:'')
  }
   
const message =error && error?<div className="alert alert-warning alert-dismissible  show">
<a className="close" data-dismiss="alert">&times;</a>
{error}

</div>:''

  return (
    <section className='main_section_wrapper'>
    <div className="welcome_section_wrapper">
      <Container>
        {loading&& loading?<AppLoader />:''}
    <div className="justify-content-center form">
          
                <form className='login_from_wrapper' action='/send-smileez'>
                {message}
                <Row>
                <div className="col-lg-6">
                  <p className="text-medium-emphasis">Send Smileez Pending List</p>
                  </div>
                  
                  </Row>
                  
                  <div className="row">
                  <div className="col-lg-12">
                  <table className="table table-striped table-hover table-resposive">
                  <thead>
                  <tr>
            <th>
            <Checkbox
        type="checkbox"
        name="selectAll"
        id="selectAll"
        handleClick={handleSelectAll}
        isChecked={isCheckAll}
      />
            </th>      
            <th>
            Request {reqfrom}
            </th>
            <th>
          Smileez
            </th><th>
            Request Date
            </th><th>
            Status
            </th></tr>
                  </thead>
                  
                  {requestData}
                  
                  <tfoot>
                  </tfoot>
                  </table>
                 
                  </div>
                  </div>
                
                  
                  
                  
                  
                  
                </form>
           </div>   
      </Container>
    </div>
</section>
  );
};

export default RequestList;
