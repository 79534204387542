import "bootstrap/dist/css/bootstrap.min.css"
import LayoutContainer from "./components/Layout/LayoutContainer";
import Layout from "./components/Layout/Layout";

function App() {
  if (localStorage.getItem("user_id")) {
    return <Layout />;
  } else {
    return <LayoutContainer />;
  }
}

export default App;
