import React, { useEffect, useState } from "react";
import "../assets/css/style.css";
import questionMark from "../assets/images/whiteWesMark.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import {
  addMerchantOffer,
  getOfferCategory,
} from "../components/Common/Actions";
import moment from "moment";
import { ToastContainer, toast, Bounce } from "react-toastify";

function AddNewOffer() {
  const [categoryData, setCategoryData] = useState([]);
  const [addMerchantOfferData, setAddMerchantOfferData] = useState({
    // offer_category: "",
    offer_title: "",
    offer_description: "",
    target_value: "",
    target_offer_value: "",
    target_amount: "",
    target_offer_amount: "",
    offer_flat_percentage: "",
    offer_percentage_max_discount_amount: "",
    number_of_use: "",
    offer_available_on: "",
  });
  const [expiryDate, setExpiryDate] = useState();
  const [selectedCategory, setSelectedCategory] = useState("1");

  const navigate = useNavigate();
  const goToBack = () => {
    navigate("/dashboard");
  };

  async function getAllOfferCategoryData() {
    await getOfferCategory()
      .then((response) => {
        setCategoryData(response.data.data);
      })
      .catch((error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
      });
  }

  const handleGetMerchantOfferData = (i) => {
    setAddMerchantOfferData({
      ...addMerchantOfferData,
      [i.target.name]: i.target.value,
    });
  };

  const handleAddMerchantOffer = async (e) => {
    e.preventDefault();
    const newObjData = {
      ...addMerchantOfferData,
      offer_category: selectedCategory,
      merchant_id: localStorage.getItem("userId"),
      offer_expiry_date: moment(expiryDate).format("YYYY-MM-DD"),
    };
    await addMerchantOffer(newObjData)
      .then((response) => {
        if (response.status) {
          toast.success(response.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setAddMerchantOfferData({
            offer_title: "",
            offer_description: "",
            target_value: "",
            target_offer_value: "",
            target_amount: "",
            target_offer_amount: "",
            offer_flat_percentage: "",
            offer_percentage_max_discount_amount: "",
            number_of_use: "",
            offer_available_on: "",
          });
          setSelectedCategory("");
          setExpiryDate("");
        }
      })
      .catch((error) => {
        console.log("🚀 ~ handleAddMerchantOffer ~ error:", error);
      });
  };

  useEffect(() => {
    getAllOfferCategoryData();
  }, []);

  return (
    <div className="container" style={{ marginBottom: "120px" }}>
      <ToastContainer />
      <div className="add_new_voucher_create_wrapper">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <div className="voucher_create_wrapper_box">
              <div
                className="back_icon"
                onClick={goToBack}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={require("../assets/images/white_back_icon.png")}
                  alt="white_back_icon"
                  className="mt-2"
                />
              </div>
              <div className="voucherTitle">
                <img
                  className="imagecreate img-fluid"
                  src={require("../assets/images/voucher_icon.png")}
                  alt="voucher creation"
                />
                <div className="textCreate"> Create New Vouchers </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <div className="questionMark">
              <img src={questionMark} alt="white_back_icon" className="mt-2" />
            </div>{" "}
          </div>
        </div>
      </div>
      <div className="add_new_voucher_form mt-5">
        <form method="post" onSubmit={handleAddMerchantOffer}>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-4">Add new voucher details</h3>
            </div>
            <div className="col-12">
              <div className="voucher-details-part d-flex flex-column gap-1">
                <label className="control-label m-0">Offer Category</label>
                <select
                  className="custom-select"
                  onChange={(i) => setSelectedCategory(i.target.value)}
                  value={selectedCategory}
                >
                  <option selected disabled>
                    Select Category
                  </option>
                  {categoryData?.map((category, index) => (
                    <option value={category.id} key={index}>
                      {" "}
                      {category.category_name}{" "}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                <label className="control-label m-0">Offer title</label>
                <input
                  className="voucherName p-2"
                  type="text"
                  name="offer_title"
                  onChange={handleGetMerchantOfferData}
                  value={addMerchantOfferData.offer_title}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                <label className="control-label m-0">Offer description</label>
                <input
                  className="voucherName p-2"
                  type="text"
                  name="offer_description"
                  onChange={handleGetMerchantOfferData}
                  value={addMerchantOfferData.offer_description}
                />
              </div>
            </div>
            {selectedCategory == "1" && (
              <>
                <div className="col-12">
                  <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                    <label className="control-label m-0">Target Value</label>
                    <input
                      className="voucherName p-2"
                      type="text"
                      name="target_value"
                      onChange={handleGetMerchantOfferData}
                      value={addMerchantOfferData.target_value}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                    <label className="control-label m-0">Offer Value</label>
                    <input
                      className="voucherName p-2"
                      type="text"
                      name="target_offer_value"
                      onChange={handleGetMerchantOfferData}
                      value={addMerchantOfferData.target_offer_value}
                    />
                  </div>
                </div>
              </>
            )}
            {selectedCategory == "2" && (
              <>
                <div className="col-12">
                  <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                    <label className="control-label m-0">Target Amount</label>
                    <input
                      className="voucherName p-2"
                      type="text"
                      name="target_amount"
                      onChange={handleGetMerchantOfferData}
                      value={addMerchantOfferData.target_amount}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                    <label className="control-label m-0">Offer Amount</label>
                    <input
                      className="voucherName p-2"
                      type="text"
                      name="target_offer_amount"
                      onChange={handleGetMerchantOfferData}
                      value={addMerchantOfferData.target_offer_amount}
                    />
                  </div>
                </div>
              </>
            )}
            {selectedCategory == "3" && (
              <>
                <div className="col-12">
                  <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                    <label className="control-label m-0">
                      Target Percentage
                    </label>
                    <input
                      className="voucherName p-2"
                      type="text"
                      name="offer_flat_percentage"
                      onChange={handleGetMerchantOfferData}
                      value={addMerchantOfferData.offer_flat_percentage}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                    <label className="control-label m-0">
                      Max Discount Amount
                    </label>
                    <input
                      className="voucherName p-2"
                      type="text"
                      name="offer_percentage_max_discount_amount"
                      onChange={handleGetMerchantOfferData}
                      value={
                        addMerchantOfferData.offer_percentage_max_discount_amount
                      }
                    />
                  </div>
                </div>
              </>
            )}
            <div className="col-12">
              <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                <label className="control-label m-0">Number of use</label>
                <input
                  className="voucherName p-2"
                  type="text"
                  name="number_of_use"
                  onChange={handleGetMerchantOfferData}
                  value={addMerchantOfferData.number_of_use}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                <label className="control-label m-0">Offer available on</label>
                <select
                  className="custom-select"
                  onChange={handleGetMerchantOfferData}
                  value={addMerchantOfferData.offer_available_on}
                  name="offer_available_on"
                >
                  <option selected disabled>
                    Offer available on
                  </option>
                  <option value="Self">Self Outlet</option>
                  <option value="All">All Outlet</option>
                </select>
              </div>
            </div>
            <div className="col-12">
              <div className="voucher-details-part d-flex flex-column gap-1 mt-3">
                <label className="control-label m-0">Offer validity</label>
                <DatePicker
                  selected={expiryDate}
                  onChange={(date) => setExpiryDate(date)}
                  value={expiryDate}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-12">
              <button
                className="create-button pointer_class border-0 mt-4"
                type="submit"
              >
                <img
                  src={require("../assets/images/createicon.png")}
                  alt="create_icon"
                  className="pe-2"
                />
                Add New Offer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddNewOffer;
