import React,{useState,useEffect} from 'react'
import {Link,useNavigate ,useLocation} from "react-router-dom";
import {createSendData,getFunction,extPostFunction} from '../components/Common/Actions'
import { API_KEY, APP_ID, APP_OS, CLIENT_ROLE_ID, IMEI_NO ,BRAND_ID} from '../Constants';
import ProfileHeader from '../components/Common/ProfileHeader';
import AppLoader from "../components/Common/Loader";
const CategoryVouchers=(props)=>{
     let navigate = useNavigate()
     const location = useLocation();
   const { voucher_category } = location.state;
     const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [extVouchers,setExtVouchers]=useState(null)
  const [categoryList,setCategoryList]=useState(null)
  const [vcategory,setVcategory]=useState(voucher_category)
  
     
     const getextvouchers=async (URL,category)=>{
         
         try{
             setLoading(true)
             let dataToSend = {mobile_no: localStorage.getItem('mobile_no'),KEY:API_KEY,role_id:CLIENT_ROLE_ID,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),category:category};
    let params = createSendData(dataToSend)
               const response=     await getFunction(URL,params)
              
                   if(response.status==1){
                console.log(category)
                if(category===null){
                    
                    setCategoryList(response.data)
                }       else{
                setExtVouchers(response.data)
            }
                   }
            else{
                setError(response.msg)
                
            }
             setLoading(false)
    
         }catch (error) {
    setError(error)
    setLoading(false)
   } finally {
     setLoading(false);
   }
     }
    
     useEffect(() => {
    getextvouchers('voucher/api/brandVouchers',null);
    
    
  }, []);
     useEffect(() => {
   
        getextvouchers('voucher/api/categorybrandVouchers',vcategory)
    
  }, [vcategory]);
  
  let list=''
  //let url = "https://mobicashindia.com/app/checkout?name=";
  const goPage=(pagename)=>{
      window.location.href=pagename;
  }
  if(extVouchers){
     console.log(extVouchers);
      list = extVouchers.map((lt,index)=>
     
     
      <div  className="col-5 colspacing" key={index}>
      <div className="logo-partners text-center">
					    	
								<Link to='../voucher-details' className="pointer_class" state={{ voucherId: lt.id,type:'brand_voucher' }}  >
                                                                <img src={lt.image_url}  alt={lt.name}  height="70" />
                                                               
        </Link><br/><span> &#8377;{lt.price}</span>&nbsp;&nbsp;&nbsp;
                                                                  </div>
        </div>
        )
  }
  
  let clist="";
  
  if(categoryList)
{
clist = categoryList.map((lt,index)=><option value={lt.category} key={index}>{lt.category}</option>)
}
  
    return (<section className='main_section_wrapper'>
    <div className="welcome_section_wrapper">{loading&& loading?<AppLoader />:''}
     <ProfileHeader pagename="welcome" title="Category Vouchers" />
     <select name="category" value={vcategory} className="form-control" onChange={(e)=>{setVcategory(e.target.value)}}>
        {clist}
        </select>
      <div className="container">
       <div className="row">
            {list}
            </div>
            </div>
            </div>
            </section>
             )
     }
     export default CategoryVouchers