import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_BASE_URL,
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../components/Common/Loader";

import axios, * as others from "axios";
import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";

import ProfileHeader from "../components/Common/ProfileHeader";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
const ExchangeSmileez = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  const [transactionId, setTransactionId] = useState(0);
  if (!userID) {
    navigate("../login");
  }

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [receivers, setReceivers] = useState(null);

  const [smileez, setSmileez] = useState(0);
  const [merchantId, setMerchantId] = useState(0);
  const [merchantConfig, setMerchantConfig] = useState("");
  const [tabType, setTabType] = useState("Exchange");
  const [receivedSmileez, setReceivedSmileez] = useState([]);
  const sendPayment = async (URL) => {
    try {
      if (smileez < 0) {
        setError("Smileez should be greater than 0");
      } else if (!merchantId) {
        setError("Reciver details not found");
      } else {
        let userEmail = localStorage.getItem("email");
        let dataToSend = {
          userId: userID,
          clientEmail: userEmail,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          merchant_id: merchantId,
          smileez: smileez,
        };

        let params = createSendData(dataToSend);
        const responseJson = await postFunction(URL, params);
        console.log(responseJson);
        if (responseJson.status === 1) {
          setError(responseJson.msg);
          setSmileez(0);
          setMerchantId(0);
          setMerchantConfig("");
        } else {
          setError(responseJson.msg);
          setSmileez(0);
          setMerchantId(0);
          setMerchantConfig("");
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      //setLoading(false);
    }
  };

  const exchange = async (URL) => {
    if (!merchantId) {
      setError("Select a merchant");
    } else if (!smileez) {
      setError("Please enter smileez");
    } else if (smileez < 0) {
      setError("Smileez should be greater than 0");
    } else {
      let dataToSend = {
        userId: userID,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        merchantId: merchantId,
      };
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(URL, params);
      if (responseJson.status === 1) {
        setMerchantConfig(responseJson.data);
      } else {
        setError(responseJson.msg);
      }
    }
  };
  const myExchange = async () => {
    let dataToSend1 = {
      userId: userID,
      mobile_no: localStorage.getItem("mobile_no"),
      KEY: API_KEY,
      role_id: CLIENT_ROLE_ID,
      brand_id: BRAND_ID,
      app_os: APP_OS,
      imei_no: IMEI_NO,
      app_id: APP_ID,
      token_key: localStorage.getItem("token_key"),
    };

    let reUrl = "gratification/api/exchangedSmileez";
    let reparams = createSendData(dataToSend1);
    const rewardsJson = await getFunction(reUrl, reparams);
    if (rewardsJson.status === 1) {
      setReceivedSmileez(rewardsJson.data);
    } else {
      //setError(rewardsJson.msg)
    }
  };
  const findUser = async (URL) => {
    try {
      let dataToSend = {
        userId: userID,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        merchantId: merchantId,
      };
      let params = createSendData(dataToSend);

      const responseJson = await getFunction(URL, params);

      if (responseJson.status === 1) {
        if (merchantId) {
          console.log(responseJson.data["0"].amount);
          setSmileez(responseJson.data["0"].amount);
        } else {
          setReceivers(responseJson.data);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
    }
  };

  useEffect(() => {
    findUser("gratification/api/merchantlist");
  }, []);
  useEffect(() => {
    if (merchantId) {
      findUser("gratification/api/merchantlist");
    }
    console.log(smileez);
  }, [merchantId]);

  const tabchanged = (type) => {
    setTabType(type);
  };
  useEffect(() => {
    myExchange();
  }, [tabType]);
  let historyList = "";
  if (receivedSmileez.length > 0) {
    historyList = receivedSmileez.map((rec) => (
      <div className="row historyList">
        <div className="col-9 col-xs-12">
          <div className="col-12 historylebel">From, {rec.display_name}</div>
          <div className="col-12 historysubtext">
            Mobile No: {rec.mobileno} | Date: {rec.createdon}
          </div>
        </div>
        <div className="col-3 col-xs-12 text-right historytext1">
          {rec.amount}
          <br />
          Smileez
        </div>
        <hr />
      </div>
    ));
  } else {
    historyList = (
      <div className=" historyNotFound text-center ">
        <img
          src={require("../assets/images/material-symbols_error-outline.png")}
          height="84"
          width="85"
          alt="No Data found"
        />
        <div className="text-center historyNotFoundtext">
          Not able to load the data
        </div>
      </div>
    );
  }

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  let config = "";
  if (merchantConfig) {
    config = (
      <div className="container ">
        <div className="row text-center">
          <div classsName="col-md-6">
            Smileez Point:{merchantConfig.rewards_points}
          </div>
          <div classsName="col-md-6 text-center">
            ={merchantConfig.rewards_amount} INR
          </div>
        </div>
        <div className="row text-center">
          <div classsName="col-md-6">
            Your Smileez:{smileez}*{merchantConfig.rewards_amount}
          </div>
          <div classsName="col-md-6 text-center">
            ={smileez * merchantConfig.rewards_amount} INR
          </div>
        </div>
      </div>
    );
  }
  let receiverList = "";
  if (receivers) {
    receiverList = receivers.map((rec) =>
      rec.id ? (
        <option value={rec.id} key={rec.id}>
          {rec.mobileno + " " + rec.display_name}
        </option>
      ) : (
        ""
      )
    );
  }
  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <ProfileHeader pagename="welcome" title="Exchange Smileez" />
        <Container>
          <div className="justify-content-center mt-4 tabbable">
            <div className=" mb-4">
              <ul className="nav nav-tabs">
                <li className={tabType == "Exchange" ? "active" : "inactive"}>
                  <a
                    data-toggle="tab"
                    href="#Receive"
                    onClick={() => tabchanged("Exchange")}
                  >
                    Exchange
                  </a>
                </li>
                <li className={tabType == "History" ? "active" : "inactive"}>
                  <a
                    data-toggle="tab"
                    href="#History"
                    onClick={() => tabchanged("History")}
                  >
                    History
                  </a>
                </li>
              </ul>
            </div>

            <div className="row smileez_balance ">
              <div className="col-8 textcolor">
                <img
                  src={require("../assets/images/smileezbal.png")}
                  height="40"
                  width="40"
                  alt="Smileez Bal"
                />
                Smileez Balance:{" "}
              </div>
              <div className="col-4 valuetext ">
                {localStorage.getItem("smileezRewards")}
              </div>
            </div>
            <div className="tab-content mt-4">
              <div
                id="Receive"
                class={tabType == "Exchange" ? "tab-pane active" : "tab-pane"}
              >
                <form className="login_from_wrapper" action="/send-smileez">
                  {message ? message : ""}

                  <div className="mt-4 pb-4">
                    <div className="labeltext">Exchange smileez with:</div>
                    <label className="">&nbsp;Select </label>

                    <select
                      name="merchant"
                      className="form-control"
                      onChange={(e) => {
                        setMerchantId(e.target.value);
                      }}
                    >
                      <option value="0">Select Merchant</option>
                      {receiverList}
                    </select>
                  </div>

                  <label className="">Exchange Smileez </label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        className="form-control"
                        required="required"
                        placeholder="Enter Exchange Smileez"
                        readonly="readonly"
                        maxLength={5}
                        autoComplete="smileez"
                        value={smileez}
                        onChange={(e) => setSmileez(e.target.value)}
                      />
                    </div>
                  </div>

                  {config}

                  <div className="footer_login">
                    {config ? (
                      <a
                        href="javascript:void(0)"
                        className="sign_Btn"
                        onClick={() =>
                          sendPayment("gratification/api/exchangeSmileez")
                        }
                      >
                        {" "}
                        Confirm{" "}
                      </a>
                    ) : (
                      <a
                        href="javascript:void(0)"
                        className="sign_Btn"
                        onClick={() =>
                          exchange("gratification/api/showConversion")
                        }
                      >
                        {" "}
                        Exchange{" "}
                      </a>
                    )}
                  </div>
                </form>
              </div>
              <div
                id="History"
                class={tabType == "History" ? "tab-pane active" : "tab-pane "}
              >
                <div className="row labeltext pb-4 pl-4">
                  Smileez’s exchanged
                </div>
                <div className="myHistory">{historyList}</div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="divider-120"></div>
    </section>
  );
};

export default ExchangeSmileez;
