import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../components/Common/Loader";

import axios, * as others from "axios";
import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import AutoSelection from "../components/Common/AutoSelection";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
import ProfileHeader from "../components/Common/ProfileHeader";
const Mobilepayment = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  const [transactionId, setTransactionId] = useState(0);
  if (!userID) {
    navigate("../login");
  }

  const [merchantPayId, setMerchantPayId] = useState(0);
  const [mobile, setMobile] = useState(0);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [txnStatus, setTxnStatus] = useState(0);

  const [receivers, setReceivers] = useState(null);
  const [sendAmount, setSendAmount] = useState("");
  const MINUTE_MS = 10000;
  const [timeLimit, setTimeLimit] = useState(0);
  const sendPayment = async () => {
    try {
      console.log(sendAmount);

      if (!sendAmount) {
        setError("Please enter amount");
      }
      if (!mobile) {
        setError("Reciver details not found");
      }
      let userEmail = localStorage.getItem("email");
      let dataToSend = {
        userId: userID,
        clientEmail: userEmail,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        receiver_mobile: mobile,
        payAmount: sendAmount,
      };

      let Url = "transactions/api/upipayment";
      let params = createSendData(dataToSend);
      const responseJson = await postFunction(Url, params);

      console.log(responseJson.status);
      if (responseJson.status === 1) {
        //console.log(response.data.data.txn_id);
        let response = responseJson.data;
        setTransactionId(response.data.txn_id);
        console.log(transactionId);
        setTimeout(function () {
          checkTxnStatus(response.data.txn_id);
        }, MINUTE_MS);
        window.location.href = response.data.link;
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      //setLoading(false);
    }
  };

  const checkTxnStatus = async (txnId) => {
    try {
      let dataToSend = {
        userId: userID,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        merchantId: merchantPayId,
        txnId: txnId,
      };

      let Url = "transactions/api/checkTxnStatus";
      //console.log(Url)
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);
      console.log(responseJson);
      if (responseJson.status === 1) {
        let response = responseJson.response.data;

        console.log("payment status " + response.payment_status);

        if (
          response.payment_status === "COMPLETED" ||
          response.payment_status === "FAILED" ||
          timeLimit >= "60000"
        ) {
          localStorage.setItem("payment_status", response.payment_status);
          localStorage.setItem("payment_txnid", response.payment_txnid);
          localStorage.setItem("payment_amount", response.payment_amount);
          localStorage.setItem(
            "payment_date",
            responseJson.response.payment_date
          );
          setLoading(false);
          navigate("../payment-status");
        } else {
          setTxnStatus(0);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      //setLoading(false);
    }
  };
  const findUser = async (mobileval) => {
    if (mobileval) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          receiver_mobile: mobileval,
        };

        let Url = "users/api/bymobile";
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);

        if (responseJson.status === 1) {
          setReceivers(responseJson.data);
        } else {
          setError(responseJson.msg);
          //navigate('../login')
        }
      } catch (error) {
        setError(error);
      } finally {
      }
    } else {
      setError("No mobile please enter mobile");
    }
  };

  useEffect(() => {
    console.log(mobile);

    if (mobile.length > 2) {
      findUser(mobile);
    }
  }, [mobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  if (merchantPayId) {
    setTimeout(() => {
      console.log("Txn Iddfdsfsfds " + transactionId);
      if (transactionId && txnStatus === 0) {
        let time = timeLimit + MINUTE_MS;
        setTimeLimit(time);
        checkTxnStatus(transactionId);
      }
    }, MINUTE_MS);
  }
  console.log("time limit " + timeLimit);
  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  const dataSet = (val) => {
    console.log(val);
    let mob = val.split(" ");
    setMobile(mob["0"]);
  };

  let receiverList = "";
  if (receivers) {
    receiverList = receivers.map(
      (rec) => rec.mobileno + " " + rec.display_name
    );
    //console.log(receiverList)
  }
  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <ProfileHeader pagename="welcome" title="Mobile Payment" />
        <Container>
          <div className="justify-content-center form">
            <form className="login_from_wrapper" action="/send-payment">
              {message ? message : ""}

              <div className="mt-4 pb-4">
                <label className="">&nbsp;Paying Smileez User </label>
                <AutoSelection
                  suggetions={receiverList}
                  placeholder="Enter user mobile"
                  mydata={dataSet}
                  length={10}
                  type="text"
                />
              </div>
              <label className="">Amount to be Send</label>
              <div className="row">
                <div className="col-md-12 pb-3">
                  <input
                    className="form-control"
                    required="required"
                    placeholder="Enter amount to be send"
                    maxLength={5}
                    autoComplete="sendamount"
                    {...sendAmount}
                    onChange={(e) => setSendAmount(e.target.value)}
                  />
                </div>
              </div>

              <div className="footer_login">
                <a href="#" className="sign_Btn" onClick={sendPayment}>
                  {" "}
                  Pay{" "}
                </a>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Mobilepayment;
