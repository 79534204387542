import React, { useState, useEffect, useRef } from "react";
import {
  API_BASE_URL,
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import {
  createSendData,
  getFunction,
  postAxiosFunction,
  createFileData,
} from "../components/Common/Actions";
import {
  getCustomDateTime,
  phpDateToJsDate,
} from "../components/Common/DateFormat";
import axios, * as others from "axios";

const Offer = (props) => {
  let navigate = useNavigate();
  const goPage = (pageName) => {
    navigate("../" + pageName);
  };

  let userID = localStorage.getItem("user_id");
  const inputRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [singleData, setSingleData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  //alert(maxdisamt);
  const buttonTitle = editMode ? "Update" : "Create";
  const [offerName, setOfferName] = useState(
    singleData ? singleData.offer_name : null
  );
  const [discountType, setDiscountType] = useState(
    singleData ? singleData.discount_type : "fix"
  );
  const [discountAmount, setDiscountAmount] = useState(
    singleData ? singleData.discount_amount : null
  );
  const [offerType, setOfferType] = useState(
    singleData ? singleData.user_type : "client"
  );
  const [maxDiscountAmount, setMaxDiscountAmount] = useState(
    singleData ? singleData.max_discount_amount : null
  );
  const [nooftimes, setNooftimes] = useState(
    singleData ? singleData.no_of_times : null
  );
  const [invoiceAmount, setInvoiceAmount] = useState(
    singleData ? singleData.ivoice_amount : null
  );
  const [offerImage, setOfferImage] = useState();
  const [offerId, setOfferId] = useState(singleData ? singleData.id : 0);

  let role_id =
    localStorage.getItem("role_id") && localStorage.getItem("role_id")
      ? localStorage.getItem("role_id")
      : CLIENT_ROLE_ID;

  const saveOffer = async (URL, offerId) => {
    try {
      if (offerName == null) {
        setError("Please enter offer name");
      } else if (discountType == null) {
        setError("Please select discount type");
      } else if (discountAmount == null) {
        setError("Please enter discount amount");
      } else if (offerType == null) {
        setError("Please enter offer type");
      } else if (maxDiscountAmount == null) {
        setError("Please enter max discount amount");
      } else if (nooftimes == null) {
        setError("Please enter no of times discount given");
      } else {
        let dataToSend = {
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: role_id,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          userId: userID,
          offer_name: offerName,
          offer_discount_type: discountType,
          discount_amt: discountAmount,
          user_type: offerType,
          offerId: offerId,
          max_discount_amount: maxDiscountAmount,
          no_of_times: nooftimes,
          invoice_amount: invoiceAmount,
          offer_image: offerImage,
        };

        let params = createFileData(dataToSend);
        let resp = await postAxiosFunction(URL, params);
        if (resp.data.status == 1) {
          setError(resp.data.msg);
          getOffer("offers/api/offerslist", 0);
        } else {
          setError(resp.data.msg);
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getOffer = async (URL, offerId) => {
    try {
      //console.log(offerId);
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;

      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
        offerId: offerId,
        merchantId: userID,
      };

      let params = createSendData(dataToSend);
      const responseJson = await getFunction(URL, params);

      if (responseJson.status === 1) {
        if (offerId) {
          setSingleData(responseJson.data);

          let row = responseJson.data;
          setInvoiceAmount(row.invoice_amount);
          setOfferName(row.offer_name);
          setDiscountType(row.discount_type);
          setDiscountAmount(row.discount_amount);
          setOfferType(row.user_type);
          setMaxDiscountAmount(row.max_discount_amount);
          setNooftimes(row.no_of_times);
          setOfferImage(row.offer_image);
          setOfferId(row.id);
          setEditMode(true);
        } else {
          setData(responseJson.data);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOffer("offers/api/offerslist", 0);
  }, []);

  const editOffer = (offerId) => {
    if (offerId) {
      getOffer("offers/api/getById", offerId);
      inputRef.current.focus();
      window.scrollTo(0, 0);
    } else {
      setError("offer id is required");
    }
  };

  let rewardsList = "";
  if (data) {
    rewardsList = data.map((item) => (
      <>
        <div className="reward_card" key={item.id}>
          <div className="row align-items-center">
            <div className="col-md-8">
              <label className="control-label">
                Created On &nbsp; {item.created_on}{" "}
              </label>
            </div>
            <div className="col-md-4 configrightbt ">
              <button onClick={() => editOffer(item.id)}>
                <img src={require("../assets/images/editicon.png")} alt="equal" />
                Edit
              </button>
            </div>
          </div>
          <hr className="hrwidth" />
          <div className="row mt-4">
            <div className="col">
              <label>Offer Name</label>
              <div> {item.offer_name} </div>
              <label>Min Invoice Amount</label>
              <div>&#x20B9;{item.invoice_amount} </div>
              <label>Max Discount Amount</label>
              <div>&#x20B9;{item.max_discount_amount} </div>
            </div>
            <div className="col">
              <img
                src={item.offer_image}
                alt={item.offer_name}
                height="200"
                width="100"
              />
            </div>
            <div className="col">
              <label>Offer Discount Amount</label>
              <div> &#x20B9;{item.discount_amt} </div>
              <label>No. of Times</label>
              <div>{item.no_of_times} </div>
            </div>
          </div>
        </div>
        <div className="divider-30"></div>
      </>
    ));
  }

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <div className="container">
      <div className="text-center">
        <button className="topbuttonRewards">
          {" "}
          Offers Configuration <br />
          Lorem ipsum dolor sit amet consectetur.
        </button>
      </div>
      <div className="divider-30"></div>
      <div className="row" key="1">
        <div className="col-md-12">
          {message}
          <div className="reward_card">
            <div className="col-md-12">
              <div className="row align-items-center mt-4">
                <label className="control-label lbold">
                  Min Invoice Amount
                </label>
                <input
                  type="text"
                  name="offer_name"
                  maxLength={10}
                  id="invoiceAmount"
                  placeholder="Min Invoice Amount"
                  className="form-control "
                  value={invoiceAmount}
                  onChange={(e) => {
                    setInvoiceAmount(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label lbold">Offer Name</label>
                <input
                  type="text"
                  ref={inputRef}
                  name="offer_name"
                  maxLength={100}
                  id="offer_name"
                  placeholder="Offer Name"
                  className="form-control "
                  value={offerName}
                  onChange={(e) => {
                    setOfferName(e.target.value);
                  }}
                />
              </div>

              <div className="row align-items-center">
                <label className="control-label lbold">Discount Type</label>
                <select
                  name="discount"
                  id="discount"
                  value={discountType}
                  className="form-control"
                  onChange={(e) => {
                    setDiscountType(e.target.value);
                  }}
                >
                  <option value="0">Select Discount Type </option>
                  <option value="Fix">Fix </option>
                  <option value="percent">Percent</option>
                </select>
              </div>

              <div className="row align-items-center mt-4">
                <label className="control-label"> Discount Amount</label>
                <input
                  type="text"
                  name="discountAmount"
                  id="discountAmount"
                  placeholder="Offer Discount Amount"
                  maxLength={10}
                  className="form-control"
                  value={discountAmount}
                  onChange={(e) => {
                    setDiscountAmount(e.target.value);
                  }}
                />
              </div>

              <div className="row align-items-center">
                <label className="control-label lbold">Offer Type</label>
                <select
                  name="offer_type"
                  id="discount"
                  value={offerType}
                  className="form-control"
                  onChange={(e) => {
                    setOfferType(e.target.value);
                  }}
                >
                  <option value="0">Select Offer Type </option>
                  <option value="client">Client </option>
                  <option value="merchant">Merchant</option>
                </select>
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label"> Max Discount Amount</label>
                <input
                  type="text"
                  name="max_discount_amt"
                  id="max_discount_amt"
                  placeholder="Max Discount Amount"
                  maxLength={6}
                  className="form-control"
                  value={maxDiscountAmount}
                  onChange={(e) => {
                    setMaxDiscountAmount(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label">No of Times</label>
                <input
                  type="text"
                  name="nooftimes"
                  id="nooftimes"
                  placeholder="No Of Times"
                  maxLength={6}
                  className="form-control"
                  value={nooftimes}
                  onChange={(e) => {
                    setNooftimes(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label"> Offer Image</label>
                <input
                  type="file"
                  name="voucherima"
                  id="voucherima"
                  placeholder="Offer Image"
                  className="form-control"
                  onChange={(e) => {
                    setOfferImage(e.target.files[0]);
                  }}
                />
                {
                  //voucherImage?<img src={voucherImage} height="100" width="100" alt="voucher image" />:''
                  //
                }
              </div>
              <input type="hidden" name="offerId" value={offerId} />
            </div>
            <div className="text-center mt-4">
              <button
                className="create-button pointer_class"
                onClick={() => saveOffer("offers/api/saveoffer", offerId)}
              >
                <img src={require("../assets/images/createicon.png")} alt="equal" />
                {buttonTitle}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="divider-30">
        <hr />
      </div>
      <h3>Offers History</h3>
      <div className="row" key="2">
        <div className="col-md-12">{rewardsList}</div>
      </div>
      <div className="divider-80"></div>
    </div>
  );
};
export default Offer;
