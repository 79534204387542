import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_BASE_URL,
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate, useLocation } from "react-router-dom";

import CountryDropdown from "country-dropdown-with-flags-for-react";
import axios, * as others from "axios";
import { Container, Row } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import { createSendData, getFunction } from "../components/Common/Actions";
const PaymentView = (props) => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  if (!userID) {
    navigate("../login");
  }

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [transactionView, setTransactionView] = useState("");

  const location = useLocation();
  const transactionId = location.state.transactionId;
  const orderpay = location.state ? location.state.orderpay : "";

  const viewDetails = async (txnId, URL) => {
    if (txnId) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          txnId: txnId,
          orderpay: orderpay,
        };
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(URL, params);

        if (responseJson.status === 1) {
          setTransactionView(responseJson.data);
        } else {
          // localStorage.clear();
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    console.log(transactionId);
    if (transactionId) {
      viewDetails(transactionId, "transactions/api/transactionView");
    }
  }, []);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  const txnView = transactionView ? (
    <div>
      <div className="text-center">To {transactionView.display_name}</div>
      <div className="text-center"> &#8377; {transactionView.amount}</div>
      <div className="text-center">
        {" "}
        {transactionView.status === 1 ? "Completed" : "Failed"}
      </div>
      <div className="text-center">
        {" "}
        <hr />
      </div>
      <div className="text-center"> {transactionView.modified_on}</div>
      <div className="text-center">
        UPI Transaction ID <br /> {transactionView.trans_ref}
      </div>
    </div>
  ) : (
    ""
  );

  return (
    <>
    
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        <Container>
          <div className="container-fluid form">{txnView}</div>
        </Container>
      </div>
    </section>
    </>);
}; //localStorage.getItem('payment_txnid')

export default PaymentView;
