import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import AllFoods from "../pages/AllFoods";
import FoodDetails from "../pages/FoodDetails";
import Cart from "../pages/Cart";
import Checkout from "../pages/Checkout";
import Contact from "../pages/Contact";
import Login from "../pages/Login";
import MerchantLogin from "../pages/MerchantLogin";
import MerchantHome from "../pages/MerchantHome";
import Landing from "../pages/Landing";
import Otp from "../pages/Otp";
import Register from "../pages/Register";
import Profile from "../pages/Profile";
import Send from "../pages/Send";
import Receive from "../pages/Receive";
import RequestList from "../pages/RequestList";
import PaymentHistory from "../pages/PaymentHistory";
import OrderHistory from "../pages/OrderHistory";
import BecomeMerchant from "../pages/BecomeMerchant";
import BusinessProfile from "../pages/BusinessProfile";
import BankDetails from "../pages/BankDetails";
import Payment from "../pages/Payment";
import Mobilepayment from "../pages/Mobilepayment";
import PaymentStatus from "../pages/PaymentStatus";
import PaymentView from "../pages/PaymentView";
import RewardConfig from "../pages/RewardConfig";
import Voucher from "../pages/Voucher";
import Offer from "../pages/Offer";
import PaymentOptions from "../pages/PaymentOptions";
import RewardEarned from "../pages/RewardEarned";
import EquivalentCash from "../pages/EquivalentCash";
import VoucherDetails from "../pages/VoucherDetails";
import ExchangeSmileez from "../pages/ExchangeSmileez";
import CategoryVouchers from "../pages/CategoryVouchers";
import Notification from "../pages/Notification";
//import CallerIdView from './CallerIdView'
//import LandingLayout from '../components/Layout/LandingLayout';
import Layout from "../components/Layout/Layout";
import LayoutContainer from "../components/Layout/LayoutContainer";
import FirstLoader from "../components/SplashScreen/FirstLoader";
import Page404 from "../pages/Page404";
//for merchant
import RegisterBussiness from "../pages/RegisterBussiness";
import RegisterBussinessStep2 from "../pages/RegisterBussinessStep2";

import Welcome from "../pages/Welcome";
import Offerspage from "../pages/Offerspage";
import QrCodeScan from "../pages/QrCodeScan";
import Search from "../pages/Search";
import AddNewOffer from "../pages/AddNewOffer";
import Inventory from "../pages/Inventory";
import Billings from "../pages/Billings";
import Settlements from "../pages/Settlements";
import Invoices from "../pages/Invoices";
import SuccessPage from "../pages/SuccessPage";
import FailPage from "../pages/FailPage";
import Demo from "../pages/Demo";

const CallerIdView = () => {
  console.log("landing page");
  return <div className="row">sds</div>;
};

const Routers = () => {
  return (
    <Routes>
      <Route path="/" component={LayoutContainer}>
        <Route path="/" element={<FirstLoader />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/merchant-login" element={<MerchantLogin />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/CallerId" element={<CallerIdView />} />
        <Route path="/register" element={<Register />} />
        <Route path="/become-merchant" element={<BecomeMerchant />} />
        <Route path="/register-business" element={<RegisterBussiness />} />
        <Route
          path="/register-business-step2"
          element={<RegisterBussinessStep2 />}
        />
      </Route>

      <Route component={Layout}>
        <Route path="/dashboard" element={<MerchantHome />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/send-smileez" element={<Send />} />
        <Route path="/receive-smileez" element={<Receive />} />
        <Route path="/request-smileez-list" element={<RequestList />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/mob-payment" element={<Mobilepayment />} />

        <Route path="/payment-status" element={<PaymentStatus />} />
        <Route path="/payment-history" element={<PaymentHistory />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/payment-view" element={<PaymentView />} />
        <Route path="/reward-config" element={<RewardConfig />} />
        <Route path="/business-profile" element={<BusinessProfile />} />
        <Route path="/bank-details" element={<BankDetails />} />

        <Route path="/offer" element={<Offer />} />
        <Route path="/notification" element={<Notification />} />
       
        <Route path="/reward-earned" element={<RewardEarned />} />
        <Route path="/equivalent-cash" element={<EquivalentCash />} />
        <Route path="/voucher-details" element={<VoucherDetails />} />
        <Route path="/exchange-smileez" element={<ExchangeSmileez />} />
        <Route path="/category-vouchers" element={<CategoryVouchers />} />
        <Route path="/home" element={<Home />} />
        <Route path="/foods" element={<AllFoods />} />
        <Route path="/foods/:id" element={<FoodDetails />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/contact" element={<Contact />} />
        
        {/* ------ Rohan ------ */}
        <Route path="/voucher" element={<Voucher />} />
        <Route path="/qrcodescan" element={<QrCodeScan />} />
        <Route path="/offerspage" element={<Offerspage />} />
        <Route path="/pay" element={<PaymentOptions />} />
        <Route path="/search" element={<Search />} />
        <Route path="/add-new-offer" element={<AddNewOffer />} />
        <Route path="/inventory" element={<Inventory />} />
        <Route path="/billings" element={<Billings />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/settlements" element={<Settlements />} />
        <Route path="/success-page" element={<SuccessPage />} />
        <Route path="/fail-page" element={<FailPage />} />
        <Route path="/demo" element={<Demo />} />
      </Route>

      <Route component={Page404}>
        <Route path="*" component={Page404} />
      </Route>
    </Routes>
  );
};

export default Routers;
