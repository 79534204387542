import React, { useState, useEffect } from "react";
import {
  API_BASE_URL,
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import RewardsTransactions from "./RewardsTransactions";
import AmountTransactions from "./AmountTransactions";
import { getCurrentDate, getCustomDate } from "../components/Common/DateFormat";
import { createSendData, getFunction } from "../components/Common/Actions";
import { useNavigate } from "react-router-dom";
import "../assets/css/style.css";

const MerchantHome = () => {
  let navigate = useNavigate();
  const goPage = (pageName) => {
    navigate("../" + pageName);
  };
  let role_id =
    localStorage.getItem("role_id") && localStorage.getItem("role_id")
      ? localStorage.getItem("role_id")
      : CLIENT_ROLE_ID;
  if (role_id == CLIENT_ROLE_ID) {
    goPage("welcome");
  }
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let userID = localStorage.getItem("user_id");
  const [transactionData, setTransactionData] = useState(null);
  const [weekTransactionData, setWeekTransactionData] = useState(null);
  const [monthTransactionData, setMonthTransactionData] = useState(null);
  const [monthRewardData, setMonthRewardData] = useState(null);
  const [weekRewardData, setWeekRewardData] = useState(null);
  const [rewardsTxn, setRewardsTxn] = useState(null);
  const [rewardRedeemtion, setRewardRedeemtion] = useState(0);
  let weekdays = 7;
  let monthdays = 30;

  const getTransactions = async (URL, fromDate, toDate, daysdiff) => {
    try {
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
        from_date: fromDate,
        to_date: toDate,
      };

      let params = createSendData(dataToSend);

      const responseJson = await getFunction(URL, params);

      if (responseJson.status === 1) {
        if (fromDate != "" && daysdiff === 7) {
          setWeekTransactionData(responseJson.data);
        } else if (fromDate != "" && daysdiff === 30) {
          setMonthTransactionData(responseJson.data);
        } else {
          setTransactionData(responseJson.data);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getRewardByDate = async (URL, fromDate, toDate, daysdiff) => {
    let resp = "";
    try {
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;

      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
        from_date: fromDate,
        to_date: toDate,
      };
      let params = createSendData(dataToSend);

      resp = await getFunction(URL, params);
      if (daysdiff === 7 && resp.status === 1) {
        setWeekRewardData(resp.data);
      } else if (daysdiff === 30 && resp.status === 1) {
        setMonthRewardData(resp.data);
      } else {
        setMonthRewardData(resp.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getRewardsTxn = async (URL, sendReceive) => {
    let resp = "";
    try {
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;

      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
      };
      let params = createSendData(dataToSend);

      resp = await getFunction(URL, params);
      if (sendReceive === "send") {
        if (resp.status === 1) {
          setRewardsTxn(resp.data);
        } else {
          setRewardsTxn(resp.msg);
        }
      } else {
        if (resp.status === 1) {
          setRewardRedeemtion(resp.data);
        } else {
          setRewardRedeemtion(resp.msg);
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTransactions("transactions/api/totalsuccess", "", "", 0);
    let fromDate = getCurrentDate();

    let todate = getCustomDate(fromDate, weekdays);
    getTransactions(
      "transactions/api/searchByCustomDates",
      fromDate,
      todate,
      weekdays
    );
    getRewardByDate(
      "gratification/api/checkRewardByDates",
      fromDate,
      todate,
      weekdays
    );
    todate = getCustomDate(fromDate, monthdays);
    getTransactions(
      "transactions/api/searchByCustomDates",
      fromDate,
      todate,
      monthdays
    );
    getRewardByDate(
      "gratification/api/checkRewardByDates",
      fromDate,
      todate,
      monthdays
    );
    getRewardsTxn("gratification/api/rewardTransaction", "send");
    getRewardsTxn("gratification/api/rewardRedeemtion", "receive");
  }, []);

  return (
    <React.Fragment>
      <div className="transaction_section_wrapper">
        <div className="container">
          <RewardsTransactions />
          <div className="mt-4"></div>
          <AmountTransactions />
        </div>
      </div>

      <div className="mt-3 mt-lg-5 mt-sm-5"></div>

      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3> Create </h3>
            </div>
          </div>
          <div className="pb-2"></div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-6">
              <div
                className="voucher_create_wrapper pointer_class p-2"
                onClick={() => goPage("voucher")}
              >
                <img
                  className="imagecreate"
                  src={require("../assets/images/voucher_icon.png")}
                  alt="voucher creation"
                />
                <div className="textCreate"> Create Vouchers </div>
                <div className="stars_icon_section">
                  <img
                    src={require("../assets/images/stars_icon.png")}
                    alt="stars_icon.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <div
                className="voucher_create_wrapper pointer_class p-2"
                onClick={() => goPage("offer")}
              >
                <img
                  className="imagecreate"
                  src={require("../assets/images/voucher_offer_icon.png")}
                  alt="voucheroffer"
                />
                <div className="textCreate"> Create Offers </div>
                <div className="stars_icon_section">
                  <img
                    src={require("../assets/images/stars_icon.png")}
                    alt="stats"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <div
                className="voucher_create_wrapper pointer_class p-2 mt-5"
                onClick={() => goPage("add-new-offer")}
              >
                <img
                  className="imagecreate"
                  src={require("../assets/images/voucher_icon.png")}
                  alt="voucher creation"
                />
                <div className="textCreate"> Create New Offers </div>
                <div className="stars_icon_section">
                  <img
                    src={require("../assets/images/stars_icon.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-6">
              <div
                className="voucher_create_wrapper pointer_class p-2 mt-5"
                // onClick={() => goPage("offer")}
              >
                <img
                  className="imagecreate"
                  src={require("../assets/images/voucher_offer_icon.png")}
                  alt="voucheroffer"
                />
                <div className="textCreate"> Hot Deals </div>
                <div className="stars_icon_section">
                  <img
                    src={require("../assets/images/stars_icon.png")}
                    alt="stats"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider-80"></div>

      <section>
        <div className="container">
          <div className="row">
            <div className="col col-4">
              <div className="percentage_card_section">
                <div className="percentage_card_wrapper">
                  <h4>
                    {" "}
                    {transactionData
                      ? transactionData.totalPercentageSuccess
                      : "0"}
                    %{" "}
                  </h4>
                  <div className="stars_icon_section-01">
                    <img
                      src={require("../assets/images/stars_icon.png")}
                      alt="stars icons"
                    />
                  </div>
                </div>
                <p> Success percentage </p>
              </div>
            </div>
            <div className="col col-4">
              <div className="percentage_card_section">
                <div className="percentage_card_wrapper">
                  <h4>
                    {" "}
                    <span>
                      {" "}
                      <img
                        src={require("../assets/images/rupee_icon.png")}
                        alt="rupees"
                      />{" "}
                    </span>{" "}
                    <span>
                      {" "}
                      {transactionData ? transactionData.totalAmount : "0"}{" "}
                    </span>{" "}
                  </h4>
                  <div className="stars_icon_section-01">
                    <img
                      src={require("../assets/images/stars_icon.png")}
                      alt="stars"
                    />
                  </div>
                </div>
                <p> Success % by amount </p>
              </div>
            </div>
            <div className="col col-4">
              <div className="percentage_card_section">
                <div className="percentage_card_wrapper">
                  <h4>
                    {" "}
                    <span>
                      {" "}
                      <img
                        src={require("../assets/images/rupee_icon.png")}
                        alt="ruppes"
                      />{" "}
                    </span>{" "}
                    <span>
                      {" "}
                      {weekTransactionData
                        ? weekTransactionData.datesAmount
                        : "0"}
                    </span>{" "}
                  </h4>
                  <div className="stars_icon_section-01">
                    <img
                      src={require("../assets/images/stars_icon.png")}
                      alt="stars"
                    />
                  </div>
                </div>
                <p> Transactions this week </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-4">
              <div className="percentage_card_section">
                <div className="percentage_card_wrapper">
                  <h4>
                    {" "}
                    <span>
                      {" "}
                      <img
                        src={require("../assets/images/rupee_icon.png")}
                        alt="s"
                      />{" "}
                    </span>{" "}
                    <span>
                      {" "}
                      {monthTransactionData
                        ? monthTransactionData.datesAmount
                        : "0"}{" "}
                    </span>{" "}
                  </h4>
                  <div className="stars_icon_section-01">
                    <img
                      src={require("../assets/images/stars_icon.png")}
                      alt=""
                    />
                  </div>
                </div>
                <p> Transactions this month </p>
              </div>
            </div>
            <div className="col col-4">
              <div className="percentage_card_section">
                <div className="percentage_card_wrapper">
                  <h4>
                    {" "}
                    {weekRewardData ? weekRewardData.datesRewards : "0"}{" "}
                  </h4>
                  <div className="stars_icon_section-01">
                    <img
                      src={require("../assets/images/stars_icon.png")}
                      alt=""
                    />
                  </div>
                </div>
                <p> Reward this week </p>
              </div>
            </div>
            <div className="col col-4">
              <div className="percentage_card_section">
                <div className="percentage_card_wrapper">
                  <h4>
                    {" "}
                    {monthRewardData ? monthRewardData.datesRewards : "0"}{" "}
                  </h4>
                  <div className="stars_icon_section-01">
                    <img
                      src={require("../assets/images/stars_icon.png")}
                      alt=""
                    />
                  </div>
                </div>
                <p> Reward this month </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-5 smallDevice"></div>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-6">
              <div className="reward_card transactions_reward">
                <h4> Reward transactions </h4>
                <img
                  src={require("../assets/images/reward_gift-01.png")}
                  alt="reward_img"
                />
                <p>
                  {" "}
                  <span>
                    {" "}
                    <img
                      src={require("../assets/./images/rupee_icon.png")}
                      alt=""
                    />{" "}
                  </span>{" "}
                  {rewardsTxn ? setRewardsTxn.total : "0"}{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-6">
              <div className="reward_card transactions_reward">
                <h4> Reward redemption </h4>
                <img
                  src={require("../assets/images/reward_gift-02.png")}
                  alt="reward_img"
                />
                <p> {rewardRedeemtion ? rewardRedeemtion.total : "0"} </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="mt-4 smallDevice"></div>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="reward_card config_reward_card">
                <div
                  className="row align-items-center pointer_class"
                  onClick={() => goPage("reward-config")}
                >
                  <div className="col-8">
                    <div className="reward_config_section_01 ">
                      <img
                        src={require("../assets/images/creward_gift.png")}
                        alt="config_reward"
                      />
                      <p> Reward configuration </p>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="text-right">
                      <img
                        src={require("../assets/images/rht_arrow.png")}
                        alt="config_arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="divider-120"></div>
    </React.Fragment>
  );
};

export default MerchantHome;
