import React, { useState, useEffect } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link } from "react-router-dom";
import { createSendData, getFunction } from "../components/Common/Actions";
import AutoSelection from "../components/Common/AutoSelection";
//import axios, * as others from 'axios';
import { Container } from "reactstrap";
import ProfileHeader from "../components/Common/ProfileHeader";
import Checkbox from "../components/Common/Checkbox";
import AppLoader from "../components/Common/Loader";

const Send = () => {
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  //const [values,setValues] = useState('')
  const [mobile, setMobile] = useState("");
  //const country_code = useFormInput('')
  const [countryCode, setCountryCode] = useState(91);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let userID = localStorage.getItem("userId");
  const [receivers, setReceivers] = useState(null);
  const sendpoint = useFormInput("");
  const [totalRequest, setTotalRequest] = useState(0);
  const [tabType, setTabType] = useState("Send");
  const [request, setRequest] = useState(null);
  const [sentSmileez, setSentSmileez] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const sendSmileez = async () => {
    try {
      //console.log(fullname.value+email.value+mobile.value+country_code.value)
      let dataToSend = {
        userId: userID,
        countryCode: countryCode,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        receiver_mobile: mobile,
        smileez: sendpoint.value,
      };

      let Url = "gratification/api/sendsmileez";
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);
      if (responseJson.status === 1) {
        //console.log(responseJson.data)
        setError(responseJson.msg);
        let balance =
          parseFloat(localStorage.getItem("smileezRewards")) -
          parseFloat(sendpoint.value);
        localStorage.setItem("smileezRewards", balance);
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    //console.log(mobile.value)
    if (mobile && mobile.length >= 4) {
      const findUser = async (mobileval) => {
        //console.log(mobileval)
        if (mobileval > 4) {
          try {
            let dataToSend = {
              userId: localStorage.getItem("userId"),
              countryCode: 91,
              mobile_no: localStorage.getItem("mobile_no"),
              KEY: API_KEY,
              role_id: CLIENT_ROLE_ID,
              brand_id: BRAND_ID,
              app_os: APP_OS,
              imei_no: IMEI_NO,
              app_id: APP_ID,
              token_key: localStorage.getItem("token_key"),
              receiver_mobile: mobileval,
            };

            let Url = "users/api/bymobile";
            //console.log(Url)
            let params = createSendData(dataToSend);
            const responseJson = await getFunction(Url, params);
            console.log(responseJson);
            if (responseJson.status === 1) {
              setReceivers(responseJson.data);
            } else {
              setError(responseJson.msg);
            }
          } catch (error) {
            setError(error);
          } finally {
            setLoading(false);
          }
        }
      };
      findUser(mobile);
    }
  }, [mobile]);

  useEffect(() => {
    const requestForApproval = async () => {
      try {
        let dataToSend = {
          userId: localStorage.getItem("userId"),
          countryCode: 91,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
        };

        let Url = "gratification/api/check_request";
        //console.log(Url)
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);
        if (responseJson.status === 1) {
          setTotalRequest(responseJson.total);
        } else {
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    requestForApproval();
  }, []);
  const myRewards = async () => {
    let dataToSend1 = {
      userId: userID,
      mobile_no: localStorage.getItem("mobile_no"),
      KEY: API_KEY,
      role_id: CLIENT_ROLE_ID,
      brand_id: BRAND_ID,
      app_os: APP_OS,
      imei_no: IMEI_NO,
      app_id: APP_ID,
      token_key: localStorage.getItem("token_key"),
    };

    let reUrl = "gratification/api/sentSmileezByMe";
    let reparams = createSendData(dataToSend1);
    const rewardsJson = await getFunction(reUrl, reparams);
    if (rewardsJson.status === 1) {
      setSentSmileez(rewardsJson.data);
    } else {
      //setError(rewardsJson.msg)
    }
  };
  const dataSet = (val) => {
    let mob = val.split(" ");
    setMobile(mob["0"]);
  };

  let receiList = "";
  if (receivers) {
    receiList = receivers.map((rec) => rec.mobileno + " " + rec.display_name);
  }

  const tabchanged = (type) => {
    setTabType(type);
  };
  const requestForApproval2 = async () => {
    try {
      let dataToSend = {
        userId: userID,
        countryCode: countryCode,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
      };

      let Url = "gratification/api/smzrequests";
      //console.log(Url)
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);
      if (responseJson.status === 1) {
        setRequest(responseJson.data);
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateStatus = async (statusVal, requestId) => {
    try {
      //console.log(fullname.value+email.value+mobile.value+country_code.value)
      let dataToSend = {
        userId: userID,
        countryCode: countryCode,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        request_id: requestId,
        status: statusVal,
      };

      let Url = "gratification/api/updatereqstatus";
      //console.log(Url)
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);
      if (responseJson.status === 1) {
        //console.log(responseJson.data)
        setError(responseJson.msg);
        requestForApproval2();
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  let requestData = "";
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(request.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const handleClick = (e) => {
    //console.log('hi')
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    myRewards();
    requestForApproval2();
  }, [tabType]);
  let requestbuttons = null;

  let reqfrom = null;
  if (request) {
    requestData = request.map(
      (req) => (
        <tr key={req.id}>
          <td>
            <Checkbox
              key={req.id}
              type="checkbox"
              name={req.display_name}
              id={req.id}
              handleClick={handleClick}
              isChecked={isCheck.includes(req.id)}
            />
          </td>
          <td>{req.display_name}</td>
          <td>{req.smileez}</td>
          <td>{req.created_on}</td>
          <td>
            {req.status}
            {req.status === "Pending" && userID === req.created_by ? (
              <a
                href="#"
                name="cancel"
                className="btn btn-warning"
                onClick={() => updateStatus("Cancel", req.id)}
              >
                Cancel{" "}
              </a>
            ) : (
              ""
            )}
            {req.status === "Pending" && userID !== req.created_by ? (
              <div>
                <a
                  name="approved"
                  className="btn btn-primary"
                  onClick={() => updateStatus("Approved", req.id)}
                >
                  Approved{" "}
                </a>{" "}
                <a
                  name="rejected"
                  className="btn btn-warning"
                  onClick={() => updateStatus("Rejected", req.id)}
                >
                  Rejected{" "}
                </a>
              </div>
            ) : (
              ""
            )}{" "}
          </td>
        </tr>
      )
      //reqfrom=req.reqfrom
    );

    //requestbuttons =request.map((req)=> (req.created_by!==req.request_user_id)? <div ><button name="approved" className="btn btn-primary">Approved </button> <button name="rejected" className="btn btn-warning">Rejected </button></div>:'')
  }
  let historyList = "";
  if (sentSmileez.length > 0) {
    historyList = sentSmileez.map((rec) => (
      <div className="row historyList">
        <div className="col-9 col-xs-12">
          <div className="col-12 historylebel">To, {rec.display_name}</div>
          <div className="col-12 historysubtext">
            Mobile No: {rec.mobileno} | Date: {rec.createdon}
          </div>
        </div>
        <div className="col-3 col-xs-12 text-right historytext1">
          {rec.amount}
          <br />
          Smileez
        </div>
        <hr />
      </div>
    ));
  } else {
    historyList = (
      <div className=" historyNotFound text-center ">
        <img
          src={require("../assets/images/material-symbols_error-outline.png")}
          height="84"
          width="85"
          alt="No Data found"
        />
        <div className="text-center historyNotFoundtext">
          Not able to load the data
        </div>
      </div>
    );
  }

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  return (
    <section className="main_section_wrapper">
      {loading && loading ? <AppLoader /> : ""}
      <div className="welcome_section_wrapper">
        <ProfileHeader pagename="welcome" title="Send Smileez" />
        <Container>
          <div className="justify-content-center tabbable mt-4">
            <div className=" mb-4">
              <ul className="nav nav-tabs">
                <li className={tabType == "Send" ? "active" : "inactive"}>
                  <a
                    data-toggle="tab"
                    href="#Send"
                    onClick={() => tabchanged("Send")}
                  >
                    Send
                  </a>
                </li>
                <li className={tabType == "History" ? "active" : "inactive"}>
                  <a
                    data-toggle="tab"
                    href="#History"
                    onClick={() => tabchanged("History")}
                  >
                    History
                  </a>
                </li>
                <li className="inactive">
                  {totalRequest ? (
                    <a
                      data-toggle="tab"
                      href="#request-smileez-list"
                      onClick={() => tabchanged("request-smileez-list")}
                    >
                      Waiting for approval({totalRequest})
                    </a>
                  ) : (
                    "Waiting for approval({totalRequest})"
                  )}
                </li>
              </ul>
            </div>

            <div className="row smileez_balance ">
              <div className="col-8 textcolor">
                <img
                  src={require("../assets/images/smileezbal.png")}
                  height="40"
                  width="40"
                  alt="Smileez Bal"
                />
                Smileez Balance:{" "}
              </div>
              <div className="col-4 valuetext ">
                {localStorage.getItem("smileezRewards")}
              </div>
            </div>
            <div className="tab-content mt-4">
              <div
                id="Receive"
                class={tabType == "Send" ? "tab-pane active" : "tab-pane"}
              >
                <form className="login_from_wrapper" action="/send-smileez">
                  {message}

                  <div className="mt-4 pb-4">
                    <div className="labeltext">Send Smileez To User</div>
                    <label className="">&nbsp;Enter Mobile no: </label>
                    <AutoSelection
                      suggetions={receiList}
                      placeholder="Enter user mobile"
                      mydata={dataSet}
                      length={10}
                      type="text"
                    />
                  </div>

                  <label>Enter no. of Smileez to be Send:</label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        className="form-control"
                        placeholder="Enter Points to be sent"
                        maxLength={10}
                        autoComplete="sendpoint"
                        {...sendpoint}
                      />
                    </div>
                  </div>

                  <div className="footer_login">
                    <a
                      href="javascript:void(0);"
                      className="sign_Btn"
                      onClick={sendSmileez}
                    >
                      {" "}
                      Send{" "}
                    </a>
                  </div>
                </form>
              </div>
              <div
                id="History"
                class={tabType == "History" ? "tab-pane active" : "tab-pane "}
              >
                <div className="row labeltext pb-4 pl-4">Smileez’s sent</div>
                <div className="myHistory">{historyList}</div>
              </div>
              <div
                id="request-smileez-list"
                class={
                  tabType == "request-smileez-list"
                    ? "tab-pane active"
                    : "tab-pane "
                }
              >
                <div className="row labeltext pb-4 pl-4">
                  Send Smileez Pending List
                </div>
                <div className="myHistory">
                  <table className="table table-striped table-hover table-resposive">
                    <thead>
                      <tr>
                        <th>
                          <Checkbox
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            handleClick={handleSelectAll}
                            isChecked={isCheckAll}
                          />
                        </th>
                        <th>Request {reqfrom}</th>
                        <th>Smileez</th>
                        <th>Request Date</th>
                        <th>Status</th>
                      </tr>
                    </thead>

                    {requestData}

                    <tfoot></tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Send;
