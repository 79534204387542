import React, { useRef, useEffect, useState } from "react";

import { Container } from "reactstrap";
//import logo from "../../assets/images/res-logo.png";
import { json, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  API_BASE_URL,
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../../Constants";
import { cartUiActions } from "../../store/shopping-cart/cartUiSlice";

import Wallets from "../Common/Wallets";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
// import "../../assets/css/responsive.css";

import "../../assets/font-awesome-4.7.0/css/font-awesome.min.css";
import user from "../../assets/images/user.png";
import SLogo from "../Common/SLogo";
import notification from "../../assets/images/notification.png";

const nav__links = [
  {
    display: "Home",
    path: "/home",
  },
  {
    display: "Landing",
    path: "/landing",
  },
  {
    display: "Foods",
    path: "/foods",
  },

  {
    display: "Cart",
    path: "/cart",
  },
  {
    display: "Contact",
    path: "/contact",
  },
];

const Header = () => {
  let navigate = useNavigate();

  const Logout = () => {
    localStorage.setItem("currentUser", false);
    localStorage.clear();
    window.location.href = "/user/landing";
  };

  const [sticky, setSticky] = useState("scroll");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setSticky(window.scrollY > 50 ? "sticky" : "");
    });
  }, []);

  const goPage = (pageUrl) => {
    navigate("../" + pageUrl);
  };
  const menuRef = useRef(null);

  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [roleType, setRoleType] = useState("");
  const [error, setError] = useState("");
  const [userData, setUserData] = useState({});
  const toggleMenu = () => menuRef.current.classList.toggle("show__menu");

  const toggleCart = () => {
    dispatch(cartUiActions.toggle());
  };
  let role_id =
    localStorage.getItem("role_id") && localStorage.getItem("role_id")
      ? localStorage.getItem("role_id")
      : CLIENT_ROLE_ID;

  const userInfo = async () => {
    try {
      let userID = localStorage.getItem("userId");
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
      };

      let formBody = [];
      for (let key in dataToSend) {
        let encodedKey = encodeURIComponent(key);
        let encodedValue = encodeURIComponent(dataToSend[key]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");
      let Url = API_BASE_URL + "users/api/userinfo";
      const response = await fetch(Url + "?" + formBody);
      const responseJson = await response.json();

      if (responseJson.status === 1) {
        setUserData(responseJson.userdata);
        localStorage.setItem("userInfo", responseJson.userdata);
        localStorage.setItem("roleName", responseJson.userdata.role_name);

        localStorage.setItem("currentUser", true);
        localStorage.setItem(
          "smileezRewards",
          responseJson.userdata.smileez_rewards
        );
        localStorage.setItem(
          "walletAmount",
          responseJson.userdata.wallet_amount
        );
        localStorage.setItem("mobile_no", responseJson.userdata.mobile);
        localStorage.setItem("email", responseJson.userdata.email);
        localStorage.setItem(
          "display_name",
          responseJson.userdata.display_name
        );
        localStorage.setItem("bank_name", responseJson.bankdata.bank_name);
        localStorage.setItem("bank_url", responseJson.bankdata.bank_url);
        localStorage.setItem(
          "bank_access_token",
          responseJson.bankdata.bank_access_token
        );
        localStorage.setItem(
          "bank_txn_status_url",
          responseJson.bankdata.bank_txn_status_url
        );
      } else {
        // localStorage.clear();
        Logout();
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
      console.log("error " + error);
    } finally {
      setLoading(false);
    }
  };

  const [typeActive, setTypeActive] = useState({
    notification: "",
  });
  const chng = (type, roleType) => {
    if (roleType == "Merchant") {
      navigate("/notification");
    } else {
      let dataSet = {};
      Object.keys(typeActive).map((item) => {
        if (item === type) {
          dataSet[type] = "active";
        } else {
          dataSet[item] = "";
        }
      });
      setTypeActive(dataSet);
      navigate("../" + type);
    }
  };

  useEffect(() => {
    userInfo();
    const roleName = localStorage.getItem("roleName");
    setRoleType(roleName);
  }, []);

  let navlint = role_id && role_id == CLIENT_ROLE_ID ? "welcome" : "dashboard";
  let lengthx = localStorage.getItem("display_name").length;
  let displaypostfix = lengthx > 6 ? "..." : "";
  let display =
    localStorage.getItem("display_name").substr(0, 6) + displaypostfix;

  return (
    <>
      <div
        position="header_section "
        className={`header_section mb-4 ${sticky}`}
      >
        <Container>
          <div className="mt-4">{error}</div>
          <div className="row align-items-end">
            <div className="col-4">
              <div className="user_profile">
                <img src={user} alt={display} />
                <h5> {display} </h5>
              </div>
            </div>
            <div className="col-4">
              <div className="logo_part text-center">
                <img src={require("../../assets/images/smiliiz-logo.png")} alt="logo" className="img-fluid" />
              </div>
            </div>

            {roleType == "Merchant" && (
              <div className="col-2">
                <div className="text-right">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => chng("notification", "Merchant")}
                  >
                    {localStorage.getItem("notification")}
                    <div className="icon">
                      <img src={notification} alt="Notification" />
                    </div>
                  </a>
                </div>
              </div>
            )}

            {roleType == "Client" && (
              <div className="col-4">
                <div className="text-right">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => chng("notification")}
                  >
                    {localStorage.getItem("notification")}
                    <div className="icon">
                      <img src={notification} alt="Notification" />
                    </div>
                  </a>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>

      {window.location.pathname == "/user/welcome" ? (
        <Wallets
          smileezRewards={localStorage.getItem("smileezRewards")}
          walletAmount={localStorage.getItem("walletAmount")}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
