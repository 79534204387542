import React, { useState } from "react";
import jsQR from "jsqr";

function Demo() {
  const [qrCodeData, setQRCodeData] = useState("");
  const [error, setError] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        scanQRCodeFromImage(imageData);
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  const scanQRCodeFromImage = (imageData) => {
    const code = jsQR(imageData.data, imageData.width, imageData.height);
    if (code) {
      setQRCodeData(code.data);
      setError("");
    } else {
      setError("Unable to scan QR code.");
    }
  };

  return (
    <>
      <input type="file" accept="image/*" onChange={handleFileUpload} />
      {qrCodeData && <p>QR Code Data: {qrCodeData}</p>}
      {error && <p>{error}</p>}
    </>
  );
}

export default Demo;
