import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";

import CountryDropdown from "country-dropdown-with-flags-for-react";

import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import { dateFormat } from "../components/Common/DateFormat";
import { createSendData, getFunction } from "../components/Common/Actions";
import ProfileHeader from "../components/Common/ProfileHeader";
const OrderHistory = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState(null);
  const [orders, setOrders] = useState(null);
  const [search, setSearch] = useState("");

  const orderHistory = async (orderId, searchText) => {
    if (orderId) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          search: searchText,
        };

        let Url = "transactions/api/orderList";
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);
        console.log(responseJson);
        if (responseJson.status === 1) {
          setOrders(responseJson.data);
        } else {
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    orderHistory(userID, "");
  }, []);

  useEffect(() => {
    orderHistory(userID, search);
  }, [search]);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  let requestData = "";
  if (orders) {
    requestData = orders.map((req) => (
      <tr key={req.id}>
        <td>
          <div className="checkout-details">
            <div className="row">
              <div className="col-lg-8 mt-4">
                <span className="orderhistory_text">
                  {" "}
                  Order: #{req.order_id}
                </span>
                <br />
                <span className="orderh_date">
                  Date:{dateFormat(req.created_on)}
                </span>
                <br />
                <span className="orderh_date">
                  Price: &#x20B9;{req.order_price}
                </span>
                <br />
                <span className="orderh_date">Status: {req.status}</span>
              </div>
              <div className="col-lg-4 mt-4 text-right padding-right">
                {req.img_url ? (
                  <img
                    src={req.img_url}
                    alt="order item"
                    width="84"
                    height="92"
                  />
                ) : (
                  <img src={require("../assets/images/item_img.png")} alt="order item" />
                )}
              </div>
            </div>
          </div>
          <div className="order_history_footer text-right">
            {req.status === "Suceess" ? (
              <Link to="#" className="cancel_order">
                <img src={require("../assets/images/delete_icon.png")} alt="Cancel Order" />
                &nbsp;Cancel Order
              </Link>
            ) : (
              ""
            )}{" "}
          </div>
        </td>
      </tr>
    ));

    //requestbuttons =request.map((req)=> (req.created_by!==req.request_user_id)? <div ><button name="approved" className="btn btn-primary">Approved </button> <button name="rejected" className="btn btn-warning">Rejected </button></div>:'')
  }
  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        <ProfileHeader pagename="welcome" title="Orders History" />
        <Container>
          <div className="container-fluid form">
            <form className="login_from_wrapper">
              <div className="row text-cemter">
                <input
                  type="text"
                  name="serach"
                  placeholder="Serch Transactions"
                  maxleangth="50"
                  className="form-control"
                  {...search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <table className="table table-striped table-hover table-resposive">
                <thead>
                  <tr>
                    <td className="center" colSpan="2"></td>
                  </tr>
                </thead>

                {requestData}
              </table>
            </form>
          </div>
        </Container>
      </div>
    </section>
  );
}; //localStorage.getItem('payment_txnid')

export default OrderHistory;
