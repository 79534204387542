import React, { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import CommonSection from "../components/UI/common-section/CommonSection";
import Helmet from "../components/Helmet/Helmet";

import "../styles/checkout.css";
import {API_KEY, APP_ID, APP_OS, CLIENT_ROLE_ID, IMEI_NO,BRAND_ID } from '../Constants'
import {createSendData,postFunction,getFunction} from '../components/Common/Actions'
import ProfileHeader from '../components/Common/ProfileHeader';
import AutoSelection from "../components/Common/AutoSelection";
import CountryDropdown from 'country-dropdown-with-flags-for-react';
import { useNavigate } from 'react-router-dom';
const Checkout = () => {
    let navigate = useNavigate();
    const [shippingDetail,setShippingDetail]=useState(null)
    
  const [enterName, setEnterName] = useState("");
  const [enterEmail, setEnterEmail] = useState("");
  const [enterNumber, setEnterNumber] = useState("");
  const [enterCountry, setEnterCountry] = useState("");
  const [enterCity, setEnterCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address,setAddress]=useState("")
const [countryCode,setCountryCode]=useState(0);
  const shippingInfo = [];
  const cartTotalAmount = useSelector((state) => state.cart.totalAmount);
  const cartItems = useSelector((state) => state.cart.cartItems);
  let shippingCost = 0;
 let merchantIds='';
 cartItems.map(cartProduct => {
     merchantIds +=cartProduct.merchant_id+','
        shippingCost += parseInt(cartProduct.shipping_charges)
    })
  

  const totalAmount = cartTotalAmount + Number(shippingCost);
const userID =localStorage.getItem('user_id')
  const submitHandler = async (e) => {
    e.preventDefault();
    const userShippingAddress = {
      name: enterName,
      email: enterEmail,
      phone: enterNumber,
      address:address,
      country: enterCountry,
      city: enterCity,
      postalCode: postalCode,
      countryCode:countryCode,
    };

  
    shippingInfo.push(userShippingAddress);
    let dataToSend= {
           mobile_no:localStorage.getItem('mobile_no'),KEY:API_KEY,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),userId:userID,
         name: enterName,
      email: enterEmail,
      phone: enterNumber,
      address:address,
      country: enterCountry,
      city: enterCity,
      postalCode: postalCode,role_id:CLIENT_ROLE_ID,amount:totalAmount
         
        }
        let params = createSendData(dataToSend);
       let URL = 'shopping/api/shippingaddress';
        const responseJson =  await postFunction(URL,params); 
      
      if(responseJson.status===1){
          URL = 'transactions/api/order';
         const resp= await postFunction(URL,params)
        if(resp.status===1){
            navigate('../pay')
        }
          console.log(responseJson.msg)
      }
    
  };

const  getShippingAddress=async()=>{
    
    let dataToSend= {
           mobile_no:localStorage.getItem('mobile_no'),KEY:API_KEY,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),userId:userID}
   let params = createSendData(dataToSend);
       let URL = 'shopping/api/getshippingaddress';
        const responseJson = await getFunction(URL,params); 
        if(responseJson.status===1){
           
            setShippingDetail(responseJson.data)
            
             setEnterName(responseJson.data.full_name)
            setEnterEmail(responseJson.data.email)
            setEnterNumber(responseJson.data.mobile)
            setEnterCountry(responseJson.data.country)
            setEnterCity(responseJson.data.city)
            setPostalCode(responseJson.data.pincode)
            setAddress(responseJson.data.address)
        }
}

useEffect(()=>{
    getShippingAddress();
   console.log(shippingDetail)
           
},[])

  return (
    <Helmet title="Checkout">
     
      <section className='main_section_wrapper'>
    <div className="welcome_section_wrapper">
     <ProfileHeader pagename="cart" title="Checkout" />
        <Container>
        <div className="checkout-details">
        <br />
    <h6 className="d-flex align-items-center justify-content-between  ml-4  ">
    <div className="checkout-subtotal" >Subtotal:</div> <span className="mr-4 sale-price">&#8377;{cartTotalAmount}</span>
                </h6>
                <h5 className="d-flex  ml-4 align-items-center justify-content-between">
                <div className="checkout-subtotal" > Shipping: </div><span className="mr-4 sale-price">&#8377;{shippingCost}</span>
                  </h5>
                    
<hr className=" voucher-details-hr" />
<h6 className="d-flex align-items-center justify-content-between   ml-4">
    <div className="checkout-subtotal" >Total:</div> <span className="mr-4 sale-price">&#8377;{totalAmount}</span>
                </h6>
    </div>
          <Row className="mt-4 pb-3">
            <Col lg="13" md="12">
              <h6 className="mb-4 shippinga-address">Shipping Address</h6>
              <form className="checkout__form" >
                <div className="form__group">
                <label className="shipping-level">Full Name</label>
                  <input
                    type="text"
                    placeholder="Enter your name"
                    required
                    value={enterName}
                    
                    onChange={(e) => setEnterName(e.target.value)}
                  />
                </div>
<label className="shipping-level">Email</label>
                <div className="form__group">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    required
                    
                    value={enterEmail}
                    onChange={(e) => setEnterEmail(e.target.value)}
                  />
                </div>
                <label className="shipping-level">Phone</label>
                <div className="form__group">
                <div className="row">
                        <div className="col-md-12">
                          <div className="main_contry_wrapper">
                            <div className="country_code">
                              <CountryDropdown  id="country_code" className='country_dropdown' preferredCountries={['in']}  value=""  handleChange={e => setCountryCode(e.target.value)}></CountryDropdown>
                            </div>
                            <div className="count-place">
                              <input className="form-control" type="number" placeholder="Phone Number" required  maxLength={10} autoComplete="Phone number" value={enterNumber} onChange={(e) => setEnterNumber(e.target.value)} />
                            </div>
                          </div>
                        </div>
                      </div>
                  
                  
                </div>
                 <label className="shipping-level">Complete Address</label>
                <div className="form__group">
                  <textarea
                    rows="6"
                    column="50"
                    placeholder="Address"
                    value={address}
                    required
                    onChange={(e) => setAddress(e.target.value)}
                    />
                </div>
                 <label className="shipping-level">Country</label>
                <div className="form__group">
                  <input
                    type="text"
                    placeholder="Country"
                    required
                     value={enterCountry}
                    onChange={(e) => setEnterCountry(e.target.value)}
                  />
                </div>
                 <label className="shipping-level">City</label>
                <div className="form__group">
                  <input
                    type="text"
                    placeholder="City"
                     value={enterCity}
                    required
                    onChange={(e) => setEnterCity(e.target.value)}
                  />
                </div>
                 <label className="shipping-level">Postal Code</label>
                <div className="form__group">
                  <input
                    type="number"
                    placeholder="Postal code"
                    required
                     value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div>
                <div className="text-center ">
                <button type="submit" className="checkout_button pointer_class" onClick={submitHandler}>
                 Proceed for Payment
                </button>
                </div>
              </form>
            </Col>

            
          </Row>
        </Container>
        </div>
      </section>
      <div className="divider-80"></div>
      
    </Helmet>
  );
};

export default Checkout;
