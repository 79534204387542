import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
const FirstLoader = () => {
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("../landing");
    }, 2 * 1000); //30 sec idle time
  }, []);
  return (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse text-center">
        <section>
          <Container>
            <Row className="row">
              <div className="col-md-12">
                <div className="splash_screen">
                  <img
                   src={require("../../assets/images/smiliiz-logo.png")}
                    className="img-responsive"
                  />
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};
export default FirstLoader;
