import React from "react";
import { Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import questionMarkIcon from "../../assets/images/question_mark.svg";

const ProfileHeader = (props) => {
  let navigate = useNavigate();
  const roleType = props.roleType;

  const goPage = (pageUrl) => {
    roleType == "Merchant" ? navigate("/dashboard") : navigate("/welcome");
  };
  return (
    <React.Fragment>
      <div className="container">
        <Row>
          <div className="col-8">
            <div
              className="profile_headline_wrapper pointer_class "
              onClick={() => goPage(props.pagename)}
            >
              <h3>
                <img src="./images/arrow_icon_red.png" alt="goback" />{" "}
                <span className="me-2"></span>
                {props.title}{" "}
              </h3>
            </div>
          </div>

          <div className="col-4">
            <div className="text-right">
              <img
                src={questionMarkIcon}
                alt="question mark"
              />
            </div>
          </div>
        </Row>
      </div>
      <div className="mt-2"></div>
    </React.Fragment>
  );
};
export default ProfileHeader;
