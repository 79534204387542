import React,{useState,useEffect} from 'react'
import { API_BASE_URL,API_KEY, APP_ID, APP_OS, CLIENT_ROLE_ID, IMEI_NO ,BRAND_ID} from '../Constants'
import {  Link,useNavigate} from 'react-router-dom'
//import CountryList from '../common/CountryList'
import CountryDropdown from 'country-dropdown-with-flags-for-react';
import axios, * as others from 'axios';
import { Container, Row, Col } from "reactstrap";
import ContentHeader from '../components/Common/ContentHeader';

import ProfileHeader from '../components/Common/ProfileHeader';
import {createSendData,getFunction,postFunction} from '../components/Common/Actions'
import AppLoader from "../components/Common/Loader";
const BankDetails = () => {
     let navigate = useNavigate();
  
  const [countryCode,setCountryCode]=useState(0);
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [userData,setUserData]=useState({});
  const [status,setStatus]=useState(false);
  const [merchantType,setMerchantType] =useState(false)
  const [accHolderName,setAccHolderName]=useState(userData?userData.acc_holder_name:'')
  const [accountNo,setAccountNo]=useState(userData?userData.account_no:'')
  const [ifscCode,setIfscCode]=useState(userData?userData.ifsc_code:'')
  const [bankName,setBankName]=useState(userData?userData.bank_name:'')
  const [accountType,setAccountType] =useState(userData?userData.account_type:'')
 
let role_id  = localStorage.getItem('role_id') && localStorage.getItem('role_id')?localStorage.getItem('role_id'):CLIENT_ROLE_ID;
let userID = localStorage.getItem('user_id');

const handleUpdateProfile = async() => {
    let response ='';
      
      try{
          if(accHolderName==='' || accHolderName==='undefined'){
              setError('Business Name is requred');
          }else
               if(accountNo==='' || accountNo==='undefined'){
              setError('accountNo  is requred');
          } else
      if(ifscCode===''  || ifscCode==='undefined'){
              setError('pan number is requred');
          }
      else
      if(bankName==='' || bankName==='undefined'){
              setError('Gst number is requred');
          }
      
      else{setLoading(true)
          let dataToSend = {mobile_no:localStorage.getItem('mobile_no'),KEY:API_KEY,role_id:role_id,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),userId:userID,acc_holder_name:accHolderName,bank_account_no:accountNo,ifsc_code:ifscCode,bank_name:bankName,
  save_bank_acccount:1,account_type:accountType};
      
    
        let params = createSendData(dataToSend)
        response = await postFunction('users/api/saveuser',params)
      
        
        if(response===1){
            alert(response.msg)
            setError(response.msg);
        }else
        {
            setError(response.msg);
        }
        setLoading(false)
      }
      }
      catch (error) {
    setError(error)
   } finally {
     setLoading(false);
   }
  }


  const userInfo=async(URL,types)=>{
    let resp ='';
    try{
      
      
    let dataToSend = {mobile_no:localStorage.getItem('mobile_no'),KEY:API_KEY,role_id:role_id,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),userId:userID};
      
    
        let params = createSendData(dataToSend)
        
        resp = await getFunction(URL,params);
        console.log(resp);
        if(types==='userinfo'){
        if(resp.status===1){
            setUserData(resp.userdata)
            setAccHolderName(resp.userdata.acc_holder_name)
            setAccountNo(resp.userdata.account_no)
            setIfscCode(resp.userdata.ifsc_code)
            setBankName(resp.userdata.bank_name)
            setAccountType(resp.userdata.account_type)
            
        }else{
            setUserData(resp.msg)
        }
    }else {
        if(resp.status===1){
            setMerchantType(resp.userdata)
        }else{
            setMerchantType(resp.msg)
        }
        
    }
  }catch (error) {
    setError(error)
   } finally {
     setLoading(false);
   }
    
    }
    
    
    
    
    
    useEffect(() => {
    //userInfo('common/api/merchantTypeslist','merchant_types')
    userInfo('users/api/userinfo','userinfo');
  }, []);
  let pageName='business-profile';
  
  if(role_id==57){
      pageName='profile'
  }
  console.log(merchantType);
  let categoryList =""
  if(merchantType){
      //categoryList =merchantType.map(merType=><option value={merType.id} key={merType.id}>{merType.merchant_type_name}</option>)
  }
const message =error && error?<div className="alert alert-warning alert-dismissible  show">
<a className="close" data-dismiss="alert">&times;</a>
{error}

</div>:''
  return (
    <section className='main_section_wrapper'>
    <div className="profile_section_wrapper">{loading&& loading?<AppLoader />:''}
    <ProfileHeader pagename={pageName} title="Bank Details" />
      
        <div className="mt-4"></div>
    <section className="">
    		<div className="container">
    			<div className="row">
    				
    				<div className="col-md-12"><div >
          
                <form className='profile_form_section' action='/profile'>
                {message}
                  
                 <label>Account Type</label>
                  <div className="form-group md-4">
                    
                    <select className="form-control" name="account_type" value={accountType}  id="account_type" onChange={(e)=>{setAccountType(e.target.value)}}>
                    <option value="">Select Type</option>
                    <option value="saving" >Saving</option>
                    <option value="current">Current</option>
                    </select>
                    </div>
                    <label>Acc. Holder Name</label>
                    <div className="form-group md-4">
                    <input className="form-control" placeholder="Account Holder name" maxLength="100" value={accHolderName} required autoComplete="fullname" onChange={(e)=>{setAccHolderName(e.target.value)}}   />
                    </div>
                   
                  
                  <label>Account No.</label>
                  <div className="form-group md-4">
                    
                    <input className="form-control" placeholder="Account Number" maxLength="20" type="text" value={accountNo} required autoComplete="account no" onChange={(e)=>{setAccountNo(e.target.value)}} />
                    </div>
                  <label>IFSC Code</label>
                  <div className="form-group md-4">
                    
                    <input className="form-control" placeholder="Ifsc Code" type="text" maxLength="11" value={ifscCode} required autoComplete="email" onChange={(e)=>{setIfscCode(e.target.value)}} />
                    </div>
                    <label>Name of the Bank</label>
                  <div className="form-group md-4">
                    
                    <input className="form-control" placeholder="Bamk Name" type="text" value={bankName} required autoComplete="bankname" onChange={(e)=>{setBankName(e.target.value)}} />
                    </div>
                  
                 
                  
                  
                 
                  
                  <div className="">
									<a href="javascript:void(0)" className="sign_Btn" onClick={handleUpdateProfile} > Save Bank Details </a>
								</div>
                  
                </form>
           </div>   
           </div>
           </div>
           </div>
           </section>
            <div className="mt-4"></div>
    	
	    
                
      
    </div>
   
</section>
  );
};

export default BankDetails;
