import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import Loader from "./components/SplashScreen/FirstLoader";
import { BrowserRouter as Router } from "react-router-dom";

// Use createRoot instead of ReactDOM.render
createRoot(document.getElementById("root")).render(
  <React.Suspense fallback={<Loader />}>
    <Router >
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </React.Suspense>
);
