import React from "react";

import "../../../styles/product-card.css";

import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/shopping-cart/cartSlice";

const VoucherCard = (props) => {
  const voucher = props.item;
  const dispatch = useDispatch();
  const id = voucher.id;
  const name = voucher.combo_voucher_name;
  const image01 = voucher.combo_voucher_image;
  const price = voucher.conbo_sale_price;
  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id,
        name,
        image01,
        price,
      })
    );
  };

  return (
    <div className="logo-partners" key={id}>
      <Link
        to="/voucher-details"
        state={{ voucherId: id, type: "voucher" }}
        title={name}
      >
        <img src={image01} alt={name} height="100" width="200" />
      </Link>
    </div>
  );
};

export default VoucherCard;
