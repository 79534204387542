import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Qrscan from "./Qrscan";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { createSendData, getFunction } from "../components/Common/Actions";
import ExternalVouchers from "./ExternalVouchers";
import LocalVouchers from "./LocalVouchers";
import GiftCards from "./GiftCards";
import AppLoader from "../components/Common/Loader";

const Welcome = () => {
  let navigate = useNavigate();

  const goPage = (pageName) => {
    navigate("../" + pageName);
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const userID = localStorage.getItem("user_id");
  const [hotdeals, setHotdeals] = useState(null);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const hotDeals = async (URL) => {
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;

      try {
        let userID = localStorage.getItem("user_id");
        let dataToSend = {
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          userId: userID,
          offer_name: "Hot Deals",
          role_id: role_id,
        };

        let params = createSendData(dataToSend);
        const responseJson = await getFunction(URL, params);

        if (responseJson.status === 1) {
          setHotdeals(responseJson.data);
        } else {
          // localStorage.clear();
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    hotDeals("offers/api/getByName");
    setLoading(false);
  }, []);

  const Scan = () => {
    setStatus(true);
  };
  const hideModel = () => {
    setStatus(false);
  };

  const RedeemHotDeals = (uid, mid) => {
    console.log(uid + " " + mid);
  };
  let hotdealItem = "";

  if (hotdeals) {
    hotdealItem = hotdeals.map((hotdeal) => (
      <div className="container" key={hotdeal.id}>
        <div className="row" key={hotdeal.id}>
          <div className="col-12">
            <div>
              <h5 className="voucher_title_01"> {hotdeal.offer_name} </h5>
              <div onClick={() => RedeemHotDeals(userID, hotdeal.merchant_id)}>
                <img
                  src={hotdeal.offer_image}
                  className="img-responsive pointer_class"
                  alt={hotdeal.offer_name}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  return (
    <React.Fragment>
      <div className="container mt-4">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="voucher_title_01">
                  <h5> upi </h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div
                  className="smileez_card up_card pointer_class"
                  onClick={() => goPage("qrcodescan")}
                >
                  <img src={require("../assets/images/pay-icon.png")} alt="" />
                  <p> scan </p>
                </div>
              </div>
              <div className="col-4">
                <div className="smileez_card up_card">
                  <img
                    src={require("../assets/images/receive-icon.png")}
                    alt=""
                  />
                  <p> receive </p>
                </div>
              </div>
              <div className="col-4">
                <div
                  className="smileez_card up_card pointer_class"
                  data-toggle="modal"
                  data-target="#myModal2"
                  onClick={Scan}
                >
                  <img src={require("../assets/images/scan-pay.png")} alt="" />
                  <p> pay </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="mt-4"></div>
      <section className="voucher-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="voucher_title">
                <h5> Buy Voucher </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="logo-list row">
            <div className="col-md-12">
              <ExternalVouchers name="brand vouchers" />
            </div>
          </div>
        </div>
      </section>
      <div className="mt-4"></div>
      <section className="localvoucher-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="voucher_title">
                <h5> Gift Cards </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="logo-list  row">
            <div className="col-md-12">
              <GiftCards name="Gift Cards" />
            </div>
          </div>
        </div>
      </section>
      <div className="mt-4"></div>
      <section className="">{hotdealItem}</section>
      <div className="mt-4"></div>
      <div className="mb-4 row px-0 hotdeals-voucher-card-fix-row">
        {error}
        {loading && loading ? <AppLoader /> : ""}
        <div className="col-4 p-0 ">
          <div
            className="smileez_card pointer_class"
            onClick={() => goPage("receive-smileez")}
          >
            <img src={require("../assets/images/receive-smile.png")} alt="" />
            <p> Recieve smileez </p>
          </div>
        </div>
        <div className="col-4 p-0">
          <div
            className="smileez_card pointer_class"
            onClick={() => goPage("send-smileez")}
          >
            <img src={require("../assets/images/send-smile.png")} alt="" />
            <p> send smileez </p>
          </div>
        </div>
        <div className="col-4 p-0">
          <div
            className="smileez_card pointer_class"
            onClick={() => goPage("exchange-smileez")}
          >
            <img src={require("../assets/images/exchange-smile.png")} alt="" />
            <p> exchange smileez </p>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="smileez_card">
            <img src={require("../assets/images/earn-smile.png")} alt="" />
            <p> earn smileez </p>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="smileez_card">
            <img src={require("../assets/images/discount-smile.png")} alt="" />
            <p> discount/scan QR </p>
          </div>
        </div>
        <div className="col-4 p-0">
          <div className="smileez_card">
            <img src={require("../assets/images/invoice-smile.png")} alt="" />
            <p> smileez invoice </p>
          </div>
        </div>
      </div>
      <div className="mt-4"></div>
      <section className="localvoucher-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5> Buy From Local </h5>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="logo-list  row">
            <div className="col-md-12">
              <LocalVouchers name="local vouchers" />{" "}
            </div>
          </div>
        </div>
      </section>
      <div className="mt-4"></div>
      <section className="">
        <div className="container">
          <div className="card_wrapper">
            <div className="row">
              <div className="col-12">
                <h5> play with smileez </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <div className="play_section_title">
                  <img
                    src={require("../assets/images/spin-win.png")}
                    alt="spin"
                  />
                  <p> spin & win </p>
                </div>
              </div>
              <div className="col-4">
                <div className="play_section_title">
                  <img
                    src={require("../assets/images/build-smilton.png")}
                    alt="build"
                  />
                  <p> build smiletown </p>
                </div>
              </div>
              <div className="col-4">
                <div className="play_section_title">
                  <img
                    src={require("../assets/images/get-lucky.png")}
                    alt="lucky"
                  />
                  <p> get lucky </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-4"></div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="invest_card_wrapper">
                <img
                  src={require("../assets/images/invest_img.png")}
                  className="img-responsive"
                  alt="invest"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Qrscan
        modelId="myModal2"
        status={status}
        dismiss={hideModel}
        userId={userID}
      />
      <video id="preview"></video>
    </React.Fragment>
  );
};

export default Welcome;
