import {
  API_BASE_URL,
  SMILEEZREWARD_VOUCHERS_URL,
  SCAN_QR_CODE_VOUCHERS_URL,
  STORE_USER_ACCEPTED_OFFER,
  GET_OFFER_CATEGORY,
  ADD_MERCHANT_OFFER,
  UPDATE_PAYMENT_DATA,
  GET_USER_PAYMENT_STATUS,
} from "../../Constants";
import axios from "axios";

export async function getFunction(Url, params) {
  let response = "";
  if (Url) {
    try {
      response = await fetch(API_BASE_URL + Url + "?" + params);
      const responseJson = await response.json();
      response = responseJson;
    } catch (error) {
      response = error;
    } finally {
    }
  }
  return response;
}

export async function postFunction(Url, params) {
  let resp = "";
  await fetch(API_BASE_URL + Url, {
    method: "POST",
    body: params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((responseJson) => {
      resp = responseJson;
    })
    .catch((error) => {
      resp = error;
    });
  return resp;
}

export async function getVoucherSmileezReward(token) {
  console.log("🚀 ~ getVoucherSmileezReward ~ token:", token)
  let resp = "";

  await axios
    .get(SMILEEZREWARD_VOUCHERS_URL, {
      headers: {
        Token: token,
      },
    })
    .then((response) => {
      console.log("🚀 ~ .then ~ response:", response)
      resp = response;
    })
    .catch((error) => {
      // console.log("error in action", error);
      const errorResponse = error.response.status ? "No offer found" : error;
      resp = errorResponse;
    });
  return resp;
}

export async function getOfferCategory() {
  let resp = "";

  await axios
    .get(GET_OFFER_CATEGORY)
    .then((response) => {
      resp = response;
    })
    .catch((error) => {
      console.log("error in action", error);
    });
  return resp;
}

export async function addMerchantOffer(data) {
  let resp = "";

  var requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
  };

  await fetch(ADD_MERCHANT_OFFER, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      resp = result;
    })
    .catch((error) => {
      console.log("error in action:- ", error);
    });
  return resp;
}

export async function scanQrcodeData(result) {
  let resp = "";
  var raw = {
    userid: localStorage.getItem("user_id"),
    qrcode: result,
  };

  var requestOptions = {
    method: "POST",
    body: JSON.stringify(raw),
  };

  await fetch(SCAN_QR_CODE_VOUCHERS_URL, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      resp = result;
    })
    .catch((error) => {
      console.log("error in action:- ", error);
    });
  return resp;
}

export async function StoreUserAcceptedOffer(offerId, token, amount) {
  let resp = "";
  var raw = {
    userid: localStorage.getItem("user_id"),
    merchant_id: "121",
    offer_id: offerId || "",
    amount: amount,
  };

  var requestOptions = {
    method: "PUT",
    body: JSON.stringify(raw),
    headers: {
      token: token,
    },
  };

  await fetch(UPDATE_PAYMENT_DATA, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      resp = result;
    })
    .catch((error) => {
      console.log("error in action:- ", error);
    });
  return resp;
}

export async function GetUserPaymentStatus(transaction_id) {
  let resp = "";
  var raw = {
    transaction_id: transaction_id,
  };

  var requestOptions = {
    method: "POST",
    body: JSON.stringify(raw),
  };

  await fetch(GET_USER_PAYMENT_STATUS, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      resp = result;
    })
    .catch((error) => {
      console.log("error in action:- ", error);
    });
  return resp;
}

export async function extPostFunction(Url, params) {
  let resp = "";

  await fetch(Url, {
    method: "POST",
    body: [],
    "Access-Control-Allow-Origin": "*",
  })
    .then((response) => response.json())
    .then((responseJson) => {
      resp = responseJson;
    })
    .catch((error) => {
      console.error(error);
    });

  return resp;
}

export function createSendData(dataToSend) {
  let formBody = [];
  for (let key in dataToSend) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(dataToSend[key]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  return formBody.join("&");
}

export function createFileData(dataToSend) {
  let formBody = new FormData();
  for (let key in dataToSend) {
    formBody.append(key, dataToSend[key]);
  }
  return formBody;
}

export function showhide(status) {
  if (status === true) {
    return false;
  } else {
    return true;
  }
}
export async function postAxiosFunction(Url, formBody) {
  let resp = "";
  await axios({
    url: API_BASE_URL + Url,
    method: "POST",
    data: formBody,
    headers: {
      "content-type": "multipart/form-data; charset=utf-8;",

      Accept: "application/json",
    },
  })
    .then((responseJson) => {
      resp = responseJson;
    })
    .catch((error) => {
      //Hide Loader
      resp = error;
    });
  return resp;
}

export function validateEmail(input) {
  var validRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

export function isNumber(evt) {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}
export function mobileValidate(phone) {
  if (phone.length === 10 && !isNaN(phone)) {
    return true;
  } else {
    return false;
  }
}
