import React,{useState} from "react";
import "../../assets/css/auto_selection.css";
import CountryDropdown from 'country-dropdown-with-flags-for-react';
const SuggestionsList = props => {
  const {
    suggestions,
    inputValue,
    onSelectSuggestion,
    displaySuggestions,
    selectedSuggestion
  } = props;

  if (inputValue && displaySuggestions) {
    if (suggestions.length > 0) {
      return (
        <ul className="suggestions-list">
          {suggestions.map((suggestion, index) => {
            const isSelected = selectedSuggestion === index;
            const classname = `suggestion ${isSelected ? "selected" : ""}`;
            return (
              <li
                key={index}
                className={classname}
                onClick={() => onSelectSuggestion(index)}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    } else {
      return <div>No suggestions available...</div>;
    }
  }
  return <></>;
};


const AutoSelection = (props) => {
const [countryCode,setCountryCode]=useState(0);
  const [inputValue, setInputValue] = React.useState("");
  const [filteredSuggestions, setFilteredSuggestions] = React.useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(0);
  const [displaySuggestions, setDisplaySuggestions] = React.useState(false);
  
  

  const suggestions = props.suggetions;

  const onChange = event => {
    const value = event.target.value;
    setInputValue(value);
    props.mydata(value)
    const filteredSuggestions =suggestions && suggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };

  const onSelectSuggestion = index => {
    setSelectedSuggestion(index);
    setInputValue(filteredSuggestions[index]);
    setFilteredSuggestions([]);
    props.mydata(filteredSuggestions[index]);
    setDisplaySuggestions(false);
  };


  return (
    
      <div className="row">
                        <div className="col-md-12">
                          <div className="main_contry_wrapper1">
                            <div className="country_code">
                              <CountryDropdown  id="country_code" className='country_dropdown' preferredCountries={['in']}  value="" {...countryCode} handleChange={e => setCountryCode(e.target.value)}></CountryDropdown>
                            </div>
                            <div className="count-place">
                              <input
        className=" form-control" 
        placeholder={props.placeholder}
        type={props.type}
        onChange={onChange}
        value={inputValue}
        maxLength={props.length}
      />
      <SuggestionsList
        inputValue={inputValue}
        selectedSuggestion={selectedSuggestion}
        onSelectSuggestion={onSelectSuggestion}
        displaySuggestions={displaySuggestions}
        suggestions={filteredSuggestions}
      />
                            </div>
                          </div>
                        </div>
                      </div>
      
   
  )
}
export default AutoSelection



