import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CountryDropdown from "country-dropdown-with-flags-for-react";

import { cartActions } from "../store/shopping-cart/cartSlice";
import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import { createSendData, getFunction } from "../components/Common/Actions";
import ProfileHeader from "../components/Common/ProfileHeader";

const PaymentStatus = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  if (!userID) {
    navigate("../login");
  }
  const location = useLocation();
  const orderpay = location.state ? location.state.orderpay : "";

  let merchantPayId = localStorage.getItem("merchantPayId");
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const cartItems = useSelector((state) => state.cart.cartItems);
  const dispatch = useDispatch();
  const deleteItem = (id) => {
    dispatch(cartActions.deleteItem(id));
  };

  cartItems.map((cartProduct) => {
    // alert(cartProduct.id);
    deleteItem(cartProduct.id);
  });

  const [values, setValues] = useState("");
  const mobile = useFormInput("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState("Pending");
  const [transactionView, setTransactionView] = useState("");
  const [txnStatus, setTxnStatus] = useState(0);

  const checkTxnStatus = async (txnId) => {
    try {
      let dataToSend = {
        userId: userID,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        merchantId: merchantPayId,
        txnId: txnId,
        orderpay: orderpay,
      };

      let Url = "transactions/api/checkTxnStatus";
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);

      console.log(responseJson);
      if (responseJson.status === true) {
        let response = responseJson.data;

        // console.log('payment status '+response.payment_status);
        setTransactionStatus(response.payment_status);
        if (
          response.payment_status === "COMPLETED" ||
          response.payment_status === "FAILED"
        ) {
          localStorage.setItem("payment_status", response.payment_status);
          localStorage.setItem("payment_txnid", response.payment_txnid);
          localStorage.setItem("payment_amount", response.payment_amount);
          localStorage.setItem(
            "payment_date",
            responseJson.response.payment_date
          );
          window.location.reload();
          setLoading(false);
        } else {
          setTxnStatus(0);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      //setLoading(false);
    }
  };

  const findUser = async (merchantPayId) => {
    console.log(merchantPayId);
    if (merchantPayId) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          merchantId: merchantPayId,
        };

        let Url = "users/api/byuserId";
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);

        if (responseJson.status === 1) {
          setReceivers(responseJson.data);
        } else {
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (merchantPayId) {
      findUser(merchantPayId);
    }
  }, [merchantPayId]);

  setTimeout(() => {
    let transactionId = localStorage.getItem("payment_txnid");
    console.log("Txn ID  " + transactionId + " status " + transactionStatus);
    if (transactionId && transactionStatus === "Pending") {
      checkTxnStatus(transactionId);
    }
  }, 5000);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <>
      <ProfileHeader pagename="pay" title="Payment Status" />
      <section className="main_section_wrapper">
        <div className="welcome_section_wrapper">
          <Container>
            <div className="container-fluid form">
              <form className="login_from_wrapper">
                {message}
                <Row className="text-center center">
                  <div>
                    {localStorage.getItem("payment_status") &&
                    localStorage.getItem("payment_status") == "COMPLETED" ? (
                      <img
                        src={require("../assets/images/right-tick.png")}
                        alt={localStorage.getItem("payment_status")}
                      />
                    ) : (
                      <img
                        src={require("../assets/images/failed-tick.png")}
                        alt={localStorage.getItem("payment_status")}
                      />
                    )}
                  </div>
                </Row>
                <div className="text-center  mt-2">
                  Paid to Smileez User
                  <br />{" "}
                  {receivers
                    ? receivers.display_name + "(" + receivers.mobileno + ")"
                    : ""}
                </div>
                <div className="text-center  mt-2">
                  &#8377; {localStorage.getItem("payment_amount")}
                </div>
                <div className="text-center mt-2">
                  {localStorage.getItem("payment_status")
                    ? localStorage.getItem("payment_status")
                    : ""}
                </div>
                <div className="text-center  mt-2">
                  <div>{localStorage.getItem("payment_date")}</div>
                  <div>
                    {" "}
                    UPI Transaction ID: {localStorage.getItem("payment_txnid")}
                  </div>
                </div>
                <div className="text-center mt-4 row">
                  <div className="span6 col-lg-6 mb-3">
                    <Link
                      to="/payment-view"
                      state={{
                        transactionId: localStorage.getItem("payment_txnid"),
                        orderpay: orderpay,
                      }}
                      className="btn btn-success pointer_class"
                    >
                      View Details
                    </Link>
                  </div>
                  <div className="span6 col-lg-6">
                    {" "}
                    <a className="btn btn-primary">Share</a>
                  </div>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}; //localStorage.getItem('payment_txnid')

export default PaymentStatus;
