import React, { useRef, useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import { Container, Row, Col } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  API_BASE_URL,
  API_KEY,
  APP_ID,
  APP_OS,
  MERCHANT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";

import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import ContentHeader from "../components/Common/ContentHeader";
import { GeoLocation } from "../components/Common/GeoLocation";
import AppLoader from "../components/Common/Loader";
import {
  createSendData,
  getFunction,
  postFunction,
  mobileValidate,
} from "../components/Common/Actions";

const MerchantLogin = () => {
  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  let latlong = GeoLocation();
  const mobileRef = useRef();

  //const password = useFormInput('')
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    if (mobileValidate(username) === false) {
      setError("Please enter your valid mobile");
      mobileRef.current.focus();
      setLoading(false);
    } else {
      setLoading(true);
      let dataToSend = {
        mobile_no: username,
        KEY: API_KEY,
        role_id: MERCHANT_ROLE_ID,
        lat: latlong.latitude,
        long: latlong.longitude,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
      };

      let Url = "users/api/login";
      let params = createSendData(dataToSend);
      const responseJson = await postFunction(Url, params);
      setLoading(false);

      // If server response message same as Data Matched
      if (responseJson.status == "1") {
        localStorage.setItem("mobile_no", username);
        localStorage.setItem("role_id", MERCHANT_ROLE_ID);
        setError(responseJson.msg);
        navigate("../otp");
      } else {
        setError(responseJson.msg);
        console.log(responseJson.msg);
        setLoading(false);
      }
    }
  };
  <GeoLocation name="latitude" />;

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <div className="welcome_section_wrapper">
      {loading && loading ? <AppLoader /> : ""}
      <Container>
        <ContentHeader pagename="landing" />
        <Row>
          <div className="col-12">
            <div className="form">
              {message}
              <form className="login_from_wrapper">
                <label>Mobile Number</label>

                <div className="form-group">
                  <input
                    className="form-control"
                    ref={mobileRef}
                    placeholder="Enter Your Number"
                    maxLength={10}
                    autoComplete="off"
                    {...username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="footer_login">
                  <a
                    href="javascript:void(0)"
                    className="sign_Btn"
                    onClick={handleLogin}
                  >
                    {" "}
                    Login{" "}
                  </a>
                </div>
                <Row>
                  <Col className="text-center"></Col>
                </Row>
              </form>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default MerchantLogin;
