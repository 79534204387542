import React,{useState,useEffect} from 'react'
import {useNavigate } from "react-router-dom";
import {createSendData,getFunction,extPostFunction} from '../components/Common/Actions'
import { API_KEY, APP_ID, APP_OS, CLIENT_ROLE_ID, IMEI_NO,BRAND_ID} from '../Constants';
import { Container, Row } from "reactstrap";
import AppLoader from "../components/Common/Loader";
const RewardEarned=(props)=>{
     let navigate = useNavigate()
     const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [myreward,setMyReward]=useState(null)
  const userID =localStorage.getItem('user_id')
  let role_id  = localStorage.getItem('role_id') && localStorage.getItem('role_id')?localStorage.getItem('role_id'):CLIENT_ROLE_ID;
  
     const getEarnRewards=async (URL)=>{
         
         try{
             setLoading(true)
            
    let dataToSend = {mobile_no:localStorage.getItem('mobile_no'),KEY:API_KEY,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),userId:userID,role_id:role_id};
      
       let params = createSendData(dataToSend)
               const response=     await getFunction(URL,params)
              
                   if(response.status===1){
                       
                       setMyReward(response.data)
                       
                   }
            else{
                setError(response.msg)
                
            }
             setLoading(false)
    
         }catch (error) {
    setError(error)
    setLoading(false)
   } finally {
     setLoading(false);
   }
     }
     
     useEffect(() => {
    getEarnRewards('gratification/api/equivalentCash')
    
  }, []);
  
  let list=''
  
  if(myreward){
     
      list = myreward.map((lt,index)=><tr key={lt.id} >
      
      <td scope="row">{lt.amount}</td>
      <td scope="row">{lt.rewards_amount?lt.rewards_amount:1}</td>
      <td scope="row">{lt.amount*(lt.rewards_amount?lt.rewards_amount:1)}</td>
    </tr> )
  }
  
    return (<section className='main_section_wrapper'>
    <div className="welcome_section_wrapper">
    <Container><Row><h3>Equivalent Cash</h3>
    <table className="table table-success">
  <thead>
    <tr>
      
      
      <th scope="col">Smileez</th>
      <th scope="col">Coversion Rate</th>
      <th scope="col">Amount</th>
    </tr>
    {list}
  </thead>
  <tbody>
    
    
  </tbody>
  </table></Row></Container></div></section>
             )
     }
     export default RewardEarned