import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
const Model = ({ modelId, closeModel }) => {
  return (
    <div id={modelId} className="modal fade" role="dialog">
      <div className="modal-dialog modal-sm">
        <div className="modal-content justify-content-center">
          <Container className="modelheader">
            <div className="justify-content-center ">
              <img
                src="./images/model-smileez-logo.png"
                alt="smileez logo"
                className=" "
                height="77"
                width="186"
              />
            </div>

            <div className="heading-text mt-4">
              <strog>HOLD ON SIT TIGHT</strog>
            </div>
          </Container>
          <div className="modal-body">
            <p>
              We are confirming your details
              <br />
              once done we will notify you
            </p>
          </div>
          <div className="modelFooter">
            <button className="notify-btn" onClick={() => closeModel(false)}>
              <img
                src="./images/notification.png"
                alt="Notify me"
                height="20"
                width="20"
              />
              Notify me
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Model;
