import React, { useState } from "react";
import { CLIENT_ROLE_ID } from "../../Constants";
//import "../../styles/footer.css";
import cart from "../../assets/images/cart.png";
import settings from "../../assets/images/settings.png";
import homei from "../../assets/images/home.png";
import searchi from "../../assets/images/search.png";
import notification from "../../assets/images/notification.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  let navigate = useNavigate();
  let role_id = localStorage.getItem("role_id");
  const [typeActive, setTypeActive] = useState({
    welcome: "active",
    home: "",
    cart: "",
    notification: "",
    search: "",
  });

  const chng = (type) => {
    let dataSet = {};
    Object.keys(typeActive).map((item) => {
      if (item === type) {
        dataSet[type] = "active";
      } else {
        dataSet[item] = "";
      }
    });
    setTypeActive(dataSet);
    navigate("../" + type);
  };

  const goPage = (pageUrl) => {
    navigate("../" + pageUrl);
  };

  const Logout = () => {
    localStorage.setItem("currentUser", false);
    localStorage.clear();
    window.location.href = "/user/landing";
    // navigate("../landing");
  };

  return (
    <React.Fragment>
      {/* <div className="mt-80"></div> */}
      <footer className="marchant_footer_bg footerFix">
        {/* <div className="mt-80"></div> */}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="navbar">
                  {role_id && role_id == CLIENT_ROLE_ID ? (
                    <div className="nav footer_card_wrapper">
                      <ul>
                        <li className={typeActive.cart ? "active" : ""}>
                          <a
                            onClick={() => chng("cart")}
                            style={{ cursor: "pointer" }}
                          >
                            {localStorage.getItem("cartitems")}
                            <div className="icon">
                              <img src={cart} alt="Cart" />
                            </div>
                          </a>
                        </li>
                        <li className={typeActive.home ? "active" : ""}>
                          <a
                            onClick={() => chng("home")}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="icon">
                              <img src={settings} alt="Settings" />
                            </div>
                          </a>
                        </li>
                        <li className={typeActive.welcome ? "active" : ""}>
                          <a
                            onClick={() => chng("welcome")}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="icon">
                              <img src={homei} alt="Home" />
                            </div>
                          </a>
                        </li>
                        <li className={typeActive.search ? "active" : ""}>
                          <a
                            onClick={() => chng("search")}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="icon">
                              <img src={searchi} alt="Search" />
                            </div>
                          </a>
                        </li>
                        {/* <li className={typeActive.notification ? "active" : ""}>
                          <a onClick={() => chng("notification")}>
                            {localStorage.getItem("notification")}
                            <div className="icon">
                              <img src={notification} alt="Notification" />
                            </div>
                          </a>
                        </li> */}
                        <li className={typeActive.notification ? "active" : ""}>
                          <div className="icon">
                            <div className="toggle_bar">
                              <button
                                type="button"
                                className="toogle_Btn"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal"
                              >
                                <img
                                  src={require("../../assets/images/bar.png")}
                                  alt="bar"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="drawer_menus">
                            <div className="drawer_navigation_menu">
                              <div
                                className="modal fade"
                                id="myModal"
                                aria-hidden="true"
                                tabIndex="-1"
                              >
                                <div className="modal-dialog modal-sm">
                                  <div className="modal-content">
                                    <div className="modal-header reward_header">
                                      <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                      >
                                        <img
                                          src={require("../../assets/images/left_arrow.png")}
                                          alt="Menu"
                                        />{" "}
                                        Menu
                                      </button>
                                    </div>
                                    <div
                                      className="modal-body reward_menu "
                                      data-dismiss="modal"
                                    >
                                      <div
                                        onClick={() => goPage("reward-earned")}
                                        className="pointer_class"
                                      >
                                        <span>
                                          <img
                                            src={require("../../assets/images/Reward-Earned-Icon.png")}
                                            alt="Reward Earned"
                                            className="img-fluid"
                                          />
                                        </span>
                                        <span> Reward Earned </span>
                                      </div>
                                    </div>

                                    <div
                                      className="modal-body reward_menu"
                                      data-dismiss="modal"
                                    >
                                      <div
                                        onClick={() =>
                                          goPage("equivalent-cash")
                                        }
                                        className="pointer_class"
                                      >
                                        <span>
                                          {" "}
                                          <img
                                            src={require("../../assets/images/equivalant-cash-icon.png")}
                                            alt="Equivalent Cash"
                                            className="img-fluid"
                                          />
                                        </span>
                                        <span> Equivalent Cash </span>
                                      </div>
                                    </div>
                                    <div
                                      className="modal-body reward_menu"
                                      data-dismiss="modal"
                                    >
                                      <div
                                        onClick={() => goPage("profile")}
                                        className="pointer_class"
                                      >
                                        <span>
                                          {" "}
                                          <img
                                            src={require("../../assets/images/user.png")}
                                            alt="View Profile"
                                            className="img-fluid"
                                          />
                                        </span>
                                        <span>View Profile </span>
                                      </div>
                                    </div>
                                    <div
                                      className="modal-body reward_menu"
                                      data-dismiss="modal"
                                    >
                                      <div
                                        onClick={() =>
                                          goPage("payment-history")
                                        }
                                        className="pointer_class"
                                      >
                                        <span>
                                          {" "}
                                          <i
                                            className="fa fa-exchange"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <span>Transaction History </span>
                                      </div>
                                    </div>
                                    <div
                                      className="modal-body reward_menu"
                                      data-dismiss="modal"
                                    >
                                      <div
                                        onClick={() => goPage("order-history")}
                                        className="pointer_class"
                                      >
                                        <span>
                                          {" "}
                                          <i
                                            className="fa fa-exchange"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                        <span>Orders History </span>
                                      </div>
                                    </div>

                                    <div
                                      className="modal-body reward_menu"
                                      data-dismiss="modal"
                                    >
                                      <div
                                        onClick={Logout}
                                        className="pointer_class"
                                      >
                                        <span>
                                          {" "}
                                          <img
                                            src={require("../../assets/images/login-icon.png")}
                                            alt="Logout"
                                            className="img-fluid"
                                          />
                                        </span>
                                        <span> Logout </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <div className="nav marchant_footer_section">
                      <div>
                        <ul>
                          <li>
                            <a href="/user/billings">
                              <div className="icon mt-icon">
                                <img
                                  className="imageFooter"
                                  src={require("../../assets/images/billing-icon.png")}
                                  alt="billing"
                                />
                              </div>
                              <div className="mt_text">
                                <p> Billings </p>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/user/invoices">
                              <div className="icon mt-icon">
                                <img
                                  className="imageFooter"
                                  src={require("../../assets/images/invice-icon.png")}
                                  alt="invoice"
                                />
                              </div>
                              <div className="mt_text">
                                <p> Invoices </p>
                              </div>
                            </a>
                          </li>
                          <li className="mt-active">
                            <a href="/user/dashboard">
                              <div className="icon mt-icon">
                                <img
                                  src={require("../../assets/images/mt-icon.png")}
                                  alt="home"
                                />
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="/user/settlements">
                              <div className="icon mt-icon">
                                <img
                                  className="imageFooter"
                                  src={require("../../assets/images/satlement-icon.png")}
                                  alt="satlement"
                                />
                              </div>
                              <div className="mt_text">
                                <p> Settlements </p>
                              </div>
                            </a>
                          </li>
                          <li className="text-right">
                            <div className="toggle_bar">
                              <button
                                type="button"
                                className="toogle_Btn"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal"
                              >
                                <img
                                  src={require("../../assets/images/bar.png")}
                                  alt="bar"
                                  className="icon mt-icon imageFooter"
                                />
                                <div className="mt_text">
                                  <p> Menu </p>
                                </div>
                              </button>
                            </div>
                            <div className="drawer_menus">
                              <div className="drawer_navigation_menu">
                                <div
                                  className="modal fade"
                                  id="myModal"
                                  aria-hidden="true"
                                  tabIndex="-1"
                                >
                                  <div className="modal-dialog modal-sm">
                                    <div className="modal-content">
                                      <div className="modal-header reward_header">
                                        <button
                                          type="button"
                                          className="close"
                                          data-dismiss="modal"
                                        >
                                          <img
                                            src={require("../../assets/images/left_arrow.png")}
                                            alt="Menu"
                                          />{" "}
                                          Menu
                                        </button>
                                      </div>
                                      <div
                                        className="modal-body reward_menu"
                                        data-dismiss="modal"
                                      >
                                        <div
                                          onClick={() =>
                                            goPage("reward-earned")
                                          }
                                          className="pointer_class"
                                        >
                                          <span>
                                            <img
                                              src={require("../../assets/images/Reward-Earned-Icon.png")}
                                              alt="Reward Earned"
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span> Reward Earned </span>
                                        </div>
                                      </div>
                                      <div
                                        className="modal-body reward_menu"
                                        data-dismiss="modal"
                                      >
                                        <div
                                          onClick={() =>
                                            goPage("equivalent-cash")
                                          }
                                          className="pointer_class"
                                        >
                                          <span>
                                            <img
                                              src={require("../../assets/images/equivalant-cash-icon.png")}
                                              alt="Equivalent Cash"
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span> Equivalent Cash </span>
                                        </div>
                                      </div>
                                      <div
                                        className="modal-body reward_menu"
                                        data-dismiss="modal"
                                      >
                                        <div
                                          onClick={() => goPage("profile")}
                                          className="pointer_class"
                                        >
                                          <span>
                                            <img
                                              src={require("../../assets/images/user.png")}
                                              alt="View Profile"
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span>View Profile</span>
                                        </div>
                                      </div>
                                      <div
                                        className="modal-body reward_menu"
                                        data-dismiss="modal"
                                      >
                                        <div
                                          onClick={() =>
                                            goPage("payment-history")
                                          }
                                          className="pointer_class"
                                        >
                                          <span>
                                            {" "}
                                            <i
                                              className="fa fa-exchange"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span>Transaction History </span>
                                        </div>
                                      </div>
                                      <div
                                        className="modal-body reward_menu"
                                        data-dismiss="modal"
                                      >
                                        <div
                                          onClick={() =>
                                            goPage("order-history")
                                          }
                                          className="pointer_class"
                                        >
                                          <span>
                                            {" "}
                                            <i
                                              className="fa fa-exchange"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span>Orders History </span>
                                        </div>
                                      </div>
                                      <div
                                        className="modal-body reward_menu"
                                        data-dismiss="modal"
                                      >
                                        <div
                                          onClick={Logout}
                                          className="pointer_class"
                                        >
                                          <span>
                                            {" "}
                                            <img
                                              src={require("../../assets/images/login-icon.png")}
                                              alt="Logout"
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span> Logout </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
