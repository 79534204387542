import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";

import CountryDropdown from "country-dropdown-with-flags-for-react";

import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import { dateFormat } from "../components/Common/DateFormat";
import { createSendData, getFunction } from "../components/Common/Actions";
import ProfileHeader from "../components/Common/ProfileHeader";

const Notification = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState(null);
  const [list, setList] = useState(null);
  const [search, setSearch] = useState("");

  const getNotifications = async (orderId, searchText) => {
    if (orderId) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          search: searchText,
        };

        let Url = "notifications/api/list";
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);

        if (responseJson.status === 1) {
          setList(responseJson.data);
        } else {
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    getNotifications(userID, "");
  }, []);

  useEffect(() => {
    getNotifications(userID, search);
  }, [search]);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  let requestData = "";
  if (list) {
    localStorage.setItem("notification", list.length);
    requestData = list.map((req) => (
      <tr key={req.id}>
        <td>
          <div className="checkout-details">
            <div className="row">
              <div className="col-lg-6 mt-4">
                <span className="orderhistory_text"> {req.notification}</span>
              </div>
              <div className="col-lg-6 text-right mt-4 padding-right">
                <span className="orderh_date">
                  {" "}
                  Date:{dateFormat(req.created_on)}
                </span>
              </div>

              <div className="col-lg-12 mt-4 ml-3 orderh_date">
                {req.message}{" "}
              </div>
            </div>
          </div>
        </td>
      </tr>
    ));

    //requestbuttons =request.map((req)=> (req.created_by!==req.request_user_id)? <div ><button name="approved" className="btn btn-primary">Approved </button> <button name="rejected" className="btn btn-warning">Rejected </button></div>:'')
  }

  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        <ProfileHeader pagename="welcome" title="Notications" roleType="Merchant" />
        <Container>
          <div className="container-fluid form">
            <form className="login_from_wrapper">
              <div className="row text-cemter">
                <input
                  type="text"
                  name="serach"
                  placeholder="Serch Transactions"
                  maxleangth="50"
                  className="form-control"
                  {...search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              <table className="table table-striped table-hover table-resposive">
                <thead>
                  <tr>
                    <td className="center" colSpan="2"></td>
                  </tr>
                </thead>

                {list && list.length > 0 ? (
                  requestData
                ) : (
                  <tr>
                    <td>There is no notification found!</td>
                  </tr>
                )}
              </table>
            </form>
          </div>
        </Container>
      </div>
    </section>
  );
}; //localStorage.getItem('payment_txnid')

export default Notification;
