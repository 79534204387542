import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";

import CountryDropdown from "country-dropdown-with-flags-for-react";
import axios, * as others from "axios";
import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";

import { createSendData, getFunction } from "../components/Common/Actions";

const PaymentHistory = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  if (!userID) {
    navigate("../login");
  }

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receivers, setReceivers] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [paymentHistoryData, setPaymentHistoryData] = useState([]);
  const [search, setSearch] = useState("");

  const payHistory = async (txnId, searchText) => {
    if (txnId) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          searchTxt: searchText,
        };

        let Url = "transactions/api/transactionList";
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);
        console.log(responseJson);
        if (responseJson.status === 1) {
          setPaymentHistory(responseJson.data);
        } else {
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    // payHistory(userID, "");
    axios
      .post("https://mobicash.in/smilezz/GetUsertransactionHistory", {
        user_id: userID,
      })
      .then((response) => {
        console.log("🚀 ~ useEffect ~ response:", response.data.data);
        setPaymentHistoryData(response.data.data);
      })
      .catch((error) => {
        console.log("🚀 ~ useEffect ~ error:", error);
      });
  }, []);

  // useEffect(() => {
  //   if (search) {
  //     payHistory(userID, search);
  //   }
  // }, [search]);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  let requestData = "";
  if (paymentHistory) {
    requestData = paymentHistory.map((req) => (
      <tr key={req.id}>
        <td>
          <Link
            to="/payment-view"
            state={{ transactionId: req.trans_ref }}
            className="text"
          >
            {req.display_name}
            <br />
            {req.created_on}
          </Link>
        </td>

        <td>{req.amount}</td>
      </tr>
    ));
  }
  return (
    <section className="main_section_wrapper">
      <div className="">
        <Container>
          <div className="container-fluid form">
            <form className="login_from_wrapper">
              <div className="row text-cemter">
                <input
                  type="text"
                  name="serach"
                  placeholder="Serch Transactions"
                  maxleangth="50"
                  className="form-control"
                  {...search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </form>
          </div>
        </Container>
        <div className="container mt-3">
          <div className="row">
            <div className="col-sm-12">
              <table className="table table-striped table-hover table-resposive">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Transaction Id</th>
                    <th>Payment Status</th>
                    <th>Order Id</th>
                    <th>Transaction Time</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {search
                    ? paymentHistoryData
                        ?.filter((i) => i.transaction_id == search)
                        .map((paymentHistoryData) => (
                          <tr>
                            <td> {paymentHistoryData.index} </td>
                            <td> {paymentHistoryData.transaction_id} </td>
                            <td> {paymentHistoryData.payment_status} </td>
                            <td> {paymentHistoryData.order_id} </td>
                            <td> {paymentHistoryData.transaction_time} </td>
                            <td> {paymentHistoryData.amount} </td>
                          </tr>
                        ))
                    : paymentHistoryData.map((paymentHistoryData) => (
                        <tr>
                          <td> {paymentHistoryData.index} </td>
                          <td> {paymentHistoryData.transaction_id} </td>
                          <td> {paymentHistoryData.payment_status} </td>
                          <td> {paymentHistoryData.order_id} </td>
                          <td> {paymentHistoryData.transaction_time} </td>
                          <td> {paymentHistoryData.amount} </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentHistory;
