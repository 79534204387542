import React, { useEffect } from "react";
import { useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";

import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import ProfileHeader from "../components/Common/ProfileHeader";
import {
  getVoucherSmileezReward,
  scanQrcodeData,
} from "../components/Common/Actions";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsQR from "jsqr";

// upi://pay?pa=GREENFIELDTECH@icici&pn=GreenfieldTechnologysolutionspvtltd&tr=EZV2024032615025716552774&am=200&cu=INR&mc=5411
// upi://pay?pa=voraparthiv2004-1@okhdfcbank&pn=vora%20parthiv&aid=uGICAgMDw1pP3KQ

const str1 =
  "upi://pay?pa=GREENFIELDTECH@icici&pn=GreenfieldTechnologysolutionspvtltd&tr=EZV2024050215552072029656&am=1&cu=INR&mc=5411";
const str2 =
  "upi://pay?pa=GREENFIELDTECH@icici&pn=GreenfieldTechnologysolutionspvtltd&tr=EZV2024050215555172031983&am=1&cu=INR&mc=5411";
const str3 =
  "upi://pay?pa=GREENFIELDTECH@icici&pn=GreenfieldTechnologysolutionspvtltd&tr=EZV2024050317420677787902&am=1&cu=INR&mc=5411";

function QrCodeScan() {
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  const [error, setError] = useState("");

  const handleErrorWebCam = (error) => {
    // scanQrcodeData(str3)
    //   .then(async (res) => {
    //     await getVoucherSmileezReward(res?.data?.token)
    //       .then((response) => {
    //         console.log("🚀 ~ .then ~ response:", response);
    //         navigate("/offerspage", {
    //           state: {
    //             token: res?.data?.token,
    //             offers: response?.data?.data?.offers || null,
    //             message: response?.data?.message || response,
    //             qrCode: str3,
    //           },
    //         });
    //       })
    //       .catch((err) => {
    //         console.log(
    //           "🚀 ~ await getVoucherSmileezReward ~ err:",
    //           err.response.data.message
    //         );
    //       });
    //   })
    //   .catch((err) => {
    //     console.log("🚀 ~ handleErrorWebCam ~ err:", err);
    //   });
  };
  const navigate = useNavigate();

  const [isQrReader, setIsQrReader] = useState(true);

  const handleScanWebCam = (resQr) => {
    if (resQr) {
      setScanResultWebCam(resQr);
      scanQrcodeData(resQr)
        .then(async (res) => {
          if (
            (res?.data?.offer && res?.data?.offer != null) ||
            res?.data?.offer == false
          ) {
            await getVoucherSmileezReward(res?.data?.token)
              .then((response) => {
                toast.success("Done", {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                });
                navigate("/offerspage", {
                  state: {
                    token: res?.data?.token,
                    offers: response?.data?.data?.offers || null,
                    message: response?.data?.message || response,
                    qrCode: resQr,
                  },
                });
              })
              .catch((err) => {
                console.log("getVoucherdata error :- ", err);
              });
          } else {
            toast.error("No offer found", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            navigate("/offerspage", {
              state: {
                scanResultWebCam: "",
                token: "",
                response: "",
                qrCode: resQr,
              },
            });
          }
        })
        .catch((err) => {
          console.log("Qr code error ;-", err);
        });
    } else if (!resQr && isQrReader) {
      toast.error("No offer found", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setIsQrReader(false);
    }
    return;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        scanQRCodeFromImage(imageData);
      };
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  const scanQRCodeFromImage = (imageData) => {
    const code = jsQR(imageData.data, imageData.width, imageData.height);
    if (code) {
      setQRCodeData(code.data);
      scanQrcodeData(code.data)
        .then(async (res) => {
          if (
            (res?.data?.offer && res?.data?.offer != null) ||
            res?.data?.offer == false
          ) {
            await getVoucherSmileezReward(res?.data?.token)
              .then((response) => {
                toast.success("Done", {
                  position: "top-right",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                });
                navigate("/offerspage", {
                  state: {
                    token: res?.data?.token,
                    offers: response?.data?.data?.offers || null,
                    message: response?.data?.message || response,
                    qrCode: code.data,
                  },
                });
              })
              .catch((err) => {
                console.log("getVoucherdata error :- ", err);
              });
          } else {
            toast.error("No offer found", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
            navigate("/offerspage", {
              state: {
                scanResultWebCam: "",
                token: "",
                response: "",
                qrCode: code.data,
              },
            });
          }
        })
        .catch((err) => {
          console.log("Qr code error ;-", err);
        });
      setError("");
    } else {
      setError("Unable to scan QR code.");
    }
  };

  return (
    <>
      <ToastContainer />
      <ProfileHeader pagename="welcome" title="Qr Code Scanner" />
      <Container>
        <div className="mt-4"></div>
        <Card
          style={{
            marginBottom: "80px",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <div container spacing={2}>
            <div item xl={4} lg={4} md={6} sm={12} xs={12}>
              <QrReader
                delay={300}
                style={{ width: "100%" }}
                onError={handleErrorWebCam}
                onScan={handleScanWebCam}
              />
              <div className="uploaded-gallery my-3 d-flex flex-column align-items-start gap-3">
                <label htmlFor="uploadQrCode" className="m-0">
                  Upload QR Code from gallery
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="uploadQrCode"
                  onChange={handleFileUpload}
                />
                {error && <p>{error}</p>}
              </div>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
}
export default QrCodeScan;
