import React, { useEffect, useState } from "react";
// import QrScanner from "qr-scanner";
import { useNavigate } from "react-router-dom";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
//import {FindUser} from '../components/Common/Actions';
//https://codesandbox.io/s/da-best-qr-code-reader-tou856?file=/package.json:224-246
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
const Qrscan = (props) => {
  const [qrScanner, setQrScanner] = useState();
  const [result, setResult] = useState();
  let navigate = useNavigate();
  const [merchant, setMerchant] = useState(0);
  const [error, setError] = useState(null);
  const [merchantMobile, setMerchantMobile] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    //console.log("status "+props.status + ' dismiss '+props.dismiss)

    if (props.status) {
      setQrScanner();
      // new QrScanner(document.getElementById("camera"), handleScan, {
      //   highlightScanRegion: true,
      //   returnDetailedScanResult: true,
      //   highlightCodeOutline: true,
      //   onDecodeError: () => {},
      //   calculateScanRegion: (video) => {
      //     console.log(video);
      //     return {
      //       x: 0,
      //       y: 0,
      //       width: video.width,
      //       height: video.height
      //     };
      //   }
      // })
    }
  }, [props.status]);

  useEffect(() => {
    if (!qrScanner) return;
    qrScanner.setInversionMode("both");
    qrScanner.start();
  }, [qrScanner]);

  useEffect(() => {
    if (!result) return;
    qrScanner.stop();

    // eslint-disable-next-line
  }, [result]);

  const handleScan = (result) => {
    setResult(result.data);
    let explodeRes = result.data.split("-Qrcode-");
    console.log(explodeRes["0"]);
    localStorage.setItem("merchantPayId", explodeRes["0"]);

    findUser(explodeRes["0"], props.userId);

    if (merchant === 1) {
      document.getElementById(props.modelId).click();
      navigate("../Payment");
    } else {
      setResult("There no smileez merchant found");
    }
  };

  const findUser = async (merchantPayId, userID, merchantMobile) => {
    console.log(merchantPayId);
    if (merchantPayId) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          merchantId: merchantPayId,
          merchantMobile: merchantMobile,
        };

        let Url = "users/api/byuserId";
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);

        if (responseJson.status === 1) {
          setMerchant(responseJson.status);
        } else {
          setMerchant(false);
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const hideShow = () => {
    qrScanner.stop();

    navigate("../mob-payment");
  };
  return (
    <div
      id={props.modelId}
      className="modal fade"
      role="dialog"
      onClick={hideShow}
    >
      <div className="modal-dialog modal-sm">
        <div className="">
          {qrScanner !== undefined && <button>Toggle lights</button>}
          <video id="camera" className="mt-4"></video>
          <p style={{ marginTop: "50px" }}>Last result:</p>
          <p>{result}</p>
          <div className="row " id="text">
            <input
              type="text"
              name="mobile"
              id="mobile"
              placeholder="Merchant Mobile"
              maxleangth="12"
              className="form-control"
              onClick={hideShow}
            />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qrscan;
