import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createSendData,
  getFunction,
  extPostFunction,
} from "../components/Common/Actions";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AppLoader from "../components/Common/Loader";
import VoucherCard from "../components/UI/product-card/VoucherCard";

const LocalVouchers = (props) => {
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [extVouchers, setExtVouchers] = useState(null);

  const getextvouchers = async (URL) => {
    try {
      setLoading(true);
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
      };
      let params = createSendData(dataToSend);
      const response = await getFunction(URL, params);
      if (response.status == 1) {
        setExtVouchers(response.data);
      } else {
        setError(response.msg);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getextvouchers("voucher/api/voucherlist");
  }, []);
  let list = "";

  if (extVouchers) {
    list = extVouchers.map((voucher) => (
      <div className="item" key={voucher.id}>
        <VoucherCard item={voucher} />
      </div>
    ));
  }

  return (
    <OwlCarousel
      className="owl-carousel owl-theme"
      loop
      nav
      items={3}
      dots={false}
    >
      {list}
    </OwlCarousel>
  );
};
export default LocalVouchers;
