import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createSendData,
  getFunction,
  extPostFunction,
} from "../components/Common/Actions";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AppLoader from "../components/Common/Loader";

const GiftCards = (props) => {
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [giftCard, setGiftCard] = useState(null);

  const giftlist = async (URL) => {
    try {
      setLoading(true);
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
      };
      let params = createSendData(dataToSend);
      const response = await getFunction(URL, params);
      if (response.status == 1) {
        setGiftCard(response.data);
      } else {
        setError(response.msg);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    giftlist("giftcards/api/glist");
  }, []);
  let list = "";
  let url = "../voucher-details"; //"https://mobicashindia.com/app/checkout?name=";

  if (giftCard) {
    list = giftCard.map((lt, index) => (
      <div className="item" key={index}>
        <div className="logo-partners">
          <Link
            to={url}
            className="pointer_class"
            state={{ voucherId: lt.id, type: "gift" }}
          >
            <img
              src={lt.gc_image}
              alt={lt.gift_card_name}
              className="img-responsive img-fluid"
            />
          </Link>
        </div>
      </div>
    ));
  }

  return (
    <OwlCarousel
      className="owl-carousel owl-theme"
      loop
      nav
      items={3}
      dots={false}
    >
      {list}
    </OwlCarousel>
  );
};
export default GiftCards;
