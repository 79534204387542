import React, { useEffect, useRef, useState } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  BRAND_ID,
  CLIENT_ROLE_ID,
  IMEI_NO,
} from "../Constants";
//import { useNavigate } from "react-router-dom";
import {
  createFileData,
  createSendData,
  getFunction,
  postAxiosFunction,
} from "../components/Common/Actions";
import {
  getCustomDateTime,
  phpDateToJsDate,
} from "../components/Common/DateFormat";
import AppLoader from "../components/Common/Loader";
//import axios, * as others from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import questionMark from "../assets/images/whiteWesMark.png";
import { useNavigate } from "react-router-dom";

const Voucher = (props) => {
  //let navigate = useNavigate();
  let userID = localStorage.getItem("user_id");
  const inputRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [voucherData, setVoucherData] = useState(null);
  const [singleData, setSingleData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  let now = new Date();
  let current = new Date(now.getFullYear(), now.getMonth() + 1, now.getDate());
  //alert(maxdisamt);
  const buttonTitle = editMode ? "Update" : "Create";
  const [createdFor, setCreatedFor] = useState(
    singleData ? singleData.created_for : null
  );
  const [voucherType, setVoucherType] = useState(
    singleData ? singleData.voucher_type : null
  );
  const [voucherName, setVoucherName] = useState(
    singleData ? singleData.combo_voucher_name : null
  );
  const [voucherDesc, setVoucherDesc] = useState(
    singleData ? singleData.combo_voucher_description : null
  );
  const [actualAmount, setActualAmount] = useState(
    singleData ? singleData.combo_voucher_actual_amount : null
  );
  const [discountAmount, setDiscountAmount] = useState(
    singleData ? singleData.voucher_sale_price : null
  );
  const [validFrom, setValidFrom] = useState(
    singleData ? singleData.valid_from : new Date()
  );
  const [validUpto, setValidUpto] = useState(
    singleData ? singleData.combo_voucher_valid_upto : current
  );
  const [zipcode, setZipcode] = useState(
    singleData ? singleData.zipcode : null
  );
  const [voucherImage, setVoucherImage] = useState();
  const [voucherId, setVoucherId] = useState(singleData ? singleData.id : 0);

  let role_id =
    localStorage.getItem("role_id") && localStorage.getItem("role_id")
      ? localStorage.getItem("role_id")
      : CLIENT_ROLE_ID;

  const saveVoucher = async (URL, voucherId) => {
    //console.log(createdFor)
    try {
      if (createdFor == null) {
        setError("Please select voucher created for");
      } else if (voucherType == null) {
        setError("Please select voucher type");
      } else if (voucherName == null) {
        setError("Please enter voucher name");
      } else if (actualAmount == null) {
        setError("Please enter voucher actual amount");
      } else if (discountAmount == null) {
        setError("Please enter voucher discount amount");
      } else if (validFrom == null) {
        setError("Please enter voucher valid from date");
      } else if (validUpto == null) {
        setError("Please enter voucher valid upto date");
      } else {
        let dataToSend = {
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: role_id,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          userId: userID,
          created_for: createdFor,
          voucher_name: voucherName,
          voucher_description: voucherDesc,
          voucher_actual_amount: actualAmount,
          voucherId: voucherId,
          voucher_discount_amount: discountAmount,
          zipcode: zipcode,
          valid_from: getCustomDateTime(validFrom),
          valid_upto: getCustomDateTime(validUpto),
          voucher_type: voucherType,
          voucher_image: voucherImage,
        };

        let params = createFileData(dataToSend);
        let resp = await postAxiosFunction(URL, params);
        if (resp.data.status === 1) {
          setError(resp.data.msg);
          getVouchers("voucher/api/getById", 0);
        } else {
          setError(resp.data.msg);
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getVouchers = async (URL, voucherId) => {
    try {
      //console.log(offerId);
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;

      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
        voucherId: voucherId,
        merchantId: userID,
      };

      let params = createSendData(dataToSend);
      const responseJson = await getFunction(URL, params);

      if (responseJson.status === 1) {
        if (voucherId) {
          setSingleData(responseJson.data);
          setCreatedFor(
            responseJson.data ? responseJson.data.created_for : "0"
          );
          setVoucherType(
            responseJson.data ? responseJson.data.voucher_type : "0"
          );
          setVoucherName(
            responseJson.data ? responseJson.data.combo_voucher_name : null
          );
          setVoucherDesc(
            responseJson.data
              ? responseJson.data.combo_voucher_description
              : null
          );
          setActualAmount(
            responseJson.data
              ? responseJson.data.combo_voucher_actual_amount
              : "0"
          );
          setDiscountAmount(
            responseJson.data ? responseJson.data.voucher_sale_price : "0"
          );

          setValidFrom(phpDateToJsDate(responseJson.data.valid_from));
          setValidUpto(phpDateToJsDate(responseJson.data.valid_upto));
          setZipcode(responseJson.data ? responseJson.data.zipcode : "0");
          //alert(responseJson.data.combo_voucher_image)
          //setVoucherImage(responseJson.data.combo_voucher_image)
          setVoucherId(responseJson.data ? responseJson.data.id : "0");
          setEditMode(true);
        } else {
          setVoucherData(responseJson.data);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVouchers("voucher/api/voucherlist", 0);
  }, []);

  const editVoucher = (voucherId) => {
    if (voucherId) {
      //setVocherId(offerId);
      getVouchers("voucher/api/getById", voucherId);
      inputRef.current.focus();
      window.scrollTo(0, 0);
    } else {
      setError("Reward id is required");
    }
  };

  let rewardsList = "";
  if (voucherData) {
    rewardsList = voucherData.map((item) => (
      <>
        <div className="reward_card" key={item.id}>
          <div className="row align-items-center">
            <div className="col-md-8">
              <label className="control-label">
                Created On &nbsp; {item.created_at} Voucher Code{" "}
                {item.combo_voucher_no}
              </label>
            </div>
            <div className="col-md-4 configrightbt ">
              <button onClick={() => editVoucher(item.id)}>
                <img
                  src={require("../assets/images/editicon.png")}
                  alt="equal"
                />
                Edit
              </button>
            </div>
          </div>
          <hr className="hrwidth" />
          <div className="row mt-4">
            <div className="col">
              <label>Voucher Name</label>
              <div> {item.combo_voucher_name} </div>
              <label>Voucher Desc</label>
              <div>{item.combo_voucher_description} </div>
            </div>
            <div className="col">
              <img
                src={item.combo_voucher_image}
                alt="voucher"
                hieght="200"
                width="100"
              />
            </div>
            <div className="col">
              <label>Voucher Amount</label>
              <div> &#x20B9;{item.combo_voucher_actual_amount} </div>
            </div>
          </div>
        </div>
        <div className="divider-30"></div>
      </>
    ));
  }
  const navigate = useNavigate();
  const goToBack = () => {
    navigate("/dashboard");
  };

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  const [voucherTypeClient, setVoucherTypeClient] = useState("merchant");

  const handleChange = (event) => {
    setVoucherTypeClient(event.target.value);
    console.log(event.target.value);
  };

  return (
    <div className="container" style={{ marginBottom: "100px" }}>
      <div className="text-center">
        {/* <button className="topbuttonRewards">
          {" "}
          Voucher Configuration <br />
          Lorem ipsum dolor sit amet consectetur.
        </button> */}

        <div className="voucher_create_wrapper">
          <div className="row">
            {/* <div className="col-2 col-lg-2">
              <div className="profile_headline_wrapper pointer_class ">
                  <img src={require("../assets/images/white_back_icon.png")} alt="goback" />{" "}
              </div>
            </div>
            <div className="col-8 col-lg-8" >
              <div className="voucherTitle">
                <img
                  className="imagecreate"
                  src="./images/voucher_icon.png"
                  alt="voucher creation"
                />
                <div className="textCreate"> Create Vouchers </div>
              </div>
            </div>
            <div className="col-2 col-lg-2">
              <div className="text-right">
                <img src="./images/question_mark.svg" alt="question mark" />
              </div>
            </div> */}
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <div className="voucher_create_wrapper_box">
                <div
                  className="back_icon"
                  onClick={goToBack}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={require("../assets/images/white_back_icon.png")}
                    alt="white_back_icon"
                    className="mt-2"
                  />
                </div>
                <div className="voucherTitle">
                  <img
                    className="imagecreate img-fluid"
                    src={require("../assets/images/voucher_icon.png")}
                    alt="voucher creation"
                  />
                  <div className="textCreate"> Create Vouchers </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
              <div className="questionMark">
                <img
                  src={questionMark}
                  alt="white_back_icon"
                  className="mt-2"
                />
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="divider-30"></div>
      <div className="row" key="1">
        <div className="col-md-12">
          {message}
          <div className="reward_card">
            {loading && loading ? <AppLoader /> : ""}
            <div className="col-md-12">
              <div className="row align-items-center">
                <label className="control-label lbold">
                  Voucher Created For
                </label>
                <select
                  name="created_for"
                  id="creared_for"
                  value={createdFor}
                  className="form-control"
                  onChange={(e) => {
                    setCreatedFor(e.target.value);
                  }}
                >
                  <option value="0">Select Created For</option>
                  <option value="1">Smileez Clients</option>
                  <option value="2">Merchant Clients</option>
                  <option value="3">Both Clients</option>
                </select>
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label lbold">Voucher Type</label>
                <select
                  name="voucher_type"
                  id="voucher_type"
                  value={voucherType}
                  className="form-control"
                  onChange={(e) => {
                    setVoucherType(e.target.value);
                  }}
                >
                  <option value="0">Voicher Type</option>
                  <option value="2">Single Merchant</option>
                  <option value="3">Combo Voucher</option>
                </select>
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label lbold">Voucher Name</label>
                <input
                  type="text"
                  ref={inputRef}
                  name="voucher_name"
                  maxLength={100}
                  id="voucher_name"
                  placeholder="Voucher Name"
                  className="form-control "
                  value={voucherName}
                  onChange={(e) => {
                    setVoucherName(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label">Voucher Description</label>
                <input
                  type="text"
                  name="voucher_desc"
                  id="voucher_desc"
                  maxLength={250}
                  placeholder="Voucher Description"
                  className="form-control"
                  onChange={(e) => {
                    setVoucherDesc(e.target.value);
                  }}
                  value={voucherDesc}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label"> Voucher Actual Amount</label>
                <input
                  type="text"
                  name="actualAmount"
                  id="actualAmount"
                  placeholder="Voucher Actual Amount"
                  maxLength={10}
                  className="form-control"
                  value={actualAmount}
                  onChange={(e) => {
                    setActualAmount(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label">
                  {" "}
                  Voucher Discount Amount
                </label>
                <input
                  type="text"
                  name="discountAmount"
                  id="discountAmount"
                  placeholder="Voucher Discount Amount"
                  maxLength={10}
                  className="form-control"
                  value={discountAmount}
                  onChange={(e) => {
                    setDiscountAmount(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label"> Valid From</label>
                <DatePicker
                  selected={validFrom}
                  onChange={(date) => setValidFrom(date)}
                  className="form-control"
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label"> Valid Upto</label>
                <DatePicker
                  selected={validUpto}
                  onChange={(date) => setValidUpto(date)}
                  className="form-control"
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label"> Zipcode</label>
                <input
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  placeholder="Zipcode"
                  maxLength={6}
                  className="form-control"
                  value={zipcode}
                  onChange={(e) => {
                    setZipcode(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label"> Voucher Image</label>
                <input
                  type="file"
                  name="voucherima"
                  id="voucherima"
                  placeholder="Voucher Image"
                  className="form-control"
                  onChange={(e) => {
                    setVoucherImage(e.target.files[0]);
                  }}
                />
                {
                  //voucherImage?<img src={voucherImage} height="100" width="100" alt="voucher" />:''
                  //
                }
              </div>
              <input type="hidden" name="voucherId" value={voucherId} />
            </div>
            <div className="text-center  mt-4">
              <button
                className="create-button pointer_class"
                onClick={() =>
                  saveVoucher(
                    "voucher/api/" + buttonTitle.toLowerCase() + "Voucher",
                    voucherId
                  )
                }
              >
                <img
                  src={require("../assets/images/createicon.png")}
                  alt="equal"
                />
                {buttonTitle}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="divider-30">
        <hr />
      </div>
      <h3>Vouchers History</h3>
      <div className="row" key="2">
        <div className="col-md-12">{rewardsList}</div>
      </div>
      <div className="divider-80"></div>

      <div className="col-md-12">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="voucher-details-part d-flex flex-column gap-1">
              <label className="control-label lbold m-0">Voucher name</label>
              <input
                className="voucherName w-100 py-2"
                type="text"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <label className="control-label lbold">Voucher Description</label>
          <textarea
            rows={5}
            cols={10}
            className="voucherName w-100"
            type="text"
            placeholder=""
          />
        </div>

        <div className="row align-items-center mt-4">
          <label className="control-label">Voucher Type</label>
          <div className="col-sm-6 col-lg-6 col-6">
            <div
              className={`voucherTypeSelect p-2 ${
                voucherTypeClient === "merchant"
                  ? "voucherTypeSelectActiveColor"
                  : ""
              }`}
            >
              <input
                type="radio"
                name="actualAmount"
                id="actualAmount"
                className={`clientType`}
                checked={voucherTypeClient === "merchant" ? true : false}
                value="merchant"
                onChange={handleChange}
              />
              <div className="textCreate ms-2">
                {" "}
                Merchant <br />
                Client{" "}
              </div>

              <div
                className={`stars_icon_section`}
                style={{
                  display: voucherTypeClient === "merchant" ? "block" : "none",
                }}
              >
                <img src={require("../assets/images/stars_icon.png")} alt="" />
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6 col-6">
            <div
              className={`voucherTypeSelect p-2 ${
                voucherTypeClient === "smileez"
                  ? "voucherTypeSelectActiveColor"
                  : ""
              }`}
            >
              <input
                type="radio"
                name="actualAmount"
                id="actualAmount"
                className={`clientType ${
                  voucherTypeClient === "smileez"
                    ? "voucherTypeSelectActiveColor"
                    : ""
                }`}
                checked={voucherTypeClient === "smileez" ? true : false}
                value="smileez"
                onChange={handleChange}
              />
              <div className="textCreate ms-2">
                {" "}
                Smileez <br />
                Client{" "}
              </div>

              <div
                className="stars_icon_section"
                style={{
                  display: voucherTypeClient === "smileez" ? "block" : "none",
                }}
              >
                <img src={require("../assets/images/stars_icon.png")} alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <div className="col-sm-6 col-lg-6 col-6">
            <label className="control-label"> Voucher Actual Amount</label>
            <input
              type="text"
              name="actualAmount"
              id="actualAmount"
              placeholder="Voucher Actual Amount"
              maxLength={10}
              className="form-control"
              value={actualAmount}
              onChange={(e) => {
                setActualAmount(e.target.value);
              }}
            />
          </div>

          <div className="col-sm-6 col-lg-6 col-6">
            <label className="control-label"> Voucher Discount Amount</label>
            <input
              type="text"
              name="discountAmount"
              id="discountAmount"
              placeholder="Voucher Discount Amount"
              maxLength={10}
              className="form-control"
              value={discountAmount}
              onChange={(e) => {
                setDiscountAmount(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <label className="control-label"> Valid Upto</label>
          <DatePicker
            selected={validUpto}
            onChange={(date) => setValidUpto(date)}
            className="form-control"
          />
        </div>
      </div>
      <div className="divider-30">
        <hr />
      </div>
    </div>
  );
};
export default Voucher;
