import Logo from "../../assets/images/smiliiz-logo.png";
const SLogo = () => {
  return (
    <div className="landing_logo pointer_class">
      <img src={Logo} className="img-responsive" alt="logo" />
    </div>
  );
};

export default SLogo;
