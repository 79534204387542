import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import ProfileHeader from "../components/Common/ProfileHeader";
import "../assets/css/style.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import { TbDiscount2 } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import {
  GetUserPaymentStatus,
  StoreUserAcceptedOffer,
} from "../components/Common/Actions";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Common/Loader";

function Offerspage() {
  const { state } = useLocation();
  console.log("🚀 ~ Offerspage ~ state:", state);
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [selectedOfferTitle, setSelectedOfferTitle] = useState(null);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    toast.success(state?.message, {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
  }, []);

  const userAcceptedOffer = (offerId, offerTitle) => {
    setSelectedOfferId(offerId);
    setSelectedOfferTitle(offerTitle);
  };

  const updatePaymentData = () => {
    setLoading(true);
    StoreUserAcceptedOffer(selectedOfferId, state.token, amount)
      .then(async (res) => {
        console.log("🚀 ~ .then ~ response of StoreUserAcceptedOffer:", res);
        toast.success(res?.message, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        if (res.data) {
          window.location.href = state.qrCode;
          let timeElapsed = 0;
          const intervalId = setInterval(async function () {
            timeElapsed += 5000;
            if (timeElapsed >= 120000) {
              clearInterval(intervalId);
              console.log("Payment status check timeout");
              setLoading(false);
              window.location.href = "/user/fail-page";
              return;
            }
            await GetUserPaymentStatus(res.data)
              .then((response) => {
                console.log(
                  "🚀 ~ .then ~ response of GetUserPaymentStatus:",
                  response
                );
                if (response.status) {
                  if (
                    response.data.transaction_status === "PENDING" ||
                    response.data.transaction_status === "FAILURE"
                  ) {
                    console.log("Payment Pending or Fail");
                    setLoading(true);
                    localStorage.setItem("orderId", response.data.order_id);
                    localStorage.setItem(
                      "transactionStatus",
                      response.data.transaction_status
                    );
                    localStorage.setItem(
                      "transactionTime",
                      response.data.transaction_time
                    );
                  } else if (response.data.transaction_status === "SUCCESS") {
                    console.log("Payment Success");
                    clearInterval(intervalId);
                    setLoading(false);
                    localStorage.setItem("orderId", response.data.order_id);
                    localStorage.setItem(
                      "transactionStatus",
                      response.data.transaction_status
                    );
                    localStorage.setItem(
                      "transactionTime",
                      response.data.transaction_time
                    );
                    window.location.href = "/user/success-page";
                  }
                }
              })
              .catch((error) => {
                console.log("🚀 ~.catch ~ error:", error);
              });
          }, 5000);
        }
      })
      .catch((err) => {
        console.log("🚀 ~ StoreUserAcceptedOffer ~ err:", err);
      });
  };

  const openLink = (qrCodeLink) => {
    window.location.href = qrCodeLink;
  };
  return (
    <div>
      {loading && <Loader />}
      <ToastContainer />
      <ProfileHeader pagename="qrcodescan" title="Offers" />
      <Container style={{ marginBottom: "80px" }}>
        <div className="mt-4"></div>
        <section className="container offerscontainer">
          <div className="row ">
            <div className="col-8">
              <div className="row">
                <div className="col-12">
                  <div className="offerstitle mt-3">
                    <h6>Barista</h6>
                  </div>
                </div>
                <div className="col-12">
                  <h6>Andhrei west, Mumbai</h6>
                </div>
                <div className="col-12">
                  <h6>+91 9898895836</h6>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="logo">
                {" "}
                <img
                  className="companylogo"
                  src={require("../assets/images/bannerdemo.png")}
                  alt="company logo"
                />
              </div>
              <div className="companyOutletswName ">
                <h6>Cafe</h6>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div className="details-box">
              <img
                className="imagebanner"
                src={require("../assets/images/bannerdemo.jpeg")}
                alt="bannerdemo.jpeg"
              />
            </div>
          </div>

          <div className="mt-4"></div>

          <ul className="nav nav-tabs offertabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active "
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
              >
                Deals & Cashback
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile-tab-pane"
                type="button"
                role="tab"
                aria-controls="profile-tab-pane"
                aria-selected="false"
              >
                About
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabIndex="0"
            >
              <div className="row">
                <div className="col-12">
                  <h6 className="mt-3">Get Instant Discount</h6>
                </div>
                {state?.offers ? (
                  state?.offers.map((offer, index) => (
                    <div
                      className="maincard col col-xl-4 col-lg-4 col-md-4 col-sm-4 "
                      key={index}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className={`${
                          selectedOfferId === offer.id
                            ? "selected"
                            : "cardsOffer"
                        }`}
                        onClick={() => userAcceptedOffer(offer.id, offer.title)}
                      >
                        <TbDiscount2 className="imageoffers" />
                        <div className="offer-content">
                          <h6 className="text-center">{offer.title}</h6>
                          <h6 className="text-center">{offer.description}</h6>
                        </div>
                        <div className="plusIcon">
                          <i className="fa-solid fa-plus iconePlus"></i>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <div className="emptyContainer">No offers available</div>
                    {/* <div className="my-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Amount"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                    <button
                      className="sign_Btn"
                      style={{ border: "none" }}
                      onClick={updatePaymentData}
                    >
                      PAY
                    </button> */}
                  </>
                )}
              </div>
              <div className="my-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Amount"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <button
                className="sign_Btn w-25"
                style={{ border: "none" }}
                onClick={updatePaymentData}
                disabled={loading ? true : false}
              >
                PAY
              </button>
              <div className="mt-4"></div>

              {state?.offers ? (
                <>
                  <div
                    // style={{ cursor: "pointer" }}
                    className="sign_Btn"
                    // onClick={() => openLink(state.qrCode)}
                  >
                    {selectedOfferTitle
                      ? selectedOfferTitle.substring(0, 20)
                      : "Select Offer"}
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => openLink(state.qrCode)}
                  >
                    <a className="sign_Btn">Save deal for later</a>
                  </div>
                </>
              ) : (
                <div
                  className="sign_Btn"
                  style={{ cursor: "pointer" }}
                  onClick={() => openLink(state.qrCode)}
                >
                  Save deal for later
                </div>
              )}
            </div>
            <div
              className="tab-pane fade"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabIndex="0"
            >
              Deals Terms And
            </div>
          </div>
          <div className="mt-4"></div>
        </section>
      </Container>
    </div>
  );
}

export default Offerspage;
