import React,{useState,useRef} from 'react'
import { API_BASE_URL,API_KEY, APP_ID, APP_OS, MERCHANT_ROLE_ID, IMEI_NO ,BRAND_ID} from '../Constants'
import {  Link,useNavigate} from 'react-router-dom'
//import CountryList from '../common/CountryList'
import CountryDropdown from 'country-dropdown-with-flags-for-react';
import axios, * as others from 'axios';
import { Container, Row, Col } from "reactstrap";
import ContentHeader from '../components/Common/ContentHeader';
import {GeoLocation} from '../components/Common/GeoLocation';
import AppLoader from "../components/Common/Loader";
import Model from './Model'
import {createSendData,getFunction,postFunction,validateEmail,mobileValidate} from '../components/Common/Actions'
const BecomeMerchant = () => {
     let navigate = useNavigate();
     
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue)
  
    const handleChange = (e) => {
      setValue(e.target.value)
    }
    return {
      value,
      onChange: handleChange,
    }
  }
    let latlong = GeoLocation()
    console.log(latlong)
    const inputElement = useRef();
    const emailRef = useRef();
    const mobileRef = useRef();
   console.log(localStorage.getItem('latitude'));
  const fullname = useFormInput('')
  const email = useFormInput('')
  const mobile =useFormInput('')
  const country_code = useFormInput('')
  const [countryCode,setCountryCode]=useState(0);
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  
  const handleRegister = async() => {
    setLoading(true)
    //console.log(fullname.value+email.value+mobile.value+country_code.value)
    if(fullname.value==''){
        setError('Please enter your fullname')
        inputElement.current.focus();
         setLoading(false)
    }else if(email.value==''){
        setError('Please enter your email')
        emailRef.current.focus();
         setLoading(false)
    }else if(validateEmail(email.value)===false){
        setError('Please enter your valid email')
        emailRef.current.focus();
         setLoading(false)
    }else 
        if(mobile.value==''){
        setError('Please enter your  mobile')
        mobileRef.current.focus();
         setLoading(false)
    }
else 
        if(mobileValidate(mobile.value)===false){
        setError('Please enter your valid mobile')
        mobileRef.current.focus();
         setLoading(false)
    }else{
    setLoading(true)
    let dataToSend = {fullName:fullname.value,email: email.value, countryCode:countryCode
      ,mobile_no: mobile.value,KEY:API_KEY,role_id:MERCHANT_ROLE_ID,lat:latlong.latitude,long:latlong.longitude,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID};
    
    let Url = "users/api/register";
    let params = createSendData(dataToSend);
    const responseJson = await postFunction(Url,params);
    
        if (responseJson.status == '1') {
          
          //setError(responseJson.msg)
          console.log(responseJson.data.id);
          localStorage.setItem('userId',responseJson.data.id);
          localStorage.setItem('mobile_no',mobile.value);
          localStorage.setItem('role_id',MERCHANT_ROLE_ID);
          navigate('../register-business')
            
          
          
        } else {
          setError(responseJson.msg)
          
        }
        setLoading(false)
    }
      
  }

  
  
  <GeoLocation name="latitude"/>
  
const message =error && error?<div className="alert alert-warning alert-dismissible  show">
<a className="close" data-dismiss="alert">&times;</a>
{error}

</div>:''
  return (
    <section className='main_section_wrapper'>
    <div className="welcome_section_wrapper">{loading&& loading?<AppLoader />:''}
      <Container>
      <ContentHeader pagename="landing" />
      
      
    <div className="justify-content-center ">
          
                <form className='login_from_wrapper' action='/register'>
                {message}
                
                  <p className="text-medium-emphasis">Create your account</p>
                 
                    <label>Your Full Name</label>
                    <div className="form-group md-4">
                    <input className="form-control" placeholder="Enter your fullname" ref={inputElement} required autoComplete="fullname" {...fullname}  />
                    </div>
                   
                  
                  <label>Your Email Address</label>
                  <div className="form-group md-4">
                    
                    <input className="form-control" ref={emailRef} placeholder="Email" type="email" required autoComplete="email" {...email}/>
                    </div>
                  
                  
                  <label>Phone Number</label>
                  
                  <div className="row">
                        <div className="col-md-12">
                          <div className="main_contry_wrapper1">
                            <div className="country_code">
                              <CountryDropdown  id="country_code" className='country_dropdown ' preferredCountries={['in']}  value="" {...country_code} handleChange={e => setCountryCode(e.target.value)}></CountryDropdown>
                            </div>
                            <div className="count-place">
                              <input className="form-control" placeholder="Phone Number" ref={mobileRef}  maxLength={10} autoComplete="mobile" {...mobile} />
                            </div>
                          </div>
                        </div>
                      </div>
                  
                  
                  
                  <div className="footer_login">
									<a href="javascript:void(0);" className="sign_Btn" onClick={handleRegister} > Continue </a>
								</div>
                  
                </form>
           </div>   
           
      </Container>
      
    </div>
</section>
  );
};

export default BecomeMerchant;
