import React, { useState, useEffect } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate, useLocation } from "react-router-dom";

//import CountryDropdown from 'country-dropdown-with-flags-for-react';

import { Container } from "reactstrap";

import { createSendData, getFunction } from "../components/Common/Actions";
import { useDispatch } from "react-redux";
import { cartActions } from "../store/shopping-cart/cartSlice";
import ProfileHeader from "../components/Common/ProfileHeader";
//import AppLoader from "../components/Common/Loader";
const VoucherDetails = (props) => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  if (!userID) {
    navigate("../login");
  }
  const [error, setError] = useState(null);
  //const [loading, setLoading] = useState(false)

  const [voucherDetails, setVoucherDetails] = useState("");

  const location = useLocation();
  // console.log(location.state);

  const voucherId = location.state.voucherId;
  const type = location.state.type;

  let id = voucherId;
  const [title, setTitle] = useState(); //voucher.combo_voucher_name
  const [image01, setImage01] = useState(); // voucher.combo_voucher_image
  const [price, setPrice] = useState(0); //voucher.conbo_sale_price
  const [desc, setDesc] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [shippingCharges, setShippingCharges] = useState(0);
  const dispatch = useDispatch();
  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id,
        title,
        image01,
        price,
        type,
        shippingCharges,
      })
    );
  };

  useEffect(() => {
    const viewDetails = async (URL, voucherId, type) => {
      if (voucherId) {
        try {
          let dataToSend = {
            userId: localStorage.getItem("userId"),
            mobile_no: localStorage.getItem("mobile_no"),
            KEY: API_KEY,
            role_id: CLIENT_ROLE_ID,
            brand_id: BRAND_ID,
            app_os: APP_OS,
            imei_no: IMEI_NO,
            app_id: APP_ID,
            token_key: localStorage.getItem("token_key"),
            voucherId: voucherId,
            type: type,
          };

          let params = createSendData(dataToSend);
          const responseJson = await getFunction(URL, params);

          if (responseJson.status === 1) {
            setVoucherDetails(responseJson.data);
            if (type === "gift") {
              setTitle(responseJson.data.gift_card_name);
              setImage01(responseJson.data.gc_image);
              setPrice(responseJson.data.price);
              setDesc(responseJson.data.gc_desc);
              setActualPrice(responseJson.data.gc_discount);
              setShippingCharges(responseJson.data.shipping_charges);
            } else if (type === "voucher") {
              setTitle(responseJson.data.combo_voucher_name);
              setImage01(responseJson.data.combo_voucher_image);
              setPrice(responseJson.data.voucher_sale_price);
              setDesc(responseJson.data.combo_voucher_description);
              setActualPrice(responseJson.data.combo_voucher_actual_amount);

              setShippingCharges(responseJson.data.shipping_charges);
            } else {
              setTitle(responseJson.data.name);
              setImage01(responseJson.data.image_url);
              setPrice(responseJson.data.price);
              setDesc(responseJson.data.vdesc);
              setActualPrice(responseJson.data.price);

              setShippingCharges(0);
            }
          } else {
            // localStorage.clear();
            setError(responseJson.msg);
          }
        } catch (error) {
          setError(error);
        } finally {
          //setLoading(false);
        }
      }
    };

    if (type === "gift") {
      viewDetails("giftcards/api/getById", voucherId, type);
    } else {
      viewDetails("voucher/api/getById", voucherId, type);
    }
  }, []);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  const txnView = voucherDetails ? (
    <div className="voucher-details mb-3">
      <br />
      {message}
      <div className="text-center ">
        <img src={image01} alt={title} height="200" width="200" />
      </div>
      <div className=" ml-4 voucher-title">{title} </div>

      <div className="ml-4 voucher-desc"> Description: </div>
      <div className="ml-4 decs-text">{desc}</div>
      <br />
      <div className="ml-4 ">
        {" "}
        <span className="vprice "> Price: &#8377;{actualPrice} </span>
      </div>
      <div className="ml-4">
        <span className="sale-price">Sale Price: &#8377;{price}</span>
      </div>
      <br />
      <hr className=" voucher-details-hr" />
      <br />

      <div className="text-center mt-4 ">
        <Link to="../cart" className=" buy-now  " onClick={addToCart}>
          Buy Now
        </Link>{" "}
      </div>
      <br />
    </div>
  ) : (
    ""
  );

  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        <ProfileHeader pagename="welcome" title={type + " Details"} />
        <Container>
          <div className="container-fluid">{txnView}</div>
        </Container>
      </div>
      <div className="divider-120"></div>
    </section>
  );
}; //localStorage.getItem('payment_txnid')

export default VoucherDetails;
