import React from "react";
import { Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import SLogo from "./SLogo";
import goBackIcon from "../../assets/images/arrow_01.svg"
import questionMarkIcon from "../../assets/images/question_mark.svg"

const ContentHeader = (props) => {
  let navigate = useNavigate();
  const goPage = (pageUrl) => {
    console.log("pageurllllll", pageUrl);
    navigate("../" + pageUrl);
  };
  let imgname = props.imgname;
  return (
    <React.Fragment>
      <Row>
        <div className="col-md-12">
          {/* <div className="mt-4"></div> */}
          <Row>
            <div className="col-4 mt-4">
              <div>
                <img
                  src={goBackIcon}
                  alt="goback"
                  onClick={() => goPage(props.pagename)}
                />
              </div>
            </div>
            <div className="col-4 mtp-4">
              <SLogo />
            </div>
            <div className="col-4 mt-4">
              <div className="text-right">
                <img
                  src={questionMarkIcon}
                  alt="question mark"
                />
              </div>
            </div>
          </Row>
        </div>
      </Row>
      <div className="mt-4"></div>
      <Row>
        <div className="col-12">
          <div className="welcome_img">
            {imgname ? (
              <img
                src={"./images/" + imgname + ".png"}
                className="img-responsive"
                alt={imgname}
              />
            ) : (
              <img
                src="./images/register_ac.png"
                className="img-responsive"
                alt="account"
              />
            )}
          </div>
        </div>
      </Row>
      <div className="mt-4"></div>
    </React.Fragment>
  );
};
export default ContentHeader;
