import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";

import AutoSelection from "../components/Common/AutoSelection";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
import { Container, Row, Col } from "reactstrap";
import ProfileHeader from "../components/Common/ProfileHeader";

const Receive = () => {
  let navigate = useNavigate();
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const [values, setValues] = useState("");
  const [mobile, setMobile] = useState("");
  const country_code = useFormInput("");
  const [countryCode, setCountryCode] = useState(0);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let userID = localStorage.getItem("userId");
  const [receivers, setReceivers] = useState(null);
  const [tabType, setTabType] = useState("Receive");
  const sendpoint = useFormInput("");
  const [receivedSmileez, setReceivedSmileez] = useState([]);
  const receiveSmileez = async () => {
    try {
      //console.log(fullname.value+email.value+mobile.value+country_code.value)
      let dataToSend = {
        userId: userID,
        countryCode: countryCode,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        receiver_mobile: mobile,
        smileez: sendpoint.value,
      };

      let Url = "gratification/api/receivesmileez_req";
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);

      if (responseJson.status === 1) {
        //console.log(responseJson.data)
        setError(responseJson.msg);
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const myRewards = async () => {
    let dataToSend1 = {
      userId: userID,
      mobile_no: localStorage.getItem("mobile_no"),
      KEY: API_KEY,
      role_id: CLIENT_ROLE_ID,
      brand_id: BRAND_ID,
      app_os: APP_OS,
      imei_no: IMEI_NO,
      app_id: APP_ID,
      token_key: localStorage.getItem("token_key"),
    };

    let reUrl = "gratification/api/receivedSmileez";
    let reparams = createSendData(dataToSend1);
    const rewardsJson = await getFunction(reUrl, reparams);
    if (rewardsJson.status === 1) {
      setReceivedSmileez(rewardsJson.data);
    } else {
      //setError(rewardsJson.msg)
    }
  };
  const findUser = async (mobileval) => {
    //console.log(mobileval)
    if (mobileval > 4) {
      try {
        let dataToSend = {
          userId: userID,
          countryCode: countryCode,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          receiver_mobile: mobileval,
        };

        let Url = "users/api/bymobile";
        //console.log(Url)
        let params = createSendData(dataToSend);
        const responseJson = await getFunction(Url, params);
        if (responseJson.status === 1) {
          setReceivers(responseJson.data);
        } else {
          setError(responseJson.msg);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    //console.log(mobile.value)
    if (mobile && mobile.length >= 2) {
      findUser(mobile);
    }
  }, [mobile]);
  const dataSet = (val) => {
    console.log(val);
    let mob = val.split(" ");
    setMobile(mob["0"]);
  };

  let receiList = "";
  if (receivers) {
    receiList = receivers.map((rec) => rec.mobileno + " " + rec.display_name);
  }
  const tabchanged = (type) => {
    setTabType(type);
  };
  useEffect(() => {
    myRewards();
  }, [tabType]);
  let historyList = "";
  if (receivedSmileez.length > 0) {
    historyList = receivedSmileez.map((rec) => (
      <div className="row historyList">
        <div className="col-9 col-xs-12">
          <div className="col-12 historylebel">From, {rec.display_name}</div>
          <div className="col-12 historysubtext">
            Mobile No: {rec.mobileno} | Date: {rec.createdon}
          </div>
        </div>
        <div className="col-3 col-xs-12 text-right historytext1">
          {rec.amount}
          <br />
          Smileez
        </div>
        <hr />
      </div>
    ));
  } else {
    historyList = (
      <div className=" historyNotFound text-center ">
        <img
          src={require("../assets/images/material-symbols_error-outline.png")}
          height="84"
          width="85"
          alt="No Data found"
        />
        <div className="text-center historyNotFoundtext">
          Not able to load the data
        </div>
      </div>
    );
  }

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        <ProfileHeader pagename="welcome" title="Receive Smileez" />
        <Container>
          <div className="justify-content-center mt-4">
            <div className="tabbable">
              <div className=" mb-4">
                <ul className="nav nav-tabs">
                  <li className={tabType == "Receive" ? "active" : "inactive"}>
                    <a
                      data-toggle="tab"
                      href="#Receive"
                      onClick={() => tabchanged("Receive")}
                    >
                      Receive
                    </a>
                  </li>
                  <li className={tabType == "History" ? "active" : "inactive"}>
                    <a
                      data-toggle="tab"
                      href="#History"
                      onClick={() => tabchanged("History")}
                    >
                      History
                    </a>
                  </li>
                </ul>
              </div>

              <div className="row smileez_balance ">
                <div className="col-8 textcolor">
                  <img
                    src={require("../assets/images/smileezbal.png")}
                    height="40"
                    width="40"
                    alt="Smileez Bal"
                  />
                  Smileez Balance:{" "}
                </div>
                <div className="col-4 valuetext ">
                  {localStorage.getItem("smileezRewards")}
                </div>
              </div>
              <div className="tab-content mt-4">
                <div
                  id="Receive"
                  class={tabType == "Receive" ? "tab-pane active" : "tab-pane"}
                >
                  <form className="login_from_wrapper" action="/send-smileez">
                    {message}

                    <div className="mt-4 pb-4">
                      <div className="labeltext">
                        Receive smileez from the user
                      </div>
                      <label className="">Enter mobile no: </label>
                      <AutoSelection
                        suggetions={receiList}
                        placeholder="Enter user mobile"
                        mydata={dataSet}
                        length={10}
                        type="text"
                      />
                    </div>

                    <label>Enter no. of smileez to be received:</label>
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          className="form-control"
                          placeholder="Enter Points to be sent"
                          maxLength={5}
                          autoComplete="sendpoint"
                          {...sendpoint}
                        />
                      </div>
                    </div>

                    <div className="footer_login">
                      <a
                        href="javascript:void(0)"
                        className="receive_request_Btn"
                        onClick={receiveSmileez}
                      >
                        {" "}
                        Send Request{" "}
                      </a>
                    </div>
                  </form>
                </div>
                <div
                  id="History"
                  class={tabType == "History" ? "tab-pane active" : "tab-pane "}
                >
                  <div className="row labeltext pb-4 pl-4">
                    Smileez’s received
                  </div>
                  <div className="myHistory">{historyList}</div>
                </div>
              </div>
            </div>{" "}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Receive;
