import Routes from "../../routes/Routers";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import Carts from "../UI/cart/Carts.jsx";
import { useSelector } from "react-redux";

const LayoutContainer = () => {
  const showCart = useSelector((state) => state.cartUi.cartIsVisible);

  return (
    <section className="main_section_wrapper">
      {showCart && <Carts />}

      <div>
        <Routes />
      </div>
    </section>
  );
};

export default LayoutContainer;
