import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
import AppLoader from "../components/Common/Loader";
import OtpInput from "react-otp-input";
import { Container, Row, Col } from "reactstrap";
import SLogo from "../components/Common/SLogo";

const Otp = () => {
  const [roleId, setRoleId] = useState(localStorage.getItem("role_id"));

  let navigate = useNavigate();
  const goPage = (pageurl) => {
    console.log(pageurl);
  };
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const camelCase = (str) => {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);
      let role_id = roleId ? roleId : CLIENT_ROLE_ID;
      if (otp === "") {
        setError("otp is required");
      }
      let dataToSend = {
        otp: otp,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
      };

      let Url = "users/api/otpchk";
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);

      //console.log(responseJson);
      if (responseJson.status == 1) {
        localStorage.setItem("currentUser", true);
        localStorage.setItem("token_key", responseJson.token_key);
        localStorage.setItem("user_id", responseJson.user_details.id); // its for login users and layout changes
        localStorage.setItem(
          "display_name",
          responseJson.user_details.display_name
        );
        localStorage.setItem("role_id", responseJson.user_details.role_id);
        //navigate('../welcome')

        if (role_id == CLIENT_ROLE_ID) {
          //window.location.href='/welcome';
          window.location.href = "/user/welcome";
        } else {
          //window.location.href='/dashboard';
          window.location.href = "/user/dashboard";
        }
        setLoading(false);
      } else {
        // localStorage.clear();
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const otpGen = async (mobile) => {
    try {
      setLoading(true);
      let dataToSend = {
        mobile_no: mobile,
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
      };

      let Url = "users/api/otpgen";
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);
      if (responseJson.status == true) {
        setError("Otp has been sent to " + mobile);
        setLoading(false);
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("mobile_no") &&
      localStorage.getItem("mobile_no") !== "undefined"
    ) {
      otpGen(localStorage.getItem("mobile_no"));
    }
  }, []);

  useEffect(() => {
    if (otp && otp.length === 4) {
      setOtp(0);

      verifyOTP();
    } else {
      //otpGen(localStorage.getItem('mobile_no'))
    }
  }, [otp]);

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  //{"otp":"4296","mobile_no":"9342342432","KEY":"SMZKEY1234","role_id":"57","brand_id":"1","app_os":"WEB","imei_no":"MYimei","app_id":"Smileez"}
  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <div className="container">
          <div className="mt-4"></div>

          <div className="container">
            <div className="col-md-12">
              <Row>
                <div className="col-4">
                  <div>
                    <Link to="/landing">
                      <img
                        src={require("../assets/images/arrow_01.svg")}
                        alt=""
                        onClick={() => goPage("/landing")}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-4 ">
                  <SLogo />
                </div>
                <div className="col-4">
                  <div className="text-right">
                    <img
                      src={require("../assets/images/question_mark.svg")}
                      alt=""
                    />
                  </div>
                </div>
              </Row>
            </div>
          </div>
          <div className="mt-4"></div>
          <Row>
            <div className="col-12">
              <div className="welcome_img">Code Confirmation</div>
            </div>
          </Row>
          <div className="mt-80"></div>
          <div className="">
            <div className="col-12">
              {message}
              <div>
                <div className="confirmation_code">
                  <p>Please enter the 4-digit code sent to </p>
                  <p>
                    {" "}
                    <b>{localStorage.getItem("mobile_no")}</b>{" "}
                  </p>
                </div>

                <form className="otp_cord">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={" "}
                    renderInput={(props) => (
                      <input {...props} inputMode="numeric" />
                    )}
                    inputStyle="otp_text"
                    containerStyle="otp_cord"
                  />
                </form>
              </div>
              <div className="mt-50"></div>
              <div className="get_code_content">
                <p> Didn't get the code </p>
              </div>
              <div className="mt-2">
                <a
                  href="javascript:void(0)"
                  className="send_Code_Btn"
                  onClick={() => otpGen(localStorage.getItem("mobile_no"))}
                >
                  {" "}
                  send again{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Otp;
