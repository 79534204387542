import React, { useEffect, useState } from "react";
import {  useNavigate} from 'react-router-dom'

const PrintQrcode = (props) => {
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    console.log(props);
    const printDiv=(divid) =>{
        
var pri = document.getElementById("myModal4").contentWindow;
pri.document.open();
pri.document.write(divid.innerHTML);
pri.document.close();
pri.focus();
pri.print();
    }
   
    
    return (
        <div id={props.modelId} className="modal fade" aria-hidden="true" role="dialog" onClick={props.dismiss}>
  <div className="modal-dialog">
 <div className="modal-footer">
        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={()=>printDiv('printableArea')}>Print</button> 
        &nbsp;<button type="button" className="btn btn-default closebtn" data-dismiss="modal">Close</button>
      </div> 
    
    <div className="modal-content" id="printableArea">
    <div className="modal-header">
       	<span className="modal-title mtitle" > 
                <span  id="frmName">{props.qrcode?props.qrcode.merchant_name:''}</span>
                <br />
                <span id="addess12">{props.qrcode?props.qrcode.address:''+' '+props.qrcode?props.qrcode.state:''+ ' '+props.qrcode?props.qrcode.country:''}</span>
                </span>
        <span className="modal-title membership" >M</span>
      </div>
        <div className="alert alert-error" id="errorId"></div>
      <div className="modal-body" id="result-qr-code" align="center">
          <img src={props.qrcode?props.qrcode.imgPath:''} id="qrcode_image"  alt="qr code"/> 
          </div>
         
      </div>
     
    </div>

  </div>
   
            
  );
};
    export default PrintQrcode