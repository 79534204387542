export function getCurrentDate(separator='-'){

let newDate = new Date()
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();

return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
}
var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
export function getCustomDate(mydate,nomberofdays,separator='-'){
    var d = new Date();



 d.setDate(d.getDate() - nomberofdays);
 let date = d.getDate();
 let month = d.getMonth() + 1;
 let year = d.getFullYear();
 return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`

    }
    
    export function getCustomDateTime(mydate,separator='-'){
       
 let date = mydate.getDate();
 let month = mydate.getMonth() + 1;
 let year = mydate.getFullYear();
 let hour = mydate.getHours();
 let minute = mydate.getMinutes();
 let seconds=mydate.getSeconds();
 return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date} ${hour}:${minute}:${seconds}`

    }
    
    
    export function phpDateToJsDate(mydate){
       let myDate=  mydate.replace(/-/g, "/"); 
let fd = new Date(myDate);
return fd;
    }
    
    export function dateFormat(date){
        var d = new Date(date);
        //var options = { day: 'numeric', year: 'numeric', month: 'short'  };
        return d.getDate() + ' ' + monthShortNames[d.getMonth()] + ', ' + d.getFullYear();
    }
    
    export function formatDate(date = new Date()) {
  var day, month, year;

  year = date.getFullYear();
  month = date.getMonth() + 1;
  day = date.getDate();
  
  if (month < 10) {
    month = '0' + month;
  }

  if (day < 10) {
    day = '0' + day;
  }
  
  return day + '/' + month + '/' + year;
}