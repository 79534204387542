import React, { useState, useEffect } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link } from "react-router-dom";
import { createSendData, getFunction } from "../components/Common/Actions";
import AppLoader from "../components/Common/Loader";
const RewardsTransactions = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rewardsData, setRewardsData] = useState(null);
  const getRewardsTxn = async () => {
    try {
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;
      let userID = localStorage.getItem("userId");
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
        send_receive_type: "Smileez",
      };

      let Url = "transactions/api/transactionList";
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);

      if (responseJson.status === 1) {
        setRewardsData(responseJson.userdata);
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRewardsTxn();
  }, []);

  let txnlink = rewardsData ? "../payment-history" : "#";
  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  return (
    <div className="row">
      {loading && loading ? <AppLoader /> : ""}
      <div className="col-md-12">
        {message}
        <div className="reward_card transactionCard">
          <h5>
            <Link to={txnlink} className="txnlink">
              See transactions{" "}
              <img src={require("../assets/images/arrow_icon.png")} alt="arrow_icon" />
            </Link>{" "}
          </h5>
          <img src={require("../assets/images/reward_icon.png")} alt="rewards" />
          <div className="row align-items-center textTransaction">
            <div className="col-md-8 col-8">
              <div className="transactions_title">
                <h4> Total Reward Transaction: </h4>
              </div>
            </div>
            <div className="col-md-4 col-4">
              <div className="transactions_amount">
                <h4>
                  {" "}
                  {rewardsData && rewardsData.total
                    ? rewardsData.total
                    : "0"}{" "}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RewardsTransactions;
