import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createSendData,
  getFunction,
  extPostFunction,
} from "../components/Common/Actions";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AppLoader from "../components/Common/Loader";

const ExternalVouchers = (props) => {
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [extVouchers, setExtVouchers] = useState(null);

  const getextvouchers = async (URL) => {
    try {
      setLoading(true);
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
      };
      let params = createSendData(dataToSend);
      const response = await getFunction(URL, params);
      if (response.status == 1) {
        setExtVouchers(response.data);
      } else {
        setError(response.msg);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getextvouchers("voucher/api/brandVouchers");
  }, []);
  let list = "";
  let url = "../category-vouchers"; //"https://mobicashindia.com/app/checkout?name=";

  if (extVouchers) {
    list = extVouchers.map((lt, index) => (
      <div className="item" key={index}>
        <div className="logo-partners text-center">
          <Link
            to={url}
            className="pointer_class"
            state={{ voucher_category: lt.category }}
          >
            <img
              src={lt.image_url}
              alt={lt.name}
              className="img-responsive img-fluid"
              // height="70"
            />
          </Link>
        </div>
      </div>
    ));
  }

  return (
    <OwlCarousel
      className="owl-carousel owl-theme"
      loop
      nav
      items={3}
      dots={false}
    >
      {list}
    </OwlCarousel>
  );
};
export default ExternalVouchers;
