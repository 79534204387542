import React, { useState } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  MERCHANT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { useNavigate } from "react-router-dom";
//import CountryList from '../common/CountryList'
import { createSendData, postFunction } from "../components/Common/Actions";
import { Container, Row } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import AppLoader from "../components/Common/Loader";

const RegisterBussiness = () => {
  let navigate = useNavigate();
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const business_name = useFormInput("");
  const business_address = useFormInput("");
  const pan_number = useFormInput("");
  const gst_number = useFormInput("");
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    setLoading(true);
    if (business_name.value == "") {
      setError("Business name should not be empty.");
    } else if (business_address.value == "") {
      setError("Business address should not be empty.");
    } else if (pan_number.value == "") {
      setError("PAN number should not be empty.");
    } else if (gst_number.value == "") {
      setError("GST number should not be empty.");
    } else {
      //console.log(fullname.value+email.value+mobile.value+country_code.value)
      let dataToSend = {
        business_name: business_name.value,
        business_address: business_address.value,
        pan_number: pan_number.value,
        gst_number: gst_number.value,
        KEY: API_KEY,
        role_id: MERCHANT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        userId: userId,
      };

      let Url = "users/api/businessInfo";
      let params = createSendData(dataToSend);
      const responseJson = await postFunction(Url, params);
      
      // If server response message same as Data Matched
      if (responseJson.status === 1) {
        // setUserId(responseJson.data.id);
        localStorage.setItem("business_name", business_name.value);
        localStorage.setItem("business_address", business_address.value);
        localStorage.setItem("pan_number", pan_number.value);
        localStorage.setItem("gst_number", gst_number.value);
        localStorage.setItem("userId", userId);
        navigate("../register-business-step2");
      } else {
        setError(responseJson.msg);
      }
      setLoading(false);
    }
  };

  const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <Container>
          <ContentHeader pagename="/become-merchant" imgname="seller_account" />
          <Row className="justify-content-center">
            <form className="login_from_wrapper ">
              {message}
              <label>Bussiness Name</label>
              <div className="form-group md-4">
                <input
                  placeholder="Enter your bussiness name"
                  autoComplete="business_name"
                  className="form-control"
                  {...business_name}
                />
              </div>

              <label>Bussiness Address</label>
              <div className="form-group md-4">
                <input
                  placeholder="Bussiness Address"
                  autoComplete="Business Address"
                  className="form-control"
                  {...business_address}
                />
              </div>

              <label>Pan Number</label>
              <div className="form-group md-4">
                <input
                  placeholder="Pan Number"
                  autoComplete="pannumber"
                  maxLength={10}
                  className="form-control"
                  value={pan_number}
                  {...pan_number}
                  onInput={toInputUppercase}
                />
              </div>

              <label>GST Number</label>
              <div className="form-group md-4">
                <input
                  placeholder="GST Number"
                  autoComplete="gstnumber"
                  maxLength={16}
                  className="form-control"
                  value={gst_number}
                  {...gst_number}
                  onInput={toInputUppercase}
                />
              </div>

              <div className="footer_login">
                <a
                  href="javascript:void(0);"
                  className="sign_Btn"
                  onClick={handleRegister}
                >
                  {" "}
                  Next{" "}
                </a>
              </div>
            </form>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default RegisterBussiness;
