// export const API_BASE_URL = "https://smileez.club/public/admin/"; //"https://smileez.club/public/admin/"
export const API_BASE_URL = "https://www.smileez.club/public/admin/";
export const API_KEY = "SMZKEY1234";
export const I18nkey = "SMZKEY#18&#$";
export const CLIENT_ROLE_ID = 57;
export const APP_ID = "Smileez";
export const APP_OS = "WEB";
export const IMEI_NO = "MYimei";
export const BRAND_ID = 1;
export const MERCHANT_ROLE_ID = 56;
export const MOBICASH_VOUCHERS_URL = "https://mobicash.in/Api/GetVouchers";
export const SMILEEZREWARD_VOUCHERS_URL =
  "https://mobicash.in/smilezz/GetMerchantOffers";
export const SCAN_QR_CODE_VOUCHERS_URL =
  "https://mobicash.in/smilezz/SendQrCode";
export const STORE_USER_ACCEPTED_OFFER =
  "https://mobicash.in/smilezz/StoreUserAcceptedOffer";
export const GET_OFFER_CATEGORY =
  "https://mobicash.in/smilezz/GetOfferCategory";
export const ADD_MERCHANT_OFFER =
  "https://mobicash.in/smilezz/AddMerchantOffer";

export const UPDATE_PAYMENT_DATA = "https://mobicash.in/smilezz/SendQrCode";
export const GET_USER_PAYMENT_STATUS =
  "https://mobicash.in/smilezz/GetUserPaymentStatus";

//const axios = require('axios').default;  https://smileez.club/public/admin/

// git app password : ATBB7Fq74xTP95XQ7ukBsKw52r2Y46B179B7
