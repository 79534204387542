import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row } from "reactstrap";
import ProfileHeader from "../components/Common/ProfileHeader";

const FailPage = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  if (!userID) {
    navigate("../login");
  }

  const orderId = localStorage.getItem("orderId") || "";
  const transactionStatus = localStorage.getItem("transactionStatus") || "";
  const transactionTime = localStorage.getItem("transactionTime") || "";
  return (
    <>
      <ProfileHeader pagename="pay" title="Payment Status" />
      <section className="main_section_wrapper">
        <div className="welcome_section_wrapper">
          <Container>
            <div className="container-fluid form">
              <form className="login_from_wrapper">
                <Row className="text-center center">
                  <div>
                    {localStorage.getItem("payment_status") &&
                    localStorage.getItem("payment_status") == "COMPLETED" ? (
                      <img
                        src={require("../assets/images/right-tick.png")}
                        alt={localStorage.getItem("payment_status")}
                      />
                    ) : (
                      <img
                        src={require("../assets/images/failed-tick.png")}
                        alt={localStorage.getItem("payment_status")}
                      />
                    )}
                  </div>
                </Row>
                <div className="text-center mt-2">
                  <div>Order Id: {orderId}</div>
                  <div>Transaction Status: {transactionStatus}</div>
                  <div>Transaction Time: {transactionTime}</div>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default FailPage;
