import React from 'react'


const Page404 = () => {
  return (
    <section className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <div className="container">
        <div className="justify-content-center">
          <div className="col-md-6">
            <div className="clearfix">
              <h1 className="float-start display-3 me-4">404</h1>
              <h4 className="pt-3">Oops! You{"'"}re lost.</h4>
              <p className="text-medium-emphasis float-start">
                The page you are looking for was not found.
              </p>
            </div>
            <div className="input-prepend">
              <label>
                Not found
              </label>
              <input type="text" placeholder="What are you looking for?" />
              <botton color="info">Search</botton>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Page404
