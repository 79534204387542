import React from "react";
import "../assets/css/style.css";

function Inventory() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h3 className="mb-4">Offers History</h3>
          <div className="reward_card">
            <div className="row align-items-center">
              <div className="col-8">
                <label className="control-label m-0">
                  Created On &nbsp; 2024-03-20 12:22:38
                </label>
              </div>
              <div className="col-4 configrightbt">
                <button>
                  <img src={require("../assets/images/editicon.png")} alt="equal" />
                  Edit
                </button>
              </div>
            </div>
            <hr className="hrwidth" />
            <div className="row mt-4">
              <div className="col">
                <label>Offer Name</label>
                <div> 123 </div>
                <label>Min Invoice Amount</label>
                <div>&#x20B9;123 </div>
                <label>Max Discount Amount</label>
                <div>&#x20B9;123 </div>
              </div>
              <div className="col">
                <img
                  src={require("../assets/images/editicon.png")}
                  alt="equal"
                  height="200"
                  width="100"
                />
              </div>
              <div className="col">
                <label>Offer Discount Amount</label>
                <div> &#x20B9;123 </div>
                <label>No. of Times</label>
                <div>123 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inventory;
