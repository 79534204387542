import React, { useState, useEffect } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  MERCHANT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { useNavigate } from "react-router-dom";
import Model from "./Model";
import {
  createSendData,
  getFunction,
  createFileData,
  postAxiosFunction,
} from "../components/Common/Actions";
import { Container, Row } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import AppLoader from "../components/Common/Loader";

const RegisterBussinessStep2 = () => {
  //const [isOpened, setIsOpened] = useState(false);
  let navigate = useNavigate();
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };
  const [merchantType, setMerchantType] = useState(false);

  const [businessName, setBusinessName] = useState(
    localStorage.getItem("business_name")
  );
  const [businessAddress, setBusinessAddress] = useState(
    localStorage.getItem("business_address")
  );
  const [panNumber, setPanNumber] = useState(
    localStorage.getItem("pan_number")
  );
  const [gstNumber, setGstNumber] = useState(
    localStorage.getItem("gst_number")
  );
  const [fileName, setFileName] = useState(null);
  const category = useFormInput("");
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    if (fileName == null) {
      setError("Business logo should not be empty.");
    } else if (category.value === "") {
      setError("Business category should not be empty.");
    } else {
      setLoading(true);
      let dataToSend = {
        business_name: businessName,
        business_address: businessAddress,
        pan_number: panNumber,
        gst_number: gstNumber,
        KEY: API_KEY,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        userId: userId,
        business_category: category.value,
        business_logo: fileName,
      };

      let Url = "users/api/businessInfoStep2";
      let params = createFileData(dataToSend);
      const responseJson = await postAxiosFunction(Url, params);

      // If server response message same as Data Matched
      if (responseJson.data.status === 1) {
        localStorage.setItem("userId", userId);
        localStorage.setItem("mobile_no", localStorage.getItem("mobile_no"));
        navigate("../otp");
        setLoading(false);
      } else {
        setError(responseJson.msg);
        setLoading(false);
      }
    }
  };

  const merchantCategory = async (URL, types) => {
    let resp = "";
    try {
      setLoading(true);

      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: MERCHANT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
      };

      let params = createSendData(dataToSend);

      resp = await getFunction(URL, params);
      console.log(resp);
      if (resp.status === 1) {
        setMerchantType(resp.userdata);
      } else {
        setMerchantType(resp.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    merchantCategory("common/api/merchantTypeslist", "merchant_types");
  }, []);

  console.log(merchantType);
  let categoryList = "";
  if (merchantType) {
    categoryList = merchantType.map((merType) => (
      <option value={merType.id} key={merType.id}>
        {merType.merchant_type_name}
      </option>
    ));
  }

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  //<!--data-toggle="modal" data-target="#myModal" -->
  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <Container>
          <ContentHeader
            pagename="register-business"
            imgname="few_more_steps"
          />
          <Row className="justify-content-center">
            <form className="login_from_wrapper ">
              {message}
              <div>
                <label>Upload Bussiness Logo</label>
                <div className="md-4 dotted_box">
                  <input
                    type="file"
                    className="form-group pointer_class form-control"
                    onChange={(e) => setFileName(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="mt-4">&nbsp;</div>
              <div className="mt-4">
                <label>Select Below Category</label>
                <div className="form-group md-4">
                  <select
                    name="category"
                    id="category"
                    className="form-control bgcolor"
                    {...category}
                  >
                    <option value={"0"}>Select Category</option>
                    {categoryList}
                  </select>
                </div>
              </div>

              <div className="footer_login">
                <a
                  href="javascript:void(0);"
                  className="sign_Btn"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={handleRegister}
                >
                  {" "}
                  Continue{" "}
                </a>
              </div>
            </form>
          </Row>
          <Model modelId="myModal" closeModel="setIsOpened" />
        </Container>
      </div>
    </section>
  );
};

export default RegisterBussinessStep2;
