import React, { useRef, useState, useEffect } from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import Loader from "../components/SplashScreen/FirstLoader";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../components/Common/Loader";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
  MERCHANT_ROLE_ID,
} from "../Constants";
import { GeoLocation } from "../components/Common/GeoLocation";
import { Container, Row, Col, Button } from "reactstrap";
import "../assets/css/style.css";
import {
  createSendData,
  getFunction,
  postFunction,
  mobileValidate,
  postAxiosFunction,
  createFileData,
} from "../components/Common/Actions";

const Landing = () => {
  let navigate = useNavigate();
  if (localStorage.getItem("user_id") && localStorage.getItem("role_id")) {
    if (localStorage.getItem("role_id") == CLIENT_ROLE_ID) {
      navigate("../welcome");
    } else {
      navigate("../dashboard");
    }
  }

  const goPage = (pageUrl, roleId) => {
    navigate("../" + pageUrl, {
      params: { role_id: roleId },
    });
  };
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2 * 1000); //30 sec idle time
  }, []);

  const [username, setUsername] = useState("");
  let latlong = GeoLocation();

  const mobileRef = useRef();
  //const password = useFormInput('')
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    if (mobileValidate(username) === false) {
      setError("Please enter your valid mobile");
      mobileRef.current.focus();
      setLoading(false);
    } else {
      setLoading(true);
      let dataToSend = {
        mobile_no: username,
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        lat: latlong.latitude,
        long: latlong.longitude,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
      };

      let Url = "users/api/login";
      let params = createFileData(dataToSend);
      const responseJson = await postAxiosFunction(Url, params);

      // If server response message same as Data Matched
      if (responseJson.status === 200) {
        if (responseJson.data.status === 1) {
          //Hide Loader
          setLoading(false);
          localStorage.setItem("userId", responseJson.data.data.id);
          localStorage.setItem("mobile_no", responseJson.data.data.mobileno);
          localStorage.setItem("role_id", responseJson.data.data.role_id);
          setError(responseJson.msg);
          navigate("../otp");
        } else {
          localStorage.setItem("mobile_no", username);
          navigate("../register");
        }
      } else {
        setError(responseJson.msg);
        setLoading(false);
      }
    }
  };

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <div className="">
      {loader ? <AppLoader /> : ""}
      <div className="col-12">
        <div className="landing_container_wrapper">
          <div className="mt-4"></div>
          <div className="landing_logo">
            <img
              src={require("../assets/images/smiliiz-logo.png")}
              className="img-responsive"
            />
          </div>
          <div className="landing_body_wrapper">
            <img
              src={require("../assets/images/login_illustration.png")}
              className="img-responsive"
              height="90%"
              width="90%"
            />
            <p> Login your account. </p>
          </div>

          <div className="landing_footer  mt-2">
            {message}
            {/* <div className="spacebtn-landing"> </div> */}
            <form className="login_from_wrapper">
              <div className="text-left">
                <label className="labelText">Mobile Number</label>
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  ref={mobileRef}
                  placeholder="Enter Your Number"
                  maxLength={10}
                  autoComplete="off"
                  {...username}
                  onChange={(e) => setUsername(e.target.value)}
                  inputMode="numeric"
                />
              </div>
              <div
                type="submit"
                className="create_Btn"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default behavior
                  handleLogin(e);
                }}
              >
                Log In{" "}
              </div>

              <a
                type="submit"
                className="seller_link"
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default behavior
                  goPage("become-merchant", MERCHANT_ROLE_ID);
                }}
              >
                <u>Seller Account</u>
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
