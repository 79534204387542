import React, { useState, useEffect } from "react";
import {
  API_BASE_URL,
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";
//import CountryList from '../common/CountryList'
import CountryDropdown from "country-dropdown-with-flags-for-react";
import axios, * as others from "axios";
import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";

import ProfileHeader from "../components/Common/ProfileHeader";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
import AppLoader from "../components/Common/Loader";
const BusinessProfile = () => {
  let navigate = useNavigate();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [status, setStatus] = useState(false);
  const [merchantType, setMerchantType] = useState(false);
  const [businessName, setBusinessName] = useState(
    userData ? userData.business_name : ""
  );
  const [businessAddress, setBusinessAddress] = useState(
    userData ? userData.business_address : ""
  );
  const [panNumber, setPanNumber] = useState(
    userData ? userData.pan_number : ""
  );
  const [gstNumber, setGstNumber] = useState(
    userData ? userData.gst_number : ""
  );
  const [businessCategory, setBusinessCategory] = useState(
    userData ? userData.business_category : ""
  );
  let role_id =
    localStorage.getItem("role_id") && localStorage.getItem("role_id")
      ? localStorage.getItem("role_id")
      : CLIENT_ROLE_ID;
  let userID = localStorage.getItem("user_id");
  const userInfo = async (URL, types) => {
    let resp = "";
    try {
      setLoading(true);

      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
      };

      let params = createSendData(dataToSend);

      resp = await getFunction(URL, params);
      console.log(resp);
      if (types === "userinfo") {
        if (resp.status === 1) {
          setUserData(resp.userdata);
          setBusinessName(resp.userdata.business_name);
          setBusinessAddress(resp.userdata.business_address);
          setPanNumber(resp.userdata.pan_number);
          setGstNumber(resp.userdata.gst_number);
          setBusinessCategory(resp.userdata.business_category);
        } else {
          setUserData(resp.msg);
        }
      } else {
        if (resp.status === 1) {
          setMerchantType(resp.userdata);
        } else {
          setMerchantType(resp.msg);
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    let response = "";

    try {
      if (businessName === "") {
        setError("Business Name is requred");
      } else if (businessAddress === "") {
        setError("Business address is requred");
      } else if (panNumber === "") {
        setError("pan number is requred");
      } else if (gstNumber === "") {
        setError("Gst number is requred");
      } else if (businessCategory == "" || businessCategory == "undefined") {
        setError("Business category number is requred");
      } else {
        setLoading(true);
        let dataToSend = {
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: role_id,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          userId: userID,
          business_name: businessName,
          address: businessAddress,
          pan_number: panNumber,
          gst_number: gstNumber,
          business_category: businessCategory,
          business_profile: 1,
        };

        let params = createSendData(dataToSend);
        response = await postFunction("users/api/saveuser", params);

        if (response === 1) {
          alert(response.msg);
          setError(response.msg);
        } else {
          setError(response.msg);
        }
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    userInfo("common/api/merchantTypeslist", "merchant_types");
    userInfo("users/api/userinfo", "userinfo");
  }, []);

  console.log(merchantType);
  let categoryList = "";
  if (merchantType) {
    categoryList = merchantType.map((merType) => (
      <option value={merType.id} key={merType.id}>
        {merType.merchant_type_name}
      </option>
    ));
  }

  console.log(error);
  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  return (
    <section className="main_section_wrapper">
      <div className="profile_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <ProfileHeader pagename="profile" title="Business Porile" />
        <Container>
          <div className="mt-2"></div>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12 pointer_class">
                  <div className="text-center">
                    {userData && userData.business_logo ? (
                      <img
                        src={userData.business_logo}
                        alt="business_profile"
                      />
                    ) : (
                      <img
                        src={require("../assets/images/business_profile.png")}
                        alt="business_profile"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Container>
        <div className="mt-2"></div>
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div>
                  <form className="profile_form_section" action="/profile">
                    {message}

                    <label>Business Name</label>
                    <div className="form-group md-4">
                      <input
                        className="form-control"
                        placeholder="Business name"
                        value={businessName}
                        required
                        autoComplete="fullname"
                        maxLength="100"
                        onChange={(e) => {
                          setBusinessName(e.target.value);
                        }}
                      />
                    </div>

                    <label>Business Address</label>
                    <div className="form-group md-4">
                      <input
                        className="form-control"
                        placeholder="Business Address"
                        type="text"
                        value={businessAddress}
                        maxLength="150"
                        required
                        autoComplete="address"
                        onChange={(e) => {
                          setBusinessAddress(e.target.value);
                        }}
                      />
                    </div>
                    <label>Pan Number</label>
                    <div className="form-group md-4">
                      <input
                        className="form-control"
                        placeholder="Pan Number"
                        type="text"
                        value={panNumber}
                        maxLength="10"
                        required
                        autoComplete="pan"
                        onChange={(e) => {
                          setPanNumber(e.target.value);
                        }}
                      />
                    </div>
                    <label>GST Number</label>
                    <div className="form-group md-4">
                      <input
                        className="form-control"
                        placeholder="GST Number"
                        type="text"
                        maxLength="16"
                        value={gstNumber}
                        required
                        autoComplete="email"
                        onChange={(e) => {
                          setGstNumber(e.target.value);
                        }}
                      />
                    </div>

                    <label>Select Below Category</label>
                    <div className="form-group md-4">
                      <select
                        className="form-control"
                        value={businessCategory}
                        name="category"
                        id="category"
                        onChange={(e) => {
                          setBusinessCategory(e.target.value);
                        }}
                      >
                        <option value="">Select Category</option>
                        {categoryList}
                      </select>
                    </div>

                    <div className="">
                      <a
                        href="javascript:void(0)"
                        className="sign_Btn"
                        onClick={handleUpdateProfile}
                      >
                        {" "}
                        Save Profile{" "}
                      </a>
                    </div>

                    <div className="mt-4"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mt-4"></div>
      </div>
    </section>
  );
};

export default BusinessProfile;
