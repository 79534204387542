import React, { useState, useEffect } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
const AmountTransactions = (props) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amountData, setAmountData] = useState(null);
  const getAmountTxn = async () => {
    try {
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;
      let userID = localStorage.getItem("userId");
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
        send_receive_type: "Smileez",
      };

      let Url = "transactions/api/transactionList";
      //console.log(Url+'?'+formBody);
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);

      if (responseJson.status === 1) {
        setAmountData(responseJson.userdata);
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAmountTxn();
  }, []);

  let txnlink = amountData ? "../payment-history" : "#";
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="reward_card transactionCard">
          <img
            src={require("../assets/images/total_amount_icon.png")}
            alt="total payment"
          />
          <div className="row align-items-center textTransaction">
            <div className="col-md-8 col-8">
              <div className="transactions_title">
                <h4> Total Amount Transaction: </h4>
              </div>
            </div>
            <div className="col-md-4 col-4">
              <div className="transactions_amount">
                <h4>
                  {" "}
                  <span> </span> &#8377;{" "}
                  {amountData && amountData.total ? amountData.total : "0"}{" "}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AmountTransactions;
