import React,{useState,useEffect} from 'react'
//import {useNavigate } from "react-router-dom";
import {createSendData,getFunction} from '../components/Common/Actions'
import { API_KEY, APP_ID, APP_OS, CLIENT_ROLE_ID, IMEI_NO,BRAND_ID} from '../Constants';
import { Container, Row } from "reactstrap";
import AppLoader from "../components/Common/Loader";
const RewardEarned=(props)=>{
     //let navigate = useNavigate()
     const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [myreward,setMyReward]=useState(null)
  //const userID =localStorage.getItem('user_id')
  
     
     useEffect(() => {
   let role_id  = localStorage.getItem('role_id') && localStorage.getItem('role_id')?localStorage.getItem('role_id'):CLIENT_ROLE_ID;
  
     const getEarnRewards=async (URL)=>{
         
         try{
             setLoading(true)
            
    let dataToSend = {mobile_no:localStorage.getItem('mobile_no'),KEY:API_KEY,brand_id:BRAND_ID
      ,app_os:APP_OS,imei_no:IMEI_NO,app_id:APP_ID,token_key:localStorage.getItem('token_key'),userId:localStorage.getItem('user_id'),role_id:role_id};
      
       let params = createSendData(dataToSend)
               const response=     await getFunction(URL,params)
              
                   if(response.status===1){
                       
                       setMyReward(response.data)
                       
                   }
            else{
                setError(response.msg)
                
            }
             setLoading(false)
    
         }catch (error) {
    setError(error)
    setLoading(false)
   } finally {
     setLoading(false);
   }
     }
        getEarnRewards('gratification/api/myrewards')
    
  }, []);
  
  let list='';
  
const message =error && error?<div className="alert alert-warning alert-dismissible  show">
<a className="close" data-dismiss="alert">&times;</a>
{error}

</div>:''

  
  if(myreward){
     
      list = myreward.map((lt,index)=><tr key={lt.id} >
      <td >{lt.trans_ref}</td>
      <td >{lt.amount}</td>
      <td >{lt.display_name}</td>
      <td >{lt.created_on}</td>
    </tr> )
  }
  
    return (<section className='main_section_wrapper'>{message}{loading&& loading?<AppLoader />:''}
    <div className="welcome_section_wrapper">
    <Container><Row><h3>Earned Rewards</h3>
    <table className="table table-success">
  <thead>
    <tr>
      
      <th scope="col">Trans Ref#</th>
      <th scope="col">Smileez</th>
      <th scope="col">Received From</th>
      <th scope="col">Date</th>
    </tr>
    {list}
  </thead>
  <tbody>
    
    
  </tbody>
  </table>
  </Row>
  </Container>
  </div>
  </section>
             )
     }
     export default RewardEarned;