import React, { useState, useEffect, useRef } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
//import { useNavigate } from "react-router-dom";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
import AppLoader from "../components/Common/Loader";
const RewardConfig = (props) => {
  //let navigate = useNavigate();

  let userID = localStorage.getItem("user_id");
  const inputRef = useRef();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rewardsData, setRewardsData] = useState(null);
  const [singleData, setSingleData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  //alert(maxdisamt);
  const buttonTitle = editMode ? "Update" : "Create";
  const [txnAmount, setTxnAmount] = useState(null);
  const [rewardPercentage, setRewardPercentage] = useState(null);
  const [rewardAmount, setRewardAmount] = useState(null);
  const [offerId, setOfferId] = useState(0);
  const saveReward = async (offerId) => {
    try {
      if (txnAmount == null) {
        setError("Please enter transaction amount");
      } else if (rewardPercentage == null) {
        setError("Please enter transaction amount");
      } else if (rewardAmount == null) {
        setError("Please enter transaction amount");
      } else {
        let role_id =
          localStorage.getItem("role_id") && localStorage.getItem("role_id")
            ? localStorage.getItem("role_id")
            : CLIENT_ROLE_ID;

        let dataToSend = {
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: role_id,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          userId: userID,
          invoice_amount: txnAmount,
          offer_discount_type: "percent",
          discount_amt: rewardPercentage,
          max_discount_amount: rewardAmount,
          offerId: offerId,
        };

        let Url = "offers/api/saveoffer";
        let params = createSendData(dataToSend);
        let responseJson = await postFunction(Url, params);

        //Hide Loader
        console.log(responseJson);
        setLoading(false);
        if (responseJson.status === 1) {
          setError(responseJson.msg);
          setRewardsData(responseJson.data);
        } else {
          setError(responseJson.msg);
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const calculateRewardAmt = () => {
    if (rewardPercentage) {
      let rewardsAmount = (txnAmount * rewardPercentage) / 100;
      setRewardAmount(rewardsAmount);
    }
  };

  const getOffers = async (offerId) => {
    try {
      //console.log(offerId);
      let role_id =
        localStorage.getItem("role_id") && localStorage.getItem("role_id")
          ? localStorage.getItem("role_id")
          : CLIENT_ROLE_ID;

      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
        offerId: offerId,
        merchant_id: userID,
      };

      let callingFn = "offerslist";
      if (offerId) {
        callingFn = "getById";
      }
      let Url = "offers/api/" + callingFn;
      let params = createSendData(dataToSend);
      const responseJson = await getFunction(Url, params);

      console.log(responseJson);
      if (responseJson.status === 1) {
        if (offerId) {
          setSingleData(responseJson.data);

          setTxnAmount(responseJson.data.invoice_amount);
          setRewardPercentage(responseJson.data.discount_amt);
          setRewardAmount(responseJson.data.max_discount_amount);
          setEditMode(true);
        } else {
          setRewardsData(responseJson.data);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOffers(0);
  }, []);

  const editOffer = (offerId) => {
    if (offerId) {
      setOfferId(offerId);
      getOffers(offerId);
      inputRef.current.focus();
      window.scrollTo(0, 0);
    } else {
      setError("Reward id is required");
    }
  };

  let rewardsList = "";
  if (rewardsData) {
    rewardsList = rewardsData.map((reward) => (
      <>
        <div className="reward_card" key={reward.id}>
          <div className="row align-items-center">
            {loading && loading ? <AppLoader /> : ""}
            <div className="col-md-6 col-8">
              <label className="control-label createHistory">
                Created On &nbsp; {reward.created_on}
              </label>
            </div>
            <div className="col-md-6 col-4 configrightbt ">
              <button className="editBtn" onClick={() => editOffer(reward.id)}>
                <img
                  src={require("../assets/images/editicon.png")}
                  alt="equal"
                />
                <span className="me-2"></span>
                Edit
              </button>
            </div>
          </div>
          <hr className="hrwidth" />
          <div className="row mt-4">
            <div className="col-6">
              <label className="reward_cardLabel"> Transaction Amount</label>
              <div className="ammounttxt">
                {" "}
                &#x20B9;{reward.invoice_amount}{" "}
              </div>
              <div className="mt-3"></div>
              <label className="reward_cardLabel">Rewards Percentage</label>
              <div className="ammounttxt">{reward.discount_amt}% </div>
            </div>

            <div className="col-1 equlSign">
              <img
                src={require("../assets/images/equalsign.png")}
                alt="equal"
              />
            </div>
            <div className="col-5">
              <label className="reward_cardLabel">Reward Amount</label>
              <div className="ammounttxt">
                {" "}
                &#x20B9;{reward.max_discount_amount}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="divider-30"></div>
      </>
    ));
  }

  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <div className="container">
      <div className="text-center">
        <button className="topbuttonRewards">
          <div className="row rewardRow">
            <div className="col-4 rewardImage">
              {" "}
              <img
                src={require("../assets/images/rewards_config.png")}
                alt="reward config"
              />
            </div>
            <div className="col-8 rewadCol">
              {" "}
              <div className="text1">Reward Configuration</div>
              <div className="text2">
                Lorem ipsum dolor sit amet consectetur.
              </div>
            </div>
          </div>
        </button>
      </div>

      <div classNames="divider-30"></div>
      <div className="row" key="1">
        <div className="col-md-12">
          {message}
          <div className="reward_card">
            <div className="col-md-12">
              <div className="row align-items-center">
                <label className="control-label lbold">
                  Transaction Amount
                </label>
                <input
                  type="text"
                  ref={inputRef}
                  name="trasaction_amount"
                  id="transaction_amount"
                  placeholder=""
                  className="form-control "
                  value={txnAmount}
                  onChange={(e) => {
                    setTxnAmount(e.target.value);
                  }}
                />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label">Reward Percentage</label>
                <input
                  type="text"
                  name="rewardPercentage"
                  id="rewardPercentage"
                  placeholder=""
                  className="form-control"
                  value={rewardPercentage}
                  onKeyUp={calculateRewardAmt}
                  onChange={(e) => {
                    setRewardPercentage(e.target.value);
                  }}
                />
              </div>
              <div className="text-center mt-4">
                <img src={require("../assets/images/equalsign.png")} alt="equal" />
              </div>
              <div className="row align-items-center mt-4">
                <label className="control-label">Reward Amount</label>
                <input
                  type="text"
                  name="rewardAmount"
                  id="rewardAmount"
                  placeholder=""
                  readOnly
                  className="form-control"
                  value={rewardAmount}
                />
              </div>
              <input
                type="hidden"
                name="offerId"
                value={singleData && singleData ? singleData.id : ""}
              />
            </div>
            <div className="text-center  mt-4">
              <button
                className="create-button pointer_class"
                onClick={() => saveReward(offerId)}
              >
                <img src={require("../assets/images/createicon.png")} alt="equal" />
                <span className="me-2"></span>
                {buttonTitle}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="divider-20">
        <hr />
      </div>
      <h3 className="configText">Configuration History</h3>
      <div className="mb-4"></div>
      <div className="row" key="2">
        <div className="col-md-12">{rewardsList}</div>
      </div>
      <div className="divider-80"></div>
    </div>
  );
};
export default RewardConfig;
