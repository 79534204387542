import React, { useState, useEffect } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link } from "react-router-dom";
//import CountryList from '../common/CountryList'
import CountryDropdown from "country-dropdown-with-flags-for-react";
import { Container } from "reactstrap";
import PrintQrcode from "./PrintQrcode";
import ProfileHeader from "../components/Common/ProfileHeader";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";
import AppLoader from "../components/Common/Loader";
const Profile = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [status, setStatus] = useState(false);
  const [qrcode, setQrcode] = useState(false);
  const [fullname, setFullname] = useState(
    userData ? userData.display_name : ""
  );
  const [mobile, setMobile] = useState(userData ? userData.mobileno : "");
  const [email, setEmail] = useState(userData ? userData.email : "");
  const [countryCode, setCountryCode] = useState(
    userData ? userData.country_code : "91"
  );
  let userID = localStorage.getItem("user_id");
  let role_id =
    localStorage.getItem("role_id") && localStorage.getItem("role_id")
      ? localStorage.getItem("role_id")
      : CLIENT_ROLE_ID;

  const handleUpdateProfile = async () => {
    let response = "";

    try {
      if (fullname === "") {
        setError("Full Name is requred");
      } else if (email === "") {
        setError("Email is requred");
      } else if (mobile === "") {
        setError("Mobile is requred");
      } else {
        setLoading(true);
        let dataToSend = {
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: role_id,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          userId: userID,
          full_name: fullname,
          email: email,
          countryCode: countryCode,
          personal_details: 1,
        };

        let params = createSendData(dataToSend);
        response = await postFunction("users/api/saveuser", params);
        if (response === 1) {
          setError(response.msg);
        } else {
          setError(response.msg);
        }
        setLoading(false);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const userInfo = async (URL) => {
    let resp = "";
    try {
      setLoading(true);
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
      };

      let params = createSendData(dataToSend);

      resp = await getFunction(URL, params);
      console.log(resp);
      if (resp.status === 1) {
        setUserData(resp.userdata);
        setFullname(resp.userdata.display_name);
        setMobile(resp.userdata.mobile);
        setEmail(resp.userdata.email);
        setCountryCode(resp.userdata.country_code);
      } else {
        setUserData(resp.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getQrCode = async (URL) => {
    let resp = "";
    try {
      setLoading(true);
      let dataToSend = {
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: role_id,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        userId: userID,
      };
      let params = createSendData(dataToSend);

      resp = await getFunction(URL, params);
      console.log(resp);
      if (resp.status === 1) {
        setQrcode(resp);
      } else {
        setQrcode(resp.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQrCode("userqrcode/api/qrcode");
    userInfo("users/api/userinfo");
  }, []);

  const Printqrcode = () => {
    setStatus(true);
  };

  const hideModel = () => {
    setStatus(false);
  };

  console.log(error);
  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );
  return (
    <section className="main_section_wrapper">
      <div className="profile_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <ProfileHeader pagename="dashboard" title="Profile" />
        <Container>
          <div className="mt-4"></div>
          {role_id == CLIENT_ROLE_ID ? (
            ""
          ) : (
            <section>
              {/* <div className="container"> */}
              <div className="row ">
                <div
                  className="col-md-12 pointer_class"
                  data-toggle="modal"
                  data-target="#myModal4"
                  onClick={Printqrcode}
                >
                  <div className="qr_background_image">
                    <div className="row align-items-center qrGenerator">
                      <div className="col-3">
                        <div className="text-center">
                          <img src={require("../assets/images/qr_icon.png")} alt="qrcode" />
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="qr_content">
                          <h3> Generate QR </h3>
                          <p> Lorem ipsum dolor sit amet consectetur. </p>
                        </div>
                      </div>
                      <div className="col-2">
                        <div>
                          <img
                            src={require("../assets/images/white_arrow_profile.png")}
                            alt="profile pic"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </section>
          )}
        </Container>
        <div className="mt-2"></div>
        <section className="">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="personal_info_title">
                  <h3> Personal Info </h3>
                </div>
              </div>
              <div className="col-md-12">
                <div>
                  <form className="profile_form_section" action="/profile">
                    {message}

                    <label>Full Name</label>
                    <div className="form-group md-4">
                      <input
                        className="form-control"
                        placeholder="Enter your fullname"
                        value={fullname}
                        maxLength="100"
                        required
                        autoComplete="fullname"
                        onChange={(e) => {
                          setFullname(e.target.value);
                        }}
                      />
                    </div>

                    <label>Email</label>
                    <div className="form-group md-4">
                      <input
                        className="form-control"
                        placeholder="Email"
                        type="email"
                        value={email}
                        maxLength="150"
                        required
                        autoComplete="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>

                    <label>Phone Number</label>

                    <div className="row pb-3">
                      <div className="col-md-12">
                        <div className="main_contry_wrapper">
                          <div className="country_code">
                            <CountryDropdown
                              id="country_code"
                              className="country_dropdown"
                              preferredCountries={["in"]}
                              value=""
                              handleChange={(e) =>
                                setCountryCode(e.target.value)
                              }
                            ></CountryDropdown>
                          </div>
                          <div className="count-place">
                            <input
                              className="form-control"
                              placeholder="Phone Number"
                              value={mobile}
                              maxLength={10}
                              autoComplete="mobile"
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <a
                        href="javascript:void(0)"
                        className="sign_Btn"
                        onClick={handleUpdateProfile}
                      >
                        {" "}
                        update Profile{" "}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="mt-2"></div>

        {role_id == CLIENT_ROLE_ID ? (
          ""
        ) : (
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="reward_card config_reward_card">
                    <Link to="../business-profile">
                      <div className="row align-items-center">
                        <div className="col-3">
                          <div>
                            <img
                              src={require("../assets/images/businesss_setails_icon.png")}
                              alt="business_profile"
                            />
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="business_details">
                            <h3> Business details </h3>
                            <p> Lorem ipsum dolor sit amet consectetur. </p>
                          </div>
                        </div>
                        <div className="col-2">
                          <div>
                            <img src={require("../assets/images/rht_arrow.png")} alt="alt_arrow" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        <div className="mt-4"></div>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="reward_card config_reward_card">
                  <Link to="../bank-details">
                    <div className="row align-items-center">
                      <div className="col-3">
                        <div>
                          <img
                            src={require("../assets/images/banking_icon.png")}
                            alt="bank_icon"
                          />
                        </div>
                      </div>
                      <div className="col-7">
                        <div className="business_details">
                          <h3> Bank details </h3>
                          <p> Lorem ipsum dolor sit amet consectetur. </p>
                        </div>
                      </div>
                      <div className="col-2">
                        <div>
                          <img src={require("../assets/images/rht_arrow.png")} alt="the_arrow" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="mt-4"></div>
      </div>
      {role_id == CLIENT_ROLE_ID ? (
        ""
      ) : (
        <PrintQrcode
          modelId="myModal4"
          status={status}
          dismiss={hideModel}
          qrcode={qrcode}
        />
      )}
    </section>
  );
};

export default Profile;
