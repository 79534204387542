import React, { useState, useEffect, Autocomplete } from "react";
import {
  API_KEY,
  APP_ID,
  APP_OS,
  CLIENT_ROLE_ID,
  IMEI_NO,
  BRAND_ID,
} from "../Constants";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../components/Common/Loader";

import axios, * as others from "axios";
import { Container, Row, Col } from "reactstrap";
import ContentHeader from "../components/Common/ContentHeader";
import {
  createSendData,
  getFunction,
  postFunction,
} from "../components/Common/Actions";

const Payment = () => {
  let navigate = useNavigate();
  let userID = localStorage.getItem("userId");
  const [transactionId, setTransactionId] = useState(0);
  if (!userID) {
    navigate("../login");
  }
  let merchantPayId = localStorage.getItem("merchantPayId");
  const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
      setValue(e.target.value);
    };
    return {
      value,
      onChange: handleChange,
    };
  };

  const [values, setValues] = useState("");
  const mobile = useFormInput("");

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [txnStatus, setTxnStatus] = useState(0);

  const [receivers, setReceivers] = useState(null);
  const sendAmount = useFormInput("");
  const MINUTE_MS = 10000;
  const [timeLimit, setTimeLimit] = useState(0);

  const sendPayment = async () => {
    try {
      console.log(sendAmount.value);
      if (!sendAmount.value) {
        setError("Please enter amount");
      }
      if (!receivers) {
        setError("Reciver details not found");
      } else {
        let userEmail = localStorage.getItem("email");
        let dataToSend = {
          userId: userID,
          clientEmail: userEmail,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          merchantId: merchantPayId,
          payAmount: sendAmount.value,
        };

        let Url = "transactions/api/upipayment";
        //console.log(Url)
        setLoading(true);
        let params = createSendData(dataToSend);
        const responseJson = await postFunction(Url, params);
        if (responseJson.status === 1) {
          let response = responseJson.data;
          console.log(response.status);
          if (response.status === true) {
            //console.log(response.data.data.txn_id);
            setTransactionId(response.data.txn_id);
            console.log(transactionId);
            setTimeout(function () {
              checkTxnStatus(response.data.txn_id);
            }, MINUTE_MS);
            window.location.href = response.data.link;
          } else {
            setError(responseJson.data.msg);
          }
        } else {
          setError(responseJson.msg);
        }
      }
    } catch (error) {
      setError(error);
    } finally {
      //setLoading(false);
    }
  };

  const checkTxnStatus = async (txnId) => {
    try {
      let dataToSend = {
        userId: userID,
        mobile_no: localStorage.getItem("mobile_no"),
        KEY: API_KEY,
        role_id: CLIENT_ROLE_ID,
        brand_id: BRAND_ID,
        app_os: APP_OS,
        imei_no: IMEI_NO,
        app_id: APP_ID,
        token_key: localStorage.getItem("token_key"),
        merchantId: merchantPayId,
        txnId: txnId,
      };

      let Url = "transactions/api/checkTxnStatus";
      //console.log(Url)
      let params = createSendData(dataToSend);
      const responseJson = await postFunction(Url, params);
      console.log(responseJson.response);
      if (responseJson.response.status === true) {
        let response = responseJson.response.data;

        console.log("payment status " + response.payment_status);

        if (
          response.payment_status === "COMPLETED" ||
          response.payment_status === "FAILED" ||
          timeLimit >= "60000"
        ) {
          localStorage.setItem("payment_status", response.payment_status);
          localStorage.setItem("payment_txnid", response.payment_txnid);
          localStorage.setItem("payment_amount", response.payment_amount);
          localStorage.setItem(
            "payment_date",
            responseJson.response.payment_date
          );
          setLoading(false);
          navigate("../payment-status");
        } else {
          setTxnStatus(0);
        }
      } else {
        setError(responseJson.msg);
      }
    } catch (error) {
      setError(error);
    } finally {
      //setLoading(false);
    }
  };
  const findUser = async (merchantPayId) => {
    if (merchantPayId) {
      try {
        let dataToSend = {
          userId: userID,
          mobile_no: localStorage.getItem("mobile_no"),
          KEY: API_KEY,
          role_id: CLIENT_ROLE_ID,
          brand_id: BRAND_ID,
          app_os: APP_OS,
          imei_no: IMEI_NO,
          app_id: APP_ID,
          token_key: localStorage.getItem("token_key"),
          merchantId: merchantPayId,
        };

        let Url = "users/api/byuserId";

        let params = createSendData(dataToSend);
        const responseJson = await postFunction(Url, params);
        if (responseJson.status === 1) {
          setReceivers(responseJson.data);
        } else {
          setError(responseJson.msg);
          navigate("../login");
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    //console.log(mobile.value)

    if (merchantPayId) {
      findUser(merchantPayId);
    }
  }, [merchantPayId]);

  setTimeout(() => {
    console.log("Txn Iddfdsfsfds " + transactionId);
    if (transactionId && txnStatus === 0) {
      let time = timeLimit + MINUTE_MS;
      setTimeLimit(time);
      checkTxnStatus(transactionId);
    }
  }, MINUTE_MS);
  console.log("time limit " + timeLimit);
  const message =
    error && error ? (
      <div className="alert alert-warning alert-dismissible  show">
        <a className="close" data-dismiss="alert">
          &times;
        </a>
        {error}
      </div>
    ) : (
      ""
    );

  return (
    <section className="main_section_wrapper">
      <div className="welcome_section_wrapper">
        {loading && loading ? <AppLoader /> : ""}
        <Container>
          <div className="justify-content-center form">
            <form className="login_from_wrapper" action="/send-payment">
              {message}
              <Row>
                <label className="ml-4">&nbsp; </label>
                <div className=" mt-4 ml-4 ">
                  Paying Smileez Merchant
                  <br />
                  {receivers
                    ? receivers.display_name + "(" + receivers.mobileno + ")"
                    : ""}
                </div>
              </Row>

              <div className="footer_login">
                <label>Amount to be Send</label>
                <Row>
                  <div className="col-md-12 pb-3">
                    <input
                      className="form-control"
                      autoFocus
                      required="required"
                      placeholder="Enter amount to be send"
                      maxLength={5}
                      autoComplete="sendamount"
                      {...sendAmount}
                    />
                  </div>
                </Row>
                <a
                  href="javascript:void(0)"
                  className="sign_Btn"
                  onClick={sendPayment}
                >
                  {" "}
                  Pay{" "}
                </a>
              </div>
            </form>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default Payment;
