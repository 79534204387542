import React from "react";

const Wallets = (props) => {
  return (
    <React.Fragment>
      <div className="mt-4"></div>
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="card_wrapper wallet_content">
              <h4> wallet balance </h4>
              <img
                src={require("../../assets/images/wallet-img.png")}
                alt="wallet balance"
              />
              <p>
                {props.walletAmount && props.walletAmount !== "null"
                  ? props.walletAmount
                  : 0.0}{" "}
                INR{" "}
              </p>
            </div>
          </div>
          <div className="col-6">
            <div className="card_wrapper wallet_content">
              <h4> Smileez Rewards </h4>
              <img
                src={require("../../assets/images/gift.png")}
                alt="smileez wallet"
              />

              <p>
                {props.smileezRewards && props.smileezRewards !== "null"
                  ? props.smileezRewards
                  : 0.0}{" "}
                Smileez{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Wallets;
